import React, { useState, useEffect } from "react";

import { BrowserRouter as Router, Routes, Route } from "react-router-dom";
import Footer from "./Component/Footer";
import Navbar from "./Component/Navbar";
import Home from "./Component/Home";
import LoginForm from "./Component/LoginForm";
import NavbarLogin from "./Component/NavbarLogin";
import RegisterForm from "./Component/RegisterForm";
import Register from "./Component/Register";
import "./App.css";
import ChangePassword from "./Component/ChangePassword";
import Dashboard_Manager from "./Component/Dashboard_Manager";
import Emp_Leave_Application from "./Component/Emp_Leave_Application";
import Dashboard_Adm from "./Component/Dashboard_Adm";
import Admin_Adduser_Affilt from "./Component/Admin_Adduser_Affilt";
import OBO_Emp_Leave_Application from "./Component/OBO_Emp_Leave_Application";
import Dash_Cust from "./Component/Dash_Cust";
import Cust_Rep_Lve_Bal_Consume from "./Component/Cust_Rep_Lve_Bal_Consume";
import Cust_Rep_Lve_App_Print from "./Component/Cust_Rep_Lve_App_Print";
import Manager_Agine_Report_Of_Lve from "./Component/Manager_Agine_Report_Of_Lve";
import Manager_Report_Team_Mem_Lists from "./Component/Manager_Report_Team_Mem_Lists";
import Manager_Rep_Team_Lve_Bal_Consume from "./Component/Manager_Rep_Team_Lve_Bal_Consume";
import Manager_Rep_Lve_App_Print from "./Component/Manager_Rep_Lve_App_Print";
import Adm_Rpt_All_Emp_and_Lve_Dtls from "./Component/Adm_Rpt_All_Emp_and_Lve_Dtls";
import Adm_Rpt_Today_Lev_Emp from "./Component/Adm_Rpt_Today_Lev_Emp";
import Adm_Agine_Report_of_Lve from "./Component/Adm_Agine_Report_of_Lve";
import Profile from "./Component/Profile";
import Forgot_Pass from "./Component/Forgot_Pass";
import Cust_Lve_Policy from "./Component/Cust_Lve_Policy";
import Cust_Holliday_Calendar from "./Component/Cust_Holliday_Calendar";
import Adm_Holliday_Calendar from "./Component/Adm_Holliday_calendar";
import Adm_Add_Holiday from "./Component/Adm_Add_Holiday";
import Adm_Lve_Policy from "./Component/Adm_Lve_Policy";
// import SessionTimeout from './SessionTimeout';

function App() {
  const [usr_type, setUsrType] = useState();
  const [usr_dnm, setUsrdnm] = useState();
  const [usr_post, setUsrPost] = useState();

  const usr_typ_set = (data) => {
    setUsrType(data);
  };

  const usr_post_set = (data) => {
    setUsrPost(data);
  };
  const usr_dnm_set = (data) => {
    setUsrdnm(data);
  };
  const checkSession = async () => {
    try {
      const response = await fetch('/rdbs/sessionout', { method: 'GET', credentials: 'include' });
      console.log("Response:", response);

      if (response.ok) {
        const data = await response.json();
        console.log("Session data received:", data); // Log the data received

        if (data.loggedIn) {
          setUsrType(data.usr_type);
          setUsrdnm(data.usr_dnm);
          setUsrPost(data.usr_post);
        } else {
          console.log("User  is not logged in.");
        }
      } else {
        console.error("Failed to fetch session data:", response.statusText);
      }
    } catch (error) {
      console.error('Error checking session:', error);
    }
  };

  useEffect(() => {
    checkSession(); // Check session status on component mount
  }, []);
  console.log(usr_type);
  console.log(usr_dnm);
  console.log(usr_post);
  return (
    <Router>
      {usr_type == "O" ? (
        <>
          <Navbar nav={usr_type} nav1={usr_dnm} post={usr_post} />
          <Routes>
            <Route path="/" element={<Home />} />
            {/* <Route path="/timeout" element={<SessionTimeout />} /> */}
          </Routes>
          <Route path="/usr_profile" element={<Profile />} />
          <Route path="/change_pass" element={<ChangePassword />} />
          <Footer />
        </>
      ) : usr_type == "C" ? (
        usr_post == "MNGR" ? (
          <>
            <Navbar nav={usr_type} nav1={usr_dnm} post={usr_post} />
            <Routes>
              <Route path="/" index element={<Dashboard_Manager />} />
              <Route path="/emp_lev_app" element={<Emp_Leave_Application />} />
              <Route path="/cust_rep_lve_bal_consume" element={<Cust_Rep_Lve_Bal_Consume />} />
              <Route path="/manager_agine_rpt_of_lve" element={<Manager_Agine_Report_Of_Lve />} />
              <Route path="/manager_rpt_team_mem_list" element={<Manager_Report_Team_Mem_Lists />} />
              <Route path="/manager_rpt_team_mem_le_bal_consm" element={<Manager_Rep_Team_Lve_Bal_Consume />} />
              <Route path="/manager_rpt_lve_apl_print" element={<Manager_Rep_Lve_App_Print />} />
              <Route path="/usr_profile" element={<Profile />} />
              <Route path="/change_pass" element={<ChangePassword />} />
              <Route path="/cust_lve_policy" element={<Cust_Lve_Policy />} />
              <Route path="/cust_holliday_calendar" element={<Cust_Holliday_Calendar />} />
              <Route
                path="/obo_emp_lev_app"
                element={<OBO_Emp_Leave_Application />}
              />
            </Routes>
            <Footer />
          </>
        ) : (
          <>
            <Navbar nav={usr_type} nav1={usr_dnm} post={usr_post} />
            <Routes>
              {/* <Route path="/" index element={<Dashboard_Manager />} /> */}
              <Route path="/" element={<Dash_Cust />} />
              <Route path="/emp_lev_app" element={<Emp_Leave_Application />} />
              <Route path="/cust_rep_lve_bal_consume" element={<Cust_Rep_Lve_Bal_Consume />} />
              <Route path="/cust_rep_lve_apl_print" element={<Cust_Rep_Lve_App_Print />} />
              <Route path="/obo_emp_lev_app" element={<OBO_Emp_Leave_Application />} />
              <Route path="/usr_profile" element={<Profile />} />
              <Route path="/change_pass" element={<ChangePassword />} />
              <Route path="/cust_lve_policy" element={<Cust_Lve_Policy />} />
              <Route path="/cust_holliday_calendar" element={<Cust_Holliday_Calendar />} />
            </Routes>
            <Footer />
          </>
        )
      ) : usr_type == "Adm" ? (
        <>
          <Navbar nav={usr_type} nav1={usr_dnm} post={usr_post} />
          <Routes>
            <Route path="/" index element={<Dashboard_Adm />} />
            <Route path="/emp_lev_app" element={<Emp_Leave_Application />} />
            <Route path="/cust_rep_lve_bal_consume" element={<Cust_Rep_Lve_Bal_Consume />} />
            <Route path="/manager_rpt_team_mem_list" element={<Manager_Report_Team_Mem_Lists />} />
            <Route path="/manager_rpt_team_mem_le_bal_consm" element={<Manager_Rep_Team_Lve_Bal_Consume />} />
            <Route path="/adm_rpt_all_emp_lve_dtls" element={<Adm_Rpt_All_Emp_and_Lve_Dtls />} />
            <Route path="/adm_rpt_todays_lev_emp_list" element={<Adm_Rpt_Today_Lev_Emp />} />
            <Route path="/manager_rpt_lve_apl_print" element={<Manager_Rep_Lve_App_Print />} />
            <Route path="/adm_agine_rpt_of_lve" element={<Adm_Agine_Report_of_Lve />} />
            <Route path="/usr_profile" element={<Profile />} />
            <Route path="/change_pass" element={<ChangePassword />} />
            <Route path="/obo_emp_lev_app" element={<OBO_Emp_Leave_Application />} />
            <Route path="/adm_add_usr" element={<Admin_Adduser_Affilt />} />
            <Route path="/adm_holiday_calendar" element={<Adm_Holliday_Calendar />} />
            <Route path="/adm_add_holiday" element={<Adm_Add_Holiday />} />
            <Route path="/adm_lve_policy" element={<Adm_Lve_Policy />} />
          </Routes>
          <Footer />
        </>
      ) : usr_type == "A" ? (
        <>
          <Navbar nav={usr_type} nav1={usr_dnm} post={usr_post} />
          <Routes>
            <Route path="/" element={<RegisterForm />} />
            <Route path="/emp_lev_app" element={<Emp_Leave_Application />} />
            <Route path="/usr_profile" element={<Profile />} />
            <Route path="/obo_emp_lev_app" element={<OBO_Emp_Leave_Application />} />
            <Route path="/change_pass" element={<ChangePassword />} />
          </Routes>
          <Footer />
        </>
      ) : (
        <>
          <NavbarLogin />
          <Routes>
            <Route path="/change_pass" element={<ChangePassword />} />
            <Route path="/forget_pass" element={<Forgot_Pass />} />
            <Route
              path="/"
              element={
                <LoginForm
                  ut={usr_typ_set}
                  dnm={usr_dnm_set}
                  usr_post={usr_post_set}
                />
              }
            />
            <Route path="/register" element={<RegisterForm />} />
          </Routes>
          <Footer />
        </>
      )}
    </Router>
  );
}

export default App;























import React, { useState, useEffect } from "react";
import services from "./apiServices";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Grid,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';

export default function Dash_Cust() {
    const storedUserData = sessionStorage.getItem("user_data");
    let usr_log = null;
    if (storedUserData) {
        usr_log = JSON.parse(storedUserData);
    }
    const [data, setData] = useState({});
    // console.log("Login user data..", usr_log[0].USR_NTT_ID)
    const [leavecnt, Setleavecnt] = useState({
        login_lve_bal_cnt: [], lve_apl_dt: [], login_team_membr_inf_res: [],
        shw_upcom_hlod_res: [], empwise_lve_bal_res: [], all_usr_cnt_data: [], aflt_annocement_news_result: []
    })

    const oboApplications = leavecnt.lve_apl_dt.filter(application => application.APP_OBO_ID);
    console.log("oboApplications", oboApplications)


    useEffect(() => {
        const lev_bal_cnt = async () => {
            try {
                const lve_cnt = await services.get_lev_bal_cnt();

                if (lve_cnt) {
                    Setleavecnt(lve_cnt); // Wrap it in an object if necessary
                } else {
                    console.error("Unexpected data format:", lve_cnt);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        lev_bal_cnt();

    }, [])

    console.log("leavecnt", leavecnt)
    useEffect(() => {
        const fetch = async () => {
            try {
                const affdata = await services.getaffilatedata();
                console.log("affdata", affdata)
                if (affdata) {
                    setData(affdata);
                    console.log("affdata", affdata);
                } else {
                    console.log("Error for fetching data:");
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        fetch();
    }, []);
    console.log("datra", data)

    function formatDate(dateString) {
        // Format the date to 'DD/MMM/YYYY' with the first letter of the month capitalized
        return moment(dateString).format('DD/MMM/YYYY').replace(/\/([a-z])/g, (match) => '/' + match[1].toUpperCase());
    }
    // Get the last 10 leave applications
    const last10Applications = leavecnt.lve_apl_dt
        .sort((a, b) => new Date(b.APP_DT) - new Date(a.APP_DT)) // Sort by application date descending
        .slice(0, 10); // Get the first 10 entries


    //for Annocements    
    const [popupOpen, setPopupOpen] = useState(false);
    const [selectedAnnouncement, setSelectedAnnouncement] = useState('');
    const [isPaused, setIsPaused] = useState(false);


    const handleMouseEnter = () => {
        setIsPaused(true); // Pause the marquee
    };

    const handleMouseLeave = () => {
        setIsPaused(false); // Resume the marquee
    };

    const handleAnnouncementClick = (announcement) => {
        setSelectedAnnouncement(announcement);
        setPopupOpen(true);
    };

    const handleClosePopup = () => {
        setPopupOpen(false);
        setSelectedAnnouncement('');
    };
    console.log("selectedAnnouncement", selectedAnnouncement)

    const marqueeItems = leavecnt.aflt_annocement_news_result.map((app, index) => (
        <span key={app.LVE_NEWS_KEY} // Assuming LVE_NEWS_KEY is unique
            onClick={() => handleAnnouncementClick(app)} // Assuming app holds the full announcement text
            style={{ cursor: 'pointer', marginRight: '20px' }} // Add pointer cursor and margin
        >
            {app.LVE_NTC_HEAD_SUB}
            {index < leavecnt.aflt_annocement_news_result.length - 1 && <span className="pipe">|</span>} {/* Add pipe with CSS class */}
        </span>
    ));
    return (
        <>
            <div
                className={`marquee ${isPaused ? 'paused' : ''}`}
                onMouseEnter={handleMouseEnter}
                onMouseLeave={handleMouseLeave}
            >
                <div className="marquee-content">{marqueeItems}</div>
            </div>

            {/* Popup for Announcement */}
            <Dialog open={popupOpen} onClose={handleClosePopup} maxWidth="lg" fullWidth>
                <DialogTitle style={{ backgroundColor: "#4a6d8c", padding: "10px 24px", textAlign: "center", fontSize: "20px", fontWeight: "bold" }}>
                    Announcement Details
                    <IconButton
                        edge="end"
                        color="inherit"
                        onClick={handleClosePopup}
                        aria-label="close"
                        style={{ position: 'absolute', right: 8, top: 8 }}
                    >
                        <CloseIcon />
                    </IconButton>
                </DialogTitle>
                <DialogContent style={{ padding: "1px 1px", margin: "5px 5px 10px 5px", borderRadius: "15px", height: "300px" }}>
                    <Typography style={{ padding: "5px 10px" }}>Announcement Heading :- <b>{selectedAnnouncement.LVE_NTC_HEAD_SUB}</b></Typography>
                    <Typography style={{ padding: "5px 10px" }}>Actual Announcement :- <b>{selectedAnnouncement.LVE_ACTUL_NTC}</b></Typography>
                    {/* Add more fields as needed */}
                </DialogContent>

            </Dialog>
            <div className="wrapper_form">
                <Grid item xs={12} style={{ paddingLeft: "1%", paddingRight: "1%" }}>
                    <div className="title" style={{ marginTop: "2%" }}>Upcoming Holidays</div>
                    <TableContainer
                        component={Paper}
                        style={{
                            margin: "5px auto 25px auto",
                            // maxWidth: "50%",
                            overflow: "hidden", // Prevents content overflow
                            boxShadow: "0px 6px 16px 3px #bababa", // Shadow only on the top and bottom
                            borderRadius: "20px", // Rounded corners for the container
                            border: "2px solid gray",

                        }}
                    >
                        <Table style={{ width: "100%", }}>
                            <TableHead>
                                <TableRow style={{ width: "100%", }}>
                                    <TableCell
                                        style={{
                                            fontSize: "0.8rem",
                                            padding: "1px 18px",
                                            backgroundColor: "#4a6d8c",
                                            border: "1px solid gray", color: "white", textAlign: "center"
                                        }}
                                        className=" txt_ttl1"
                                    >
                                        Sr. No.
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            fontSize: "0.8rem",
                                            padding: "1px 18px",
                                            backgroundColor: "#4a6d8c",
                                            border: "1px solid gray", color: "white", textAlign: "center"
                                        }}
                                        className=" txt_ttl1"
                                    >
                                        Date
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            fontSize: "0.8rem",
                                            padding: "1px 18px",
                                            backgroundColor: "#4a6d8c",
                                            border: "1px solid gray", color: "white", textAlign: "center"
                                        }}
                                        className=" txt_ttl1"
                                    >
                                        Day
                                    </TableCell>
                                    <TableCell
                                        style={{
                                            fontSize: "0.8rem",
                                            padding: "1px",
                                            backgroundColor: "#4a6d8c",
                                            border: "1px solid gray", color: "white", textAlign: "center"
                                        }}
                                        className=" txt_ttl1"
                                    >
                                        Particulars / Occasion
                                    </TableCell>

                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {leavecnt.shw_upcom_hlod_res
                                    .sort((a, b) => new Date(a.HOLIDAY_DATE) - new Date(b.HOLIDAY_DATE)) // Sort by HOLIDAY_DATE
                                    .map((leave, index) => (
                                        <TableRow style={{ height: "10px" }} key={leave.leaveTypeId}>
                                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                {index + 1} {/* Serial number */}
                                            </TableCell>
                                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                {formatDate(leave.HOLIDAY_DATE)} {/* Assuming HOLIDAY_DATE is in a valid format */}
                                            </TableCell>
                                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                {moment(leave.HOLIDAY_DATE).format('dddd')} {/* Day of the week */}
                                            </TableCell>
                                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                                {leave.HOLIDAY_TYP}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                {/* Add empty rows if there are less than 10 rows */}
                                {leavecnt.shw_upcom_hlod_res.length < 5 &&
                                    Array.from({ length: 5 - leavecnt.shw_upcom_hlod_res.length }).map((_, index) => (
                                        <TableRow style={{ height: "10px" }} key={`empty-row-${index}`}>
                                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                &nbsp; {/* Empty cell for spacing */}
                                            </TableCell>
                                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                &nbsp; {/* Empty cell for spacing */}
                                            </TableCell>
                                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                &nbsp; {/* Empty cell for spacing */}
                                            </TableCell>
                                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                                &nbsp; {/* Empty cell for spacing */}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <hr
                    style={{

                        border: "1px solid #4a6d8c",
                        width: "97%", // Set HR width to 90%
                        marginTop: "1%", // Remove margin
                    }}
                />{" "}
                <Grid container spacing={2} justifyContent="center">
                    {/* Left Table */}
                    <Grid item xs={6} style={{ paddingLeft: "2%" }}>
                        <div className="title" style={{ marginTop: "2%" }}>Leave Balance Details</div>
                        <TableContainer
                            component={Paper}
                            style={{
                                margin: "5px auto 25px auto",
                                // maxWidth: "50%",
                                overflow: "hidden", // Prevents content overflow
                                boxShadow: "0px 6px 16px 3px #bababa", // Shadow only on the top and bottom
                                borderRadius: "20px", // Rounded corners for the container
                                border: "2px solid gray",

                            }}
                        >
                            <Table style={{ width: "100%", }}>
                                <TableHead>
                                    <TableRow style={{ width: "100%", }}>
                                        <TableCell
                                            style={{
                                                fontSize: "0.8rem",
                                                padding: "1px 18px",
                                                backgroundColor: "#4a6d8c",
                                                border: "1px solid gray", color: "white", textAlign: "center"
                                            }}
                                            className=" txt_ttl1"
                                        >
                                            Leave Type
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                fontSize: "0.8rem",
                                                padding: "1px 18px",
                                                backgroundColor: "#4a6d8c",
                                                border: "1px solid gray", color: "white", textAlign: "center"
                                            }}
                                            className=" txt_ttl1"
                                        >
                                            Op. Bal.
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                fontSize: "0.8rem",
                                                padding: "1px 18px",
                                                backgroundColor: "#4a6d8c",
                                                border: "1px solid gray", color: "white", textAlign: "center"
                                            }}
                                            className=" txt_ttl1"
                                        >
                                            Added in Year
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                fontSize: "0.8rem",
                                                padding: "1px",
                                                backgroundColor: "#4a6d8c",
                                                border: "1px solid gray", color: "white", textAlign: "center"
                                            }}
                                            className=" txt_ttl1"
                                        >
                                            Consume
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                fontSize: "0.8rem",
                                                padding: "1px",
                                                backgroundColor: "#4a6d8c",
                                                border: "1px solid gray", color: "white", textAlign: "center"
                                            }}
                                            className=" txt_ttl1"
                                        >
                                            Balance
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {leavecnt.login_lve_bal_cnt.slice(0, 10).map((leave) => (
                                        <TableRow style={{ height: "10px" }} key={leave.leaveTypeId}>
                                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                {leave.disp_nm}
                                            </TableCell>
                                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                {leave.LVE_TYP_OP_BAL_CNT}
                                            </TableCell>
                                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                {/* If you want to display something, do it here */}
                                            </TableCell>
                                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                                {leave.LVE_TYP_CNMSN_CNT}
                                            </TableCell>
                                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                                {leave.LVE_TYP_RN_BAL_CNT}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    {/* Add empty rows if there are less than 10 rows */}
                                    {leavecnt.login_lve_bal_cnt.length < 10 &&
                                        Array.from({ length: 10 - leavecnt.login_lve_bal_cnt.length }).map((_, index) => (
                                            <TableRow style={{ height: "10px" }} key={`empty-row-${index}`}>
                                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray", lineHeight: ".2" }}>
                                                    &nbsp; {/* Empty cell for spacing */}
                                                </TableCell>
                                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                    &nbsp; {/* Empty cell for spacing */}
                                                </TableCell>
                                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                    &nbsp; {/* Empty cell for spacing */}
                                                </TableCell>
                                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                                    &nbsp; {/* Empty cell for spacing */}
                                                </TableCell>
                                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                                    &nbsp; {/* Empty cell for spacing */}
                                                </TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>

                    <Grid item xs={6} style={{ paddingRight: "1%" }}>
                        <div className="title" style={{ marginTop: "2%" }}>Last 10 Leave Application Status </div>
                        <TableContainer
                            component={Paper}
                            style={{
                                margin: "5px auto 25px auto",
                                // maxWidth: "50%",
                                overflow: "hidden", // Prevents content overflow
                                // boxShadow: "-10px 12px 16px 3px #bababa", // Gray shadow
                                boxShadow: "0px 6px 16px 3px #bababa", // Shadow only on the top and bottom
                                borderRadius: "20px", // Rounded corners for the container
                                border: "2px solid gray",

                            }}
                        >
                            <Table style={{ width: "100%", }}>
                                <TableHead>
                                    <TableRow style={{ width: "100%", }}>
                                        <TableCell
                                            style={{
                                                fontSize: "0.8rem",
                                                padding: "1px 18px",
                                                backgroundColor: "#4a6d8c",
                                                border: "1px solid gray", color: "white", textAlign: "center"
                                            }}
                                            className=" txt_ttl1"
                                        >
                                            Date
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                fontSize: "0.8rem",
                                                padding: "1px 18px",
                                                backgroundColor: "#4a6d8c",
                                                border: "1px solid gray", color: "white", textAlign: "center"
                                            }}
                                            className=" txt_ttl1"
                                        >
                                            From
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                fontSize: "0.8rem",
                                                padding: "1px",
                                                backgroundColor: "#4a6d8c",
                                                border: "1px solid gray", color: "white", textAlign: "center"
                                            }}
                                            className=" txt_ttl1"
                                        >
                                            To
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                fontSize: "0.8rem",
                                                padding: "1px",
                                                backgroundColor: "#4a6d8c",
                                                border: "1px solid gray", color: "white", textAlign: "center"
                                            }}
                                            className=" txt_ttl1"
                                        >
                                            Duration
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                fontSize: "0.8rem",
                                                padding: "1px",
                                                backgroundColor: "#4a6d8c",
                                                border: "1px solid gray", color: "white", textAlign: "center"
                                            }}
                                            className=" txt_ttl1"
                                        >
                                            Type
                                        </TableCell>
                                        <TableCell
                                            style={{
                                                fontSize: "0.8rem",
                                                padding: "1px",
                                                backgroundColor: "#4a6d8c",
                                                border: "1px solid gray", color: "white", textAlign: "center"
                                            }}
                                            className=" txt_ttl1"
                                        >
                                            Status
                                        </TableCell>
                                    </TableRow>
                                </TableHead>
                                <TableBody>
                                    {last10Applications.slice(0, 10).map((aplicantdata) => (
                                        <TableRow style={{ height: "10px" }} key={aplicantdata.leaveTypeId}>
                                            <TableCell
                                                align="center" // Center the content horizontally
                                                style={{
                                                    fontSize: "0.8rem",
                                                    padding: "1px 18px",
                                                    border: "1px solid gray",
                                                }}
                                            >
                                                {formatDate(aplicantdata.APP_DT)}
                                            </TableCell>
                                            <TableCell
                                                align="center" // Center the content horizontally
                                                style={{
                                                    fontSize: "0.8rem",
                                                    padding: "1px 18px",
                                                    border: "1px solid gray",
                                                }}
                                            >
                                                {formatDate(aplicantdata.APP_LVE_FRM_DT)} {/* Format leave to date */}
                                            </TableCell>
                                            <TableCell
                                                align="center" // Center the content horizontally
                                                style={{
                                                    fontSize: "0.8rem",
                                                    padding: "1px",
                                                    border: "1px solid gray",
                                                }}
                                            >
                                                {formatDate(aplicantdata.APP_LVE_TO_DT)}
                                            </TableCell>
                                            <TableCell
                                                align="center" // Center the content horizontally
                                                style={{
                                                    fontSize: "0.8rem",
                                                    padding: "1px",
                                                    border: "1px solid gray",
                                                }}
                                            >
                                                {aplicantdata.APP_LVE_DES} days
                                            </TableCell>
                                            <TableCell
                                                align="center" // Center the content horizontally
                                                style={{
                                                    fontSize: "0.8rem",
                                                    padding: "1px",
                                                    border: "1px solid gray",
                                                }}
                                            >
                                                {aplicantdata.disp_nm}
                                            </TableCell>
                                            <TableCell
                                                align="center" // Center the content horizontally
                                                style={{
                                                    fontSize: "0.8rem",
                                                    padding: "1px",
                                                    border: "1px solid gray",
                                                }}
                                            >
                                                {aplicantdata.APP_LVE_STTS}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                                    {last10Applications.length < 10 &&
                                        Array.from({ length: 10 - last10Applications.length }).map((_, index) => (
                                            <TableRow key={`empty-row-manager-${index}`}>
                                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                                            </TableRow>
                                        ))}
                                </TableBody>
                            </Table>
                        </TableContainer>
                    </Grid>
                </Grid>
                <hr
                    style={{

                        border: "1px solid #4a6d8c",
                        width: "97%", // Set HR width to 90%
                        marginTop: "1%", // Remove margin
                    }}
                />{" "}
                <Grid item xs={12} style={{ paddingLeft: "1%", paddingRight: "1%" }}>
                    <div className="title" style={{ marginTop: "2%" }}>OBO Applications</div>
                    <TableContainer
                        component={Paper}
                        style={{
                            margin: "5px auto 25px auto",
                            overflow: "hidden",
                            boxShadow: "0px 6px 16px 3px #bababa",
                            borderRadius: "20px",
                            border: "2px solid gray",
                        }}
                    >
                        <Table style={{ width: "100%" }}>
                            <TableHead>
                                <TableRow>
                                    <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Sr.No.</TableCell>
                                    <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>OBO ID</TableCell>
                                    <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Date</TableCell>
                                    <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>From</TableCell>
                                    <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>To</TableCell>
                                    <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Duration</TableCell>
                                    <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Type</TableCell>
                                    <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Status</TableCell>
                                </TableRow>
                            </TableHead>
                            <TableBody>
                                {oboApplications.map((application, index) => (
                                    <TableRow key={application.APP_OBO_ID}>
                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                            {index + 1}
                                        </TableCell>
                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                            {application.APP_OBO_ID}
                                        </TableCell>
                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                            {formatDate(application.APP_DT)}
                                        </TableCell>
                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                            {formatDate(application.APP_LVE_FRM_DT)}
                                        </TableCell>
                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                            {formatDate(application.APP_LVE_TO_DT)}
                                        </TableCell>
                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                            {application.APP_LVE_DES} days
                                        </TableCell>
                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                            {application.disp_nm}
                                        </TableCell>
                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                            {application.APP_LVE_STTS}
                                        </TableCell>
                                    </TableRow>
                                ))}
                                {oboApplications.length < 5 &&
                                    Array.from({ length: 5 - oboApplications.length }).map((_, index) => (
                                        <TableRow style={{ height: "10px" }} key={`empty-row-${index}`}>
                                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray", lineHeight: ".2" }}>
                                                &nbsp; {/* Empty cell for spacing */}
                                            </TableCell>
                                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                &nbsp; {/* Empty cell for spacing */}
                                            </TableCell>
                                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                &nbsp; {/* Empty cell for spacing */}
                                            </TableCell>
                                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                                &nbsp; {/* Empty cell for spacing */}
                                            </TableCell>
                                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                                &nbsp; {/* Empty cell for spacing */}
                                            </TableCell>
                                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                &nbsp; {/* Empty cell for spacing */}
                                            </TableCell>
                                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                                &nbsp; {/* Empty cell for spacing */}
                                            </TableCell>
                                            <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                                &nbsp; {/* Empty cell for spacing */}
                                            </TableCell>
                                        </TableRow>
                                    ))}
                            </TableBody>
                        </Table>
                    </TableContainer>
                </Grid>
                <hr
                    style={{

                        border: "1px solid #4a6d8c",
                        width: "97%", // Set HR width to 90%
                        marginTop: "1%", // Remove margin
                    }}
                />{" "}
            </div>
        </>
    );
}

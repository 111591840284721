import React, { useState, useEffect } from "react";
import services from "./apiServices";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
} from "@mui/material";

export default function FileDetails() {
  const [files, setFiles] = useState([]);

  useEffect(() => {
    const fetchFileDetails = async () => {
      try {
        console.log("Fetching file details...");
        const res = await services.get_lve_policy_details();
        console.log("Response received:", res);
        if (res.files) {
          setFiles(res.files);
          console.log("Files state updated:", res.files);
        } else {
          console.error("Unexpected data format:", res);
        }
      } catch (error) {
        console.error("Error fetching file details:", error);
      }
    };
    fetchFileDetails();
  }, []);

  const openFile = (filePath) => {
    const newWindow = window.open(filePath, '_blank', 'noopener,noreferrer');
    if (newWindow) newWindow.opener = null; // Prevent the new window from accessing the opener
  };

  console.log("files", files);

  return (
    <div className='wrapper_form'>
      <div className="title" style={{ marginTop: ".3%" }}> File Details</div>
      <TableContainer component={Paper}>
        <Table>
          <TableHead>
            <TableRow>
              <TableCell>File Name</TableCell>
              <TableCell>Description</TableCell>
              <TableCell>Action</TableCell>
            </TableRow>
          </TableHead>
          <TableBody>
            {files.map((file, index) => (
              <TableRow key={index}>
                <TableCell>{file.LVE_FILE_NM}</TableCell>
                <TableCell>{file.LVE_ACTUL_NTC}</TableCell>
                <TableCell>
                  <button onClick={() => openFile(file.LVE_FILE_PATH)}>
                    Open File
                  </button>
                </TableCell>
              </TableRow>
            ))}
          </TableBody>
        </Table>
      </TableContainer>
    </div>
  );
}
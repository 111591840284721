import React, { useEffect, useState } from "react";
import { Link } from "react-router-dom";
import services from "./apiServices";
import {
  Checkbox,
  FormControlLabel,
  FormGroup,
  FormLabel,
} from "@mui/material";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
// import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
// import FormLabel from "@mui/material/FormLabel";
// import Checkbox from "@mui/material/Checkbox";
import LveTypRegTab from "./LveTypRegTab";

const RegisterForm = (props) => {
  console.log(props);
  function toggleForm() {
    var formContainer = document.getElementById("formContainer");
    var toggleButton = document.getElementById("toggleButton");
    if (
      formContainer.style.display === "none" ||
      formContainer.style.display === ""
    ) {
      formContainer.style.display = "block";
      toggleButton.textContent = "-";
    } else {
      formContainer.style.display = "none";
      toggleButton.textContent = "+";
    }
  }
  const [tabValue, setTabValue] = useState(0);
  const [formErrors, setFormErrors] = useState({});
  const [loading, setLoading] = useState(true);

  const [thirdcomplete, setthirdcomplete] = useState(false);
  const [secondcomplete, setsecondcomplete] = useState(false);
  const [firstcomplete, setfirstcomplete] = useState(false);

  const [error, setError] = useState(null);

  const [formData, setFormData] = useState({
    org_fullnm: "",
    org_snm: "",
    org_unicode: "",
    usr_typ: "",
    userCategory: "",
    userPost: "",
    userTitle: "",
    usr_fnm: "",
    usr_mnm: "",
    usr_lnm: "",
    usernm: "",
    usr_pass: "",
    addressLine1: "",
    addressLine2: "",
    addressLine3: "",
    city: "",
    pin: "",
    state: "",
    country: 100,
    landmark: "",
    gmapLink: "",
    pan: "",
    tan: "",
    gst: "",
    phone1: "",
    phone2: "",
    email: "",
    website: "",
    socialMedia1: "",
    socialMedia2: "",
    socialMedia3: "",
    socialMedia4: "",
  });

  const [revdata, setrevdata] = useState({ tabdata: [], seledesgination: [] });
  const [tabdata4, settabdata4] = useState([]);

  // console.log(revdata)
  // console.log(formData);
  const [data, setData] = useState({
    result: [],
    result2: [],
    country_res: [],
  });
  const [gender, setgender] = useState([]);

  const [usrdata, setUsrdata] = useState({
    usrtype: [],
    usrcat: [],
    usrtitle: [],
    Desgn: [],
  });

  const [form, setform] = useState({
    LVE_ALOW_IN_JOIN_MN_YN: "",
    LVE_YR_STRT: "",
    WORKING_DAYS: "",
    LVE_HD_AVIL_YN: "",
    LVE_MIN_UOM_HR_HD_FD: "",
    LVE_ADD_MNLY_YRLY: "",
    LVE_MIN_CNSMSN_YN: "",
    LVE_TYP_MIN_CONSMSN: "",
    LVE_TYP_CRRFW_HOLDE_CNT: "",
    LVE_APP_OBO_ALWD: "",
    LVE_APP_BKDTED_ALOW: "",
    LVE_APP_BKDTED_MAX_DES: "",
    LVE_APP_AFTR_CLOSN_YN: "",
    LVE_ALOW_IN_JOIN_MN_YN: "",
    LVE_APP_ALOW_AFTR_JOIN: "",
    LVE_APP_STTS_ALRT_DES: "",
    LVE_APP_MAX_DES: "",
    LVE_APP_IN_CNT_IN_WIK: "",
    LVE_APP_NXT_DE_CNT: "",
    LVE_APP_DISP_LST_YR: "",
    LVE_NXT_HLDCLNR_DISP_YN: "",
    LVE_PST_HLDCLNR_DISP_YN: "",
    USR_ID_MAX_LNTH: "",
    USR_ID_MIN_LNTH: "",
    PWD_MAX_LNTH: "",
    PWD_MIN_LNTH: "",
    PWD_NUMBR_YN: "",
    PWD_SPL_CHAR_LST: "",
  });
  const [formDataArray, setFormDataArray] = useState([]);

  const months = [
    { value: "01", label: "January" },
    { value: "02", label: "February" },
    { value: "03", label: "March" },
    { value: "04", label: "April" },
    { value: "05", label: "May" },
    { value: "06", label: "June" },
    { value: "07", label: "July" },
    { value: "08", label: "August" },
    { value: "09", label: "September" },
    { value: "10", label: "October" },
    { value: "11", label: "November" },
    { value: "12", label: "December" },
  ];

  // const [selectedDays, setSelectedDays] = useState({
  //   all: false,
  //   monday: false,
  //   tuesday: false,
  //   wednesday: false,
  //   thursday: false,
  //   friday: false,
  //   saturday: false,
  //   sunday: false,
  // });

  // Handler for changing individual checkboxes
  // Handler for when a specific day is checked/unchecked
  const handleDayChange = (day) => {
    let updatedDays = [...form.WORKING_DAYS];

    if (day === "all") {
      if (updatedDays.includes("all")) {
        updatedDays = []; // Clear all selections if 'All' is selected
      } else {
        updatedDays = [
          "all",
          "monday",
          "tuesday",
          "wednesday",
          "thursday",
          "friday",
          "saturday",
          "sunday",
        ]; // Select all days
      }
    } else {
      if (updatedDays.includes(day)) {
        updatedDays = updatedDays.filter((d) => d !== day); // Deselect the day
      } else {
        updatedDays.push(day); // Select the day
      }

      // Update logic for "All"
      if (updatedDays.length < 8) {
        updatedDays = updatedDays.filter((d) => d !== "all");
      }
      if (updatedDays.length === 7) {
        updatedDays.push("all");
      }
    }

    setform((prevForm) => ({
      ...prevForm,
      WORKING_DAYS: updatedDays,
    }));

    console.log("Updated Form Data:", {
      ...form,
      WORKING_DAYS: updatedDays,
    });
  };

  console.log(form);

  useEffect(() => {
    const dataArray = Object.entries(form).map(([key, value]) => ({
      name: key,
      value: value,
    }));
    setFormDataArray(dataArray);
  }, [form]);

  // Handle input change
  const handleChangethirdtab = (e) => {
    const { name, value } = e.target;
    setform((prev) => ({
      ...prev,
      [name]: value,
    }));
  };

  useEffect(() => {
    const fetch = async () => {
      try {
        const result = await services.getlvetypdata(revdata);
        // const gender_var =  await services.getdropdowndata("APCBL_GNDR");

        console.log("Fetched data:", result);

        // Adjust this based on actual data structure
        if (result) {
          setData(result); // Wrap it in an object if necessary
          console.log(result);
          props.res_admin(result);
        } else {
          console.error("Unexpected data format:", result);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetch();
  }, []);

  useEffect(() => {
    const fg = async () => {
      try {
        // Make multiple requests
        const res = await Promise.all([
          services.getdropdowndata("APCBL_GNDR"),
          services.getdropdowndata("User_Type"),
          services.getdropdowndata("User_Category"),
          services.getdropdowndata("User_Title"),
          services.getdropdowndata("APCBL_POST"),
        ]);

        const newUsrdata = { usrtype: [], usrcat: [], usrtitle: [], Desgn: [] };

        // Process responses based on the name identifier
        res.forEach((response) => {
          const { name, dpresult } = response;

          switch (name) {
            case "APCBL_GNDR":
              setgender(dpresult);
              break;
            case "User_Type":
              newUsrdata.usrtype = dpresult;
              break;
            case "User_Category":
              newUsrdata.usrcat = dpresult;
              break;
            case "User_Title":
              newUsrdata.usrtitle = dpresult;
              break;
            case "APCBL_POST":
              newUsrdata.Desgn = dpresult;
              break;
            default:
              break;
          }
        });

        setUsrdata(newUsrdata);
      } catch (err) {
        // Handle errors
        setError(err);
      } finally {
        // Set loading to false when requests are completed
        setLoading(false);
      }
    };

    fg();
  }, []);

  // console.log(usrdata.Desgn[0])

  const handleTabChange = (newValue) => {
    setTabValue(newValue);
  };

  const handleSubmit = async (e) => {
    e.preventDefault(); // Prevent the default form submission behavior

    // Filter out "all" from the workingDays array

    // You can now proceed to use formData, for example:
    // await sendFormDataToAPI(formData);
  };

  const handleChange = (e, field) => {
    setFormData({ ...formData, [field]: e.target.value });
  };

  const nextStep = () => {
    if (tabValue < 3) {
      setTabValue(tabValue + 1);
    }
  };

  const setrecivedData = (data) => {
    setrevdata(data);
  };

  const set4thtabData = (data) => {
    settabdata4(data);
  };

  const [errors, setErrors] = useState({});

  const validateForm = () => {
    const newErrors = {};

    // Check each field for emptiness
    if (!formData.org_fullnm)
      newErrors.org_fullnm = "Organization Full Name is required";
    if (!formData.org_snm)
      newErrors.org_snm = "Organization Short Name is required";
    if (!formData.org_unicode)
      newErrors.org_unicode = "Organization Unique Code is required";
    if (!formData.usr_typ) newErrors.usr_typ = "User Type is required";
    if (!formData.userCategory)
      newErrors.userCategory = "User Category is required";
    if (!formData.userPost) newErrors.userPost = "User Post is required";
    if (!formData.userTitle) newErrors.userTitle = "User Title is required";
    if (!formData.usr_fnm) newErrors.usr_fnm = "User First Name is required";
    // if (!formData.usr_mnm) newErrors.usr_mnm = "User Middle Name is required";
    if (!formData.usr_lnm) newErrors.usr_lnm = "User Last Name is required";
    if (!formData.usernm) newErrors.usernm = "User Name is required";
    if (!formData.usr_pass) newErrors.usr_pass = "Password is required";
    if (!formData.addressLine1)
      newErrors.addressLine1 = "Address Line 1 is required";
    if (!formData.city) newErrors.city = "City is required";
    // if (!formData.addressLine2) newErrors.addressLine2 = "Address Line 2 is required";
    if (!formData.pin) newErrors.pin = "PIN is required";
    // if (!formData.addressLine3)      newErrors.addressLine3 = "Address Line 3 is required";
    if (!formData.state) newErrors.state = "State is required";
    if (!formData.country) newErrors.country = "Country is required";
    // if (!formData.landmark) newErrors.landmark = "Landmark is required";
    // if (!formData.gmapLink) newErrors.gmapLink = "Google Map Link is required";
    // if (!formData.pan) newErrors.pan = "PAN is required";
    // if (!formData.tan) newErrors.tan = "TAN is required";
    // if (!formData.gst) newErrors.gst = "GST is required";
    if (!formData.phone1) newErrors.phone1 = "Phone 1 is required";
    // if (!formData.phone2) newErrors.phone2 = "Phone 2 is required";
    if (!formData.email) newErrors.email = "Email is required";
    if (!formData.website) newErrors.website = "Website is required";
    if (!formData.socialMedia1)
      newErrors.socialMedia1 = "Social Media Link 1 is required";
    // if (!formData.socialMedia2) newErrors.socialMedia2 = "Social Media Link 2 is required";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const validateFormtab4 = () => {
    const newErrors = {};
    // Check each field for emptiness
    if (!form.LVE_YR_STRT) newErrors.LVE_YR_STRT = "this field is required ";
    if (!form.LVE_HD_AVIL_YN)
      newErrors.LVE_HD_AVIL_YN = "this field is required ";
    if (!form.WORKING_DAYS || form.WORKING_DAYS.length === 0) {
      newErrors.WORKING_DAYS = "this field is required.";
    }
    if (!form.LVE_MIN_UOM_HR_HD_FD)
      newErrors.LVE_MIN_UOM_HR_HD_FD = "this field is required ";
    if (!form.LVE_ADD_MNLY_YRLY)
      newErrors.LVE_ADD_MNLY_YRLY = "this field is required ";
    if (!form.LVE_MIN_CNSMSN_YN)
      newErrors.LVE_MIN_CNSMSN_YN = "this field is required ";
    if (!form.LVE_TYP_MIN_CONSMSN)
      newErrors.LVE_TYP_MIN_CONSMSN = "this field is required ";
    if (!form.LVE_TYP_CRRFW_HOLDE_CNT)
      newErrors.LVE_TYP_CRRFW_HOLDE_CNT = "this field is required ";
    if (!form.LVE_APP_OBO_ALWD)
      newErrors.LVE_APP_OBO_ALWD = "this field is required ";
    if (!form.LVE_APP_BKDTED_ALOW)
      newErrors.LVE_APP_BKDTED_ALOW = "this field is required ";
    if (!form.LVE_APP_BKDTED_MAX_DES)
      newErrors.LVE_APP_BKDTED_MAX_DES = "this field is required ";
    if (!form.LVE_APP_AFTR_CLOSN_YN)
      newErrors.LVE_APP_AFTR_CLOSN_YN = "this field is required ";
    if (!form.LVE_ALOW_IN_JOIN_MN_YN)
      newErrors.LVE_ALOW_IN_JOIN_MN_YN = "this field is required ";
    if (!form.LVE_APP_ALOW_AFTR_JOIN)
      newErrors.LVE_APP_ALOW_AFTR_JOIN = "this field is required ";
    if (!form.LVE_APP_STTS_ALRT_DES)
      newErrors.LVE_APP_STTS_ALRT_DES = "this field is required ";
    if (!form.LVE_APP_MAX_DES)
      newErrors.LVE_APP_MAX_DES = "this field is required ";
    if (!form.LVE_APP_IN_CNT_IN_WIK)
      newErrors.LVE_APP_IN_CNT_IN_WIK = "this field is required ";
    if (!form.LVE_APP_NXT_DE_CNT)
      newErrors.LVE_APP_NXT_DE_CNT = "this field is required ";
    if (!form.LVE_APP_DISP_LST_YR)
      newErrors.LVE_APP_DISP_LST_YR = "this field is required ";
    if (!form.LVE_NXT_HLDCLNR_DISP_YN)
      newErrors.LVE_NXT_HLDCLNR_DISP_YN = "this field is required ";
    if (!form.LVE_PST_HLDCLNR_DISP_YN)
      newErrors.LVE_PST_HLDCLNR_DISP_YN = "this field is required ";
    if (!form.USR_ID_MAX_LNTH)
      newErrors.USR_ID_MAX_LNTH = "this field is required ";
    if (!form.USR_ID_MIN_LNTH)
      newErrors.USR_ID_MIN_LNTH = "this field is required ";
    if (!form.PWD_MAX_LNTH) newErrors.PWD_MAX_LNTH = "this field is required ";
    if (!form.PWD_MIN_LNTH) newErrors.PWD_MIN_LNTH = "this field is required ";
    if (!form.PWD_NUMBR_YN) newErrors.PWD_NUMBR_YN = "this field is required ";
    if (!form.PWD_SPL_CHAR_LST)
      newErrors.PWD_SPL_CHAR_LST = "this field is required ";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const sub_first_tab = () => {
    if (validateForm()) {
      const insrt_tab1 = async () => {
        try {
          const insrt_res = await services.insert_reg_tab1(formData);
          // const gender_var =  await services.getdropdowndata("APCBL_GNDR");

          console.log("Fetched data:", insrt_res);
          // if (!formData.org_fullnm) {
          //   alert("Please fill out the Organization Full Name.");
          //   return;
          // }
          // Adjust this based on actual data structure
          if (insrt_res) {
            setfirstcomplete(true);
            console.log("Tab 1  data successfully inserted"); // Wrap it in an object if necessary
            nextStep();
          } else {
            console.error("Tab 1  data insertion failed");
          }
        } catch (error) {
          console.error("Tab 1  data insertion failed");
        }
      };

      insrt_tab1();
    }
  };

  const [checkboxError, setCheckboxError] = useState("");

  const validateCheckboxSelection = () => {
    // Check if any checkbox is selected
    const isAnyChecked = checked.checkedckbox.length > 0;

    if (!isAnyChecked) {
      setCheckboxError("Please select at least one checkbox.");
    } else {
      setCheckboxError("");
    }

    return isAnyChecked;
  };

  const sub_second_tab = () => {
    if (validateCheckboxSelection()) {
      // Proceed with form submission or tab change
      const insrt_tab2 = async () => {
        try {
          const insrt_res2 = await services.insert_reg_tab2(checked);
          // const gender_var =  await services.getdropdowndata("APCBL_GNDR");

          console.log("Fetched data:", insrt_res2);

          // Adjust this based on actual data structure
          if (insrt_res2) {
            setsecondcomplete(true);
            console.log("Leave types successfully inserted"); // Wrap it in an object if necessary
            nextStep();
          } else {
            console.error("Leave types insertion failed");
          }
        } catch (error) {
          console.error("Leave types insertion failed");
        }
      };

      insrt_tab2();
    } else {
      alert("Please select at least one leave Type");
    }
  };

  const validatethirdtab = () => {
    // Initialize the error flag
    let errortab = false;

    for (let i = 0; i < revdata.tabdata.length; i++) {
      if (revdata.tabdata[i].ques_code !== "LVE_TYP_ALOW_POST") {
        for (let j = 0; j < revdata.tabdata[i].lve_types.length; j++) {
          if (
            revdata.tabdata[i].ques_code == "" ||
            revdata.tabdata[i].ans[j] == ""
          ) {
            errortab = true;
            break;
          }
        }
      } else {
        for (let j = 0; j < revdata.seledesgination.length; j++) {
          console.log(revdata.seledesgination[j].length);

          if (revdata.seledesgination[j].length < 1) {
            errortab = true;
            break;
          }
        }
      }

      if (errortab) break; // Exit outer loop if an error is found
    }

    return errortab;
  };
  console.log(formDataArray);
  const sub_third_tab = async () => {
    if (!validatethirdtab()) {
      // Validation succeeded (no errors)
      try {
        const insrt_res3 = await services.insert_reg_tab3(revdata);
        console.log("Fetched data:", insrt_res3);

        if (insrt_res3) {
          setthirdcomplete(true);
          console.log("Leave types ques successfully inserted");
          nextStep();
        } else {
          console.error("Leave types ques insertion failed");
        }
      } catch (error) {
        console.error("Error occurred during insertion:", error);
      }
    } else {
      alert("Please fill all fields");
    }
  };

  const sub_fourth_tab = () => {
    if (validateFormtab4()) {
      const insrt_tab4 = async () => {
        try {
          const insrt_res4 = await services.insert_reg_tab4(formDataArray);

          console.log("Fetched data 4242434:", insrt_res4);

          //Adjust this based on actual data structure
          if (insrt_res4) {
            console.log(
              "Registration configration 2 questions successfully inserted"
            ); // Wrap it in an object if necessary
          } else {
            console.error("Registration configration questions  2 failed");
          }
        } catch (error) {
          console.error("Registration configration questions 2 failed");
        }
      };

      insrt_tab4();
    }
  };

  const [checked, setChecked] = useState({
    check: [],
    checkName: [],
    checkedckbox: [],
  });
  // Handles changes for parent checkboxes
  const handleParentChange = (event) => {
    const parentCd = event.target.name; // Parent checkbox ID
    const isChecked = event.target.checked; // Whether the checkbox is checked or not

    setChecked((prev) => {
      // Get all child IDs under the current parent
      const childChecks = data.result
        .filter(
          (item) => item.ATTRIB_GRP_TYP === parentCd && item.IS_PARENT === null
        )
        .map((item) => item.ATTRIB_CD);

      const parentcode = data.result.find(
        (item) => item.ATTRIB_CD === parentCd && item.IS_PARENT === null
      )?.ATTRIB_CD;

      const childcodes = data.result
        .filter((item) => childChecks.includes(item.ATTRIB_CD))
        .map((item) => item.ATTRIB_CD);

      const newCheck = isChecked
        ? [...prev.check, parentcode, ...childcodes]
            .filter((name) => name !== undefined) // Remove undefined values
            .filter((value, index, self) => self.indexOf(value) === index) // Remove duplicates
        : prev.check
            .filter((name) => !childcodes.includes(name) && name !== parentcode)
            .filter((value, index, self) => self.indexOf(value) === index); // Remove duplicates

      const newCheckedchbox = isChecked
        ? Array.from(new Set([...prev.checkedckbox, parentCd, ...childChecks]))
        : Array.from(
            new Set(
              prev.checkedckbox.filter(
                (cd) => !childChecks.includes(cd) && cd !== parentCd
              )
            )
          );

      // If the parent is checked, add both parent and child names to the checkName array.
      // Otherwise, remove them.
      const parentName = data.result.find(
        (item) => item.ATTRIB_CD === parentCd && item.IS_PARENT === null
      )?.ATTRIB_NAME;

      const childNames = data.result
        .filter((item) => childChecks.includes(item.ATTRIB_CD))
        .map((item) => item.ATTRIB_NAME);

      const newCheckNames = isChecked
        ? Array.from(
            new Set(
              [...prev.checkName, parentName, ...childNames].filter(
                (name) => name !== undefined
              )
            )
          )
        : Array.from(
            new Set(
              prev.checkName.filter(
                (name) => !childNames.includes(name) && name !== parentName
              )
            )
          );

      return {
        check: newCheck,
        checkedckbox: newCheckedchbox,
        checkName: newCheckNames,
      };
    });
  };
  const handleChange1 = (event) => {
    const childCd = event.target.name; // Child checkbox ID
    const isChecked = event.target.checked; // Whether the checkbox is checked or not

    setChecked((prev) => {
      // Find the name of the child being checked/unchecked
      const childName = data.result.find(
        (item) => item.ATTRIB_CD === childCd && item.IS_PARENT === null
      )?.ATTRIB_NAME;

      // Add/remove the child ID in the check array
      const newCheck = isChecked
        ? [...prev.check, childCd]
        : prev.check.filter((cd) => cd !== childCd);
      const newCheckedchbox = isChecked
        ? [...prev.checkedckbox, childCd]
        : prev.checkedckbox.filter((cd) => cd !== childCd);

      // Add/remove the child name in the checkName array
      const newCheckNames = isChecked
        ? [...prev.checkName, childName].filter((name) => name !== undefined)
        : prev.checkName.filter((name) => name !== childName);

      // Find the parent of the child
      const parentCd = data.result.find(
        (item) => item.ATTRIB_CD === childCd
      )?.ATTRIB_GRP_TYP;

      // Check if all siblings are checked, and if so, add the parent ID
      const updatedParentCheck = parentCd
        ? data.result
            .filter(
              (item) =>
                item.ATTRIB_GRP_TYP === parentCd && item.IS_PARENT === null
            )
            .map((item) => item.ATTRIB_CD)
            .every((cd) => newCheck.includes(cd))
          ? [...newCheck]
          : newCheck.filter((cd) => cd !== parentCd)
        : newCheck;

      const updatedParentCheckedbox = parentCd
        ? data.result
            .filter(
              (item) =>
                item.ATTRIB_GRP_TYP === parentCd && item.IS_PARENT === null
            )
            .map((item) => item.ATTRIB_CD)
            .every((cd) => newCheckedchbox.includes(cd))
          ? [...newCheckedchbox, parentCd]
          : newCheckedchbox.filter((cd) => cd !== parentCd)
        : newCheckedchbox;

      // Similarly, update the parent name
      const parentName = data.result.find(
        (item) => item.ATTRIB_CD === parentCd && item.IS_PARENT === null
      )?.ATTRIB_NAME;

      const updatedCheckNames = parentCd
        ? data.result
            .filter(
              (item) =>
                item.ATTRIB_GRP_TYP === parentCd && item.IS_PARENT === null
            )
            .map((item) => item.ATTRIB_NAME)
            .every((name) => newCheckNames.includes(name))
          ? [...newCheckNames, parentName].filter((name) => name !== undefined)
          : newCheckNames.filter((name) => name !== parentName)
        : newCheckNames;

      return {
        check: updatedParentCheck,
        checkedckbox: updatedParentCheckedbox,
        checkName: updatedCheckNames,
      };
    });
  };

  return (
    <div className="wrapper_form">
      <div className="title">Signup Form</div>
      <form onSubmit={handleSubmit}>
        <div className="tabs">
          <button
            type="button"
            className={`tab-button ${tabValue === 0 ? "active" : ""}`}
            onClick={() => handleTabChange(0)}
          >
            Company Setup
          </button>
          <button
            type="button"
            // disabled={!firstcomplete}
            className={`tab-button ${tabValue === 1 ? "active" : ""}`}
            onClick={() => handleTabChange(1)}
          >
            Leave Types
          </button>
          <button
            type="button"
            // disabled={!secondcomplete}

            className={`tab-button ${tabValue === 2 ? "active" : ""}`}
            onClick={() => handleTabChange(2)}
          >
            Configuration Setup 1
          </button>
          <button
            type="button"
            // disabled={!thirdcomplete}
            className={`tab-button ${tabValue === 3 ? "active" : ""}`}
            onClick={() => handleTabChange(3)}
          >
            Configuration Setup 2
          </button>
        </div>

        {tabValue === 0 && (
          <div className="form-container">
            <hr className="hr_ttl" />
            <p
              style={{
                fontSize: "25px",
                paddingTop: "5px",
                color: "black",
              }}
            >
              Organization information
            </p>
            <hr className="hr_dt" style={{ marginBottom: "19px" }} />
            {/* Group fields as needed */}
            <div className="row" style={{ marginBottom: "16px" }}>
              <div className="column">
                <div className="field">
                  <input
                    type="text"
                    value={formData.org_fullnm || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, org_fullnm: e.target.value })
                    }
                  />
                  <label>
                    Organization Full Name{" "}
                    {errors.org_fullnm && (
                      <span className="error-message">{errors.org_fullnm}</span>
                    )}
                  </label>
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <input
                    type="text"
                    value={formData.org_snm || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, org_snm: e.target.value })
                    }
                  />
                  <label>
                    Orgnization Short Name{" "}
                    {errors.org_snm && (
                      <span className="error-message">{errors.org_snm}</span>
                    )}
                  </label>
                </div>
              </div>

              <div className="column">
                <div className="field">
                  <input
                    type="text"
                    value={formData.org_unicode || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, org_unicode: e.target.value })
                    }
                  />
                  <label>
                    Orgnization Unique code{" "}
                    {errors.org_unicode && (
                      <span className="error-message">
                        {errors.org_unicode}
                      </span>
                    )}
                  </label>
                </div>
              </div>
            </div>
            <div className="row" style={{ marginBottom: "15px" }}>
              <div className="column">
                <div className="field">
                  <input
                    type="text"
                    value={formData.tan || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, tan: e.target.value })
                    }
                  />
                  <label>
                    TAN
                    {/* {errors.tan && (
                    <span className="error-message">{errors.tan}</span>
                  )} */}
                  </label>
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <input
                    type="text"
                    value={formData.gst || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, gst: e.target.value })
                    }
                  />
                  <label>
                    GST
                    {/* {errors.gst && (
                    <span className="error-message">{errors.gst}</span>
                  )} */}
                  </label>
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <input
                    type="text"
                    value={formData.pan || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, pan: e.target.value })
                    }
                  />
                  <label>
                    PAN
                    {/* {errors.pan && (
                    <span className="error-message">{errors.pan}</span>
                  )} */}
                  </label>
                </div>
              </div>
            </div>

            <hr className="hr_ttl" />
            <p
              style={{
                fontSize: "25px",
                paddingTop: "5px",
                color: "black",
              }}
            >
              Admin Personal information
            </p>
            <hr className="hr_dt" />
            <div className="row" style={{ marginBottom: "1px" }}>
              <div className="column drp_ln_hg">
                <label className="drp_mg">
                  User Type{" "}
                  {errors.usr_typ && (
                    <span className="error-message">{errors.usr_typ}</span>
                  )}{" "}
                </label>
                <div className="field">
                  <span>
                    <i className="fas fa-unlock"></i>
                  </span>
                  <select
                    className="dropdown_p1"
                    value={formData.usr_typ}
                    onChange={(e) => handleChange(e, "usr_typ")}
                  >
                    <option value="">Select User Type</option>
                    {usrdata.usrtype.map((type) => (
                      <option value={type.ATTRIB_CD}>{type.ATTRIB_NAME}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="column drp_ln_hg">
                <label className="drp_mg">
                  User Category{" "}
                  {errors.userCategory && (
                    <span className="error-message">{errors.userCategory}</span>
                  )}
                </label>
                <div className="field">
                  <span>
                    <i className="fas fa-unlock"></i>
                  </span>
                  <select
                    className="dropdown_p1"
                    value={formData.userCategory}
                    onChange={(e) => handleChange(e, "userCategory")}
                  >
                    <option value="">Select User Category</option>
                    {usrdata.usrcat.map((type) => (
                      <option value={type.ATTRIB_CD}>{type.ATTRIB_NAME}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="column drp_ln_hg">
                <label className="drp_mg">
                  User Post{" "}
                  {errors.userPost && (
                    <span className="error-message">{errors.userPost}</span>
                  )}
                </label>
                <div className="field">
                  <span>
                    <i className="fas fa-unlock"></i>
                  </span>
                  <select
                    className="dropdown_p1"
                    value={formData.userPost}
                    onChange={(e) => handleChange(e, "userPost")}
                  >
                    <option value="">Select User Post</option>
                    {usrdata.Desgn.map((type) => (
                      <option value={type.ATTRIB_CD}>{type.ATTRIB_NAME}</option>
                    ))}
                  </select>
                </div>
              </div>
            </div>
            <div className="row" style={{ marginBottom: "15px" }}>
              <div className=" drp_ln_hg">
                <label style={{ marginLeft: "14%" }}>Title</label>
                <div className="field">
                  <select
                    className="dropdown_p1"
                    // tyle={{ width: "36%" }}
                    value={formData.userTitle}
                    onChange={(e) => handleChange(e, "userTitle")}
                  >
                    <option value="">Select Title</option>
                    {usrdata.usrtitle.map((type) => (
                      <option value={type.ATTRIB_CD}>{type.ATTRIB_NAME}</option>
                    ))}
                  </select>
                </div>
              </div>
              <div className="column" style={{ margin: "19px 12px 0 0" }}>
                <div className="field">
                  <input
                    type="text"
                    value={formData.usr_fnm || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, usr_fnm: e.target.value })
                    }
                  />
                  <label>
                    User First Name{" "}
                    {errors.usr_fnm && (
                      <span className="error-message">{errors.usr_fnm}</span>
                    )}
                  </label>
                </div>
              </div>
              <div className="column" style={{ margin: "19px 12px 0 0" }}>
                <div className="field">
                  <input
                    type="text"
                    value={formData.usr_mnm || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, usr_mnm: e.target.value })
                    }
                  />
                  <label>User Middle Name</label>
                </div>
              </div>
              <div className="column" style={{ margin: "19px 12px 0 0" }}>
                <div className="field">
                  <input
                    type="text"
                    value={formData.usr_lnm || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, usr_lnm: e.target.value })
                    }
                  />
                  <label>
                    User Last Name
                    {errors.usr_lnm && (
                      <span className="error-message">{errors.usr_lnm}</span>
                    )}
                  </label>
                </div>
              </div>
            </div>
            <div className="row" style={{ marginBottom: "15px" }}>
              <div className="column">
                <div className="field">
                  <input
                    type="text"
                    value={formData.usernm || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, usernm: e.target.value })
                    }
                  />
                  <label>
                    Admin UserName{" "}
                    {errors.usernm && (
                      <span className="error-message">{errors.usernm}</span>
                    )}
                  </label>
                </div>
              </div>

              <div className="column">
                <div className="field">
                  <input
                    type="password"
                    value={formData.usr_pass || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, usr_pass: e.target.value })
                    }
                  />
                  <label>
                    Password{" "}
                    {errors.usr_pass && (
                      <span className="error-message">{errors.usr_pass}</span>
                    )}
                  </label>
                </div>
              </div>
            </div>

            <hr className="hr_ttl" />
            <p
              style={{
                fontSize: "25px",
                paddingTop: "5px",
                color: "black",
              }}
            >
              Address information
            </p>
            <hr className="hr_dt" style={{ marginBottom: "19px" }} />

            <div className="row" style={{ marginBottom: "15px" }}>
              <div className="column">
                <div className="field">
                  <input
                    type="text"
                    value={formData.addressLine1 || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, addressLine1: e.target.value })
                    }
                  />
                  <label>
                    Address Line 1
                    {errors.addressLine1 && (
                      <span className="error-message">
                        {errors.addressLine1}
                      </span>
                    )}
                  </label>
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <span>
                    <i className="fas fa-address-book"></i>
                  </span>
                  <input
                    type="text"
                    value={formData.city || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, city: e.target.value })
                    }
                  />
                  <label>
                    City
                    {errors.city && (
                      <span className="error-message">{errors.city}</span>
                    )}
                  </label>
                </div>
              </div>
            </div>
            <div className="row" style={{ marginBottom: "15px" }}>
              <div className="column">
                <div className="field">
                  <input
                    type="text"
                    value={formData.addressLine2 || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, addressLine2: e.target.value })
                    }
                  />
                  <label>
                    Address Line 2
                    {/* {errors.addressLine2 && (
                    <span className="error-message">{errors.addressLine2}</span>
                  )} */}
                  </label>
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <input
                    type="text"
                    value={formData.pin || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, pin: e.target.value })
                    }
                  />
                  <label>
                    PIN
                    {errors.pin && (
                      <span className="error-message">{errors.pin}</span>
                    )}
                  </label>
                </div>
              </div>
            </div>
            <div className="row" style={{ marginBottom: "1px" }}>
              <div className="column">
                <div className="field">
                  <input
                    type="text"
                    value={formData.addressLine3 || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, addressLine3: e.target.value })
                    }
                  />
                  <label>
                    Address Line 3
                    {/* {errors.addressLine3 && (
                    <span className="error-message">{errors.addressLine3}</span>
                  )} */}
                  </label>
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <input
                    type="text"
                    value={formData.state || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, state: e.target.value })
                    }
                  />
                  <label>
                    State
                    {errors.state && (
                      <span className="error-message">{errors.state}</span>
                    )}
                  </label>
                </div>
              </div>
            </div>
            <div className="row" style={{ marginBottom: "15px" }}>
              <div className="column drp_ln_hg">
                <label className="drp_mg">Country</label>
                <div className="field">
                  <select
                    className="dropdown_p1"
                    onChange={(e) => handleChange(e, "country")}
                    value="100"
                  >
                    <option value="">---Select Country---</option>
                    {data.country_res.map((type) => (
                      <option value={type.CNTRY_KY}>{type.CNTRY_NM}</option>
                    ))}
                  </select>
                </div>
              </div>

              <div className="column drp_ln_hg">
                <label className="drp_mg">
                  Landmark
                  {/* {errors.landmark && (
                    <span className="error-message">{errors.landmark}</span>
                  )} */}
                </label>
                <div className="field">
                  <input
                    // className="ys"
                    type="text"
                    value={formData.landmark || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, landmark: e.target.value })
                    }
                  />
                </div>
              </div>
            </div>
            <div className="row" style={{ marginBottom: "15px" }}>
              <div className="column">
                <div className="field">
                  <input
                    type="text"
                    value={formData.gmapLink || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, gmapLink: e.target.value })
                    }
                  />
                  <label>
                    Google Map Link
                    {/* {errors.gmapLink && (
                    <span className="error-message">{errors.gmapLink}</span>
                  )} */}
                  </label>
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <input
                    type="email"
                    value={formData.email || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, email: e.target.value })
                    }
                  />
                  <label>
                    Email
                    {errors.email && (
                      <span className="error-message">{errors.email}</span>
                    )}
                  </label>
                </div>
              </div>
            </div>

            <div className="row" style={{ marginBottom: "15px" }}>
              <div className="column">
                <div className="field">
                  <input
                    type="tel"
                    value={formData.phone1 || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, phone1: e.target.value })
                    }
                  />
                  <label>
                    Phone 1
                    {errors.phone1 && (
                      <span className="error-message">{errors.phone1}</span>
                    )}
                  </label>
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <input
                    type="tel"
                    value={formData.phone2 || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, phone2: e.target.value })
                    }
                  />
                  <label>
                    Phone 2
                    {/* {errors.phone2 && (
                    <span className="error-message">{errors.phone2}</span>
                  )} */}
                  </label>
                </div>
              </div>
            </div>

            <hr className="hr_ttl" />
            <p
              style={{
                fontSize: "25px",
                paddingTop: "5px",
                color: "black",
              }}
            >
              Admin Social Media information
            </p>
            <hr className="hr_dt" style={{ marginBottom: "15px" }} />

            <div className="row" style={{ marginBottom: "15px" }}>
              <div className="column">
                <div className="field">
                  <input
                    type="text"
                    value={formData.website || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, website: e.target.value })
                    }
                  />
                  <label>
                    Website
                    {errors.website && (
                      <span className="error-message">{errors.website}</span>
                    )}
                  </label>
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <input
                    type="text"
                    value={formData.socialMedia1 || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, socialMedia1: e.target.value })
                    }
                  />
                  <label>
                    Social Media Link 1
                    {errors.socialMedia1 && (
                      <span className="error-message">
                        {errors.socialMedia1}
                      </span>
                    )}
                  </label>
                </div>
              </div>
            </div>
            <div className="row" style={{ marginBottom: "15px" }}>
              <div className="column">
                <div className="field">
                  <input
                    type="text"
                    value={formData.socialMedia2 || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, socialMedia2: e.target.value })
                    }
                  />
                  <label>
                    Social Media Link 2
                    {/* {errors.socialMedia2 && (
                    <span className="error-message">{errors.socialMedia2}</span>
                  )} */}
                  </label>
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <input
                    type="text"
                    value={formData.socialMedia3 || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, socialMedia3: e.target.value })
                    }
                  />
                  <label>Social Media Link 3</label>
                </div>
              </div>
              <div className="column">
                <div className="field">
                  <input
                    type="text"
                    value={formData.socialMedia4 || ""}
                    onChange={(e) =>
                      setFormData({ ...formData, socialMedia4: e.target.value })
                    }
                  />
                  <label>Social Media Link 4</label>
                </div>
              </div>
            </div>
            <div className="row" style={{ marginBottom: "15px" }}>
              <div className="column"></div>
              <div className="column"></div>
              <div className="column"></div>
              <div className="column"></div>
              <div className="column">
                <div className="field">
                  <button
                    type="submit"
                    className="btn-action action-button"
                    onClick={sub_first_tab}
                  >
                    Submit & Next
                  </button>
                </div>
              </div>
            </div>
          </div>
        )}
        {tabValue === 1 && (
          <div className="tab-content">
            <div className="form-container">
              {data.result.map((item) =>
                item.ATTRIB_CD === "paid" ? (
                  <div
                    key={item.ATTRIB_CD}
                    className="row"
                    style={{ marginBottom: "15px" }}
                  >
                    <div>
                      <label style={{ fontSize: "30px" }}>
                        <b>{item.ATTRIB_NAME}</b>
                      </label>
                    </div>
                  </div>
                ) : item.ATTRIB_GRP_TYP === "paid" ? (
                  <div key={item.ATTRIB_CD} className="row group-container">
                    <div className="label-container">
                      <label>
                        <Checkbox
                          name={item.ATTRIB_CD}
                          checked={checked.checkedckbox.includes(
                            item.ATTRIB_CD
                          )}
                          onChange={handleParentChange}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                        <b>{item.ATTRIB_NAME}</b>
                      </label>
                    </div>

                    <div className="checkbox-container">
                      {data.result
                        .filter(
                          (item1) => item1.ATTRIB_GRP_TYP === item.ATTRIB_CD
                        )
                        .map((item1) => (
                          <div key={item1.ATTRIB_CD} className="checkbox-item">
                            <Checkbox
                              checked={checked.checkedckbox.includes(
                                item1.ATTRIB_CD
                              )}
                              name={item1.ATTRIB_CD}
                              onChange={handleChange1}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                            {item1.ATTRIB_NAME}
                          </div>
                        ))}
                    </div>
                  </div>
                ) : null
              )}

              {data.result.map((item) =>
                item.ATTRIB_CD === "unpaid" ? (
                  <div
                    key={item.ATTRIB_CD}
                    className="row"
                    style={{ marginBottom: "15px" }}
                  >
                    <div>
                      <label style={{ fontSize: "30px" }}>
                        <b>{item.ATTRIB_NAME}</b>
                      </label>
                    </div>
                  </div>
                ) : item.ATTRIB_GRP_TYP === "unpaid" ? (
                  <div key={item.ATTRIB_CD} className="row group-container">
                    <div className="label-container">
                      <label>
                        <Checkbox
                          checked={checked.checkedckbox.includes(
                            item.ATTRIB_CD
                          )}
                          name={item.ATTRIB_CD}
                          onChange={handleParentChange}
                          inputProps={{ "aria-label": "controlled" }}
                        />
                        <b>{item.ATTRIB_NAME}</b>
                      </label>
                    </div>
                    <div className="checkbox-container">
                      {data.result
                        .filter(
                          (item1) => item1.ATTRIB_GRP_TYP === item.ATTRIB_CD
                        )
                        .map((item1) => (
                          <div key={item1.ATTRIB_CD} className="checkbox-item">
                            <Checkbox
                              checked={checked.checkedckbox.includes(
                                item1.ATTRIB_CD
                              )}
                              name={item1.ATTRIB_CD}
                              onChange={handleChange1}
                              inputProps={{ "aria-label": "controlled" }}
                            />
                            {item1.ATTRIB_NAME}
                          </div>
                        ))}
                    </div>
                  </div>
                ) : null
              )}

              <div className="row" style={{ marginBottom: "15px" }}>
                <div className="column">
                  <button
                    type="button"
                    className="btn-action action-button"
                    onClick={() => handleTabChange(0)}
                  >
                    Previous
                  </button>
                </div>
                <div className="column"></div>
                <div className="column"></div>
                <div className="column"></div>
                <div className="column">
                  <div className="field">
                    <button
                      type="button"
                      className="btn-action action-button"
                      onClick={sub_second_tab}
                    >
                      Submit & Next
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        )}

        {tabValue === 2 && (
          <>
            <LveTypRegTab
              holidays={checked}
              gender={gender}
              gtabdata={setrecivedData}
            />
            <div className="row" style={{ marginBottom: "15px" }}>
              <div className="column">
                <button
                  type="button"
                  className="btn-action action-button"
                  onClick={() => handleTabChange(1)}
                >
                  Previous
                </button>
              </div>
              <div className="column"></div>
              <div className="column"></div>
              <div className="column"></div>
              <div className="column">
                <div className="field">
                  <button
                    type="button"
                    className="btn-action action-button"
                    onClick={sub_third_tab}
                  >
                    Submit & Next
                  </button>
                </div>
              </div>
            </div>
          </>
        )}
        {tabValue === 3 && (
          <>
            <div className="tab-content">
              {/* Step 3 Fields */}
              <div className="form-container">
                {/* Fields for Step 3 */}
                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="column">
                    <label>Increment Days in Joining Month Allowed ?</label>
                  </div>
                  <div className="column">
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="LVE_ALOW_IN_JOIN_MN_YN"
                        value={form.name}
                        onChange={handleChangethirdtab}
                      >
                        <FormControlLabel
                          className="mgl_1"
                          value="yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="column">
                    {errors.LVE_ALOW_IN_JOIN_MN_YN && (
                      <span className="error-message">
                        {errors.LVE_ALOW_IN_JOIN_MN_YN}
                      </span>
                    )}
                  </div>
                </div>

                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="column">
                    <label className=" ">Leave Calender Start Month :</label>
                  </div>

                  <div className="column">
                    <div className="field">
                      <span>
                        <i className="fas fa-unlock"></i>
                      </span>
                      <select
                        name="LVE_YR_STRT"
                        value={form.name}
                        onChange={handleChangethirdtab}
                      >
                        <option value="">--Select Month--</option>
                        {months.map((month, index) => (
                          <option key={index} value={month.value}>
                            {month.label}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>
                  <div className="column">
                    {errors.LVE_YR_STRT && (
                      <span className="error-message">
                        {errors.LVE_YR_STRT}
                      </span>
                    )}
                  </div>
                </div>

                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="column" style={{ width: "100%" }}>
                    <label>Working Days?</label>
                  </div>

                  {/* Row for "All" checkbox */}
                  <div
                    className="column"
                    style={{
                      display: "flex",
                      flexDirection: "row",
                      marginBottom: "10px",
                    }}
                  >
                    <FormControlLabel
                      control={
                        <Checkbox
                          checked={form.WORKING_DAYS.includes("all")}
                          onChange={() => handleDayChange("all")}
                        />
                      }
                      label="All"
                    />

                    {/* Row for individual day checkboxes */}

                    <FormGroup
                      style={{
                        display: "flex",
                        flexDirection: "row",
                        // flexWrap: "wrap",
                      }}
                    >
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={form.WORKING_DAYS.includes("monday")}
                            onChange={() => handleDayChange("monday")}
                          />
                        }
                        label="Monday"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={form.WORKING_DAYS.includes("tuesday")}
                            onChange={() => handleDayChange("tuesday")}
                          />
                        }
                        label="Tuesday"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={form.WORKING_DAYS.includes("wednesday")}
                            onChange={() => handleDayChange("wednesday")}
                          />
                        }
                        label="Wednesday"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={form.WORKING_DAYS.includes("thursday")}
                            onChange={() => handleDayChange("thursday")}
                          />
                        }
                        label="Thursday"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={form.WORKING_DAYS.includes("friday")}
                            onChange={() => handleDayChange("friday")}
                          />
                        }
                        label="Friday"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={form.WORKING_DAYS.includes("saturday")}
                            onChange={() => handleDayChange("saturday")}
                          />
                        }
                        label="Saturday"
                      />
                      <FormControlLabel
                        control={
                          <Checkbox
                            checked={form.WORKING_DAYS.includes("sunday")}
                            onChange={() => handleDayChange("sunday")}
                          />
                        }
                        label="Sunday"
                      />
                    </FormGroup>
                  </div>

                  {/* Error message for WORKING_DAYS */}
                  <div className="column">
                    {errors.WORKING_DAYS && (
                      <span className="error-message">
                        {errors.WORKING_DAYS}
                      </span>
                    )}
                  </div>
                </div>

                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="column">
                    <label>Half Day Allow ?</label>
                  </div>

                  <div className="column">
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="LVE_HD_AVIL_YN"
                        value={form.name}
                        onChange={handleChangethirdtab}
                      >
                        <FormControlLabel
                          className="mgl_1"
                          value="yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="column">
                    {errors.LVE_HD_AVIL_YN && (
                      <span className="error-message">
                        {errors.LVE_HD_AVIL_YN}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="column">
                    <label>
                      Include Half and Full Day in One Application ?
                    </label>
                  </div>

                  <div className="column">
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="LVE_MIN_UOM_HR_HD_FD"
                        value={form.name}
                        onChange={handleChangethirdtab}
                      >
                        <FormControlLabel
                          className="mgl_1"
                          value="yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="column">
                    {errors.LVE_MIN_UOM_HR_HD_FD && (
                      <span className="error-message">
                        {errors.LVE_MIN_UOM_HR_HD_FD}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="column">
                    <label className=" ">Leave Allotment Method :</label>
                  </div>

                  <div className="column">
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="LVE_ADD_MNLY_YRLY"
                        value={form.name}
                        onChange={handleChangethirdtab}
                      >
                        <FormControlLabel
                          className="mgl_1"
                          value="Monthly"
                          control={<Radio />}
                          label="Monthly"
                        />
                        <FormControlLabel
                          value="Yearly"
                          control={<Radio />}
                          label="Yearly"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="column">
                    {errors.LVE_ADD_MNLY_YRLY && (
                      <span className="error-message">
                        {errors.LVE_ADD_MNLY_YRLY}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="column">
                    <label>Minimum Consumsion Allow ?</label>
                  </div>

                  <div className="column">
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="LVE_MIN_CNSMSN_YN"
                        value={form.name}
                        onChange={handleChangethirdtab}
                      >
                        <FormControlLabel
                          className="mgl_1"
                          value="yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="column">
                    {errors.LVE_MIN_CNSMSN_YN && (
                      <span className="error-message">
                        {errors.LVE_MIN_CNSMSN_YN}
                      </span>
                    )}
                  </div>
                </div>

                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="column">
                    <label>Minimum Consumsion of Year</label>
                  </div>

                  <div className="column ">
                    <div className="field">
                      <input
                        className="input_wd"
                        type="number"
                        name="LVE_TYP_MIN_CONSMSN"
                        value={form.name}
                        onChange={handleChangethirdtab}
                        min="0"
                        max="999" // Maximum value for three digits
                      />
                    </div>
                  </div>
                  <div className="column">
                    {errors.LVE_TYP_MIN_CONSMSN && (
                      <span className="error-message">
                        {errors.LVE_TYP_MIN_CONSMSN}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="column">
                    <label>Waived Balance Caary Forword for # Year</label>
                  </div>

                  <div className="column">
                    <div className="field">
                      <span>
                        <i className="fas fa-envelope"></i>
                      </span>
                      <input
                        className="input_wd"
                        name="LVE_TYP_CRRFW_HOLDE_CNT"
                        type="number"
                        value={form.name}
                        onChange={handleChangethirdtab}
                      />
                    </div>
                  </div>
                  <div className="column">
                    {errors.LVE_TYP_CRRFW_HOLDE_CNT && (
                      <span className="error-message">
                        {errors.LVE_TYP_CRRFW_HOLDE_CNT}
                      </span>
                    )}
                  </div>
                </div>

                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="column">
                    <label>OBO Allowed </label>
                  </div>

                  <div className="column">
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="LVE_APP_OBO_ALWD"
                        value={form.name}
                        onChange={handleChangethirdtab}
                      >
                        <FormControlLabel
                          className="mgl_1"
                          value="yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="column">
                    {errors.LVE_APP_OBO_ALWD && (
                      <span className="error-message">
                        {errors.LVE_APP_OBO_ALWD}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="column">
                    <label>Backdated Leaves Allowed ?</label>
                  </div>

                  <div className="column">
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="LVE_APP_BKDTED_ALOW"
                        value={form.name}
                        onChange={handleChangethirdtab}
                      >
                        <FormControlLabel
                          className="mgl_1"
                          value="yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="column">
                    {errors.LVE_APP_BKDTED_ALOW && (
                      <span className="error-message">
                        {errors.LVE_APP_BKDTED_ALOW}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="column">
                    <label>Maximum Backdated Days :</label>
                  </div>

                  <div className="column">
                    <div className="field">
                      <span>
                        <i className="fas fa-envelope"></i>
                      </span>
                      <input
                        className="input_wd"
                        type="number"
                        name="LVE_APP_BKDTED_MAX_DES"
                        value={form.name}
                        onChange={handleChangethirdtab}
                      />
                    </div>
                  </div>
                  <div className="column">
                    {errors.LVE_APP_BKDTED_MAX_DES && (
                      <span className="error-message">
                        {errors.LVE_APP_BKDTED_MAX_DES}
                      </span>
                    )}
                  </div>
                </div>

                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="column">
                    <label>After Last Year Closing, Backdated Allowed ?</label>
                  </div>

                  <div className="column">
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="LVE_APP_AFTR_CLOSN_YN"
                        value={form.name}
                        onChange={handleChangethirdtab}
                      >
                        <FormControlLabel
                          className="mgl_1"
                          value="yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="column">
                    {errors.LVE_APP_AFTR_CLOSN_YN && (
                      <span className="error-message">
                        {errors.LVE_APP_AFTR_CLOSN_YN}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="column">
                    <label>Increment Days in Joining Month Allowed ?</label>
                  </div>

                  <div className="column">
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="LVE_ALOW_IN_JOIN_MN_YN"
                        value={form.name}
                        onChange={handleChangethirdtab}
                      >
                        <FormControlLabel
                          className="mgl_1"
                          value="yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="column">
                    {errors.LVE_ALOW_IN_JOIN_MN_YN && (
                      <span className="error-message">
                        {errors.LVE_ALOW_IN_JOIN_MN_YN}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="column">
                    <label>Awail After Period :</label>
                  </div>

                  <div className="column">
                    <div className="field">
                      <span>
                        <i className="fas fa-envelope"></i>
                      </span>
                      <input
                        className="input_wd"
                        type="number"
                        name="LVE_APP_ALOW_AFTR_JOIN"
                        value={form.name}
                        onChange={handleChangethirdtab}
                      />
                    </div>
                  </div>
                  <div className="column">
                    {errors.LVE_APP_ALOW_AFTR_JOIN && (
                      <span className="error-message">
                        {errors.LVE_APP_ALOW_AFTR_JOIN}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="column">
                    <label className=" ">Status Pending Alert Days :</label>
                  </div>

                  <div className="column">
                    <div className="field">
                      <span>
                        <i className="fas fa-unlock"></i>
                      </span>
                      <input
                        className="input_wd"
                        type="number"
                        name="LVE_APP_STTS_ALRT_DES"
                        value={form.name}
                        onChange={handleChangethirdtab}
                      />
                    </div>
                  </div>
                  <div className="column">
                    {errors.LVE_APP_STTS_ALRT_DES && (
                      <span className="error-message">
                        {errors.LVE_APP_STTS_ALRT_DES}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="column">
                    <label className=" ">
                      Maximum days Allowed in One Application :
                    </label>
                  </div>

                  <div className="column">
                    <div className="field">
                      <span>
                        <i className="fas fa-phone"></i>
                      </span>
                      <input
                        className="input_wd"
                        type="number"
                        name="LVE_APP_MAX_DES"
                        value={form.name}
                        onChange={handleChangethirdtab}
                      />
                    </div>
                  </div>
                  <div className="column">
                    {errors.LVE_APP_MAX_DES && (
                      <span className="error-message">
                        {errors.LVE_APP_MAX_DES}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="column">
                    <label>Number of Leave Application in One Week :</label>
                  </div>

                  <div className="column ">
                    <div className="field">
                      <span>
                        <i className="fas fa-phone"></i>
                      </span>
                      <input
                        className="input_wd"
                        type="number"
                        name="LVE_APP_IN_CNT_IN_WIK"
                        value={form.name}
                        onChange={handleChangethirdtab}
                      />
                    </div>
                  </div>
                  <div className="column">
                    {errors.LVE_APP_IN_CNT_IN_WIK && (
                      <span className="error-message">
                        {errors.LVE_APP_IN_CNT_IN_WIK}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="column">
                    <label className=" ">
                      Maximum Future Days Leave Allowed Application :
                    </label>
                  </div>
                  <div className="column">
                    <div className="field">
                      <span>
                        <i className="fas fa-phone"></i>
                      </span>
                      <input
                        className="input_wd"
                        type="number"
                        name="LVE_APP_NXT_DE_CNT"
                        value={form.name}
                        onChange={handleChangethirdtab}
                      />
                    </div>
                  </div>
                  <div className="column">
                    {errors.LVE_APP_NXT_DE_CNT && (
                      <span className="error-message">
                        {errors.LVE_APP_NXT_DE_CNT}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="column">
                    <label className=" ">
                      How Many Years Display Entered Application in List ?
                    </label>
                  </div>

                  <div className="column">
                    <div className="field">
                      <span>
                        <i className="fas fa-phone"></i>
                      </span>
                      <input
                        className="input_wd"
                        type="number"
                        name="LVE_APP_DISP_LST_YR"
                        value={form.name}
                        onChange={handleChangethirdtab}
                      />
                    </div>
                  </div>
                  <div className="column">
                    {errors.LVE_APP_DISP_LST_YR && (
                      <span className="error-message">
                        {errors.LVE_APP_DISP_LST_YR}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="column">
                    <label>List of Holiday for Next Year Display ? </label>
                  </div>
                  <div className="column">
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="LVE_NXT_HLDCLNR_DISP_YN"
                        value={form.name}
                        onChange={handleChangethirdtab}
                      >
                        <FormControlLabel
                          className="mgl_1"
                          value="yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="column">
                    {errors.LVE_NXT_HLDCLNR_DISP_YN && (
                      <span className="error-message">
                        {errors.LVE_NXT_HLDCLNR_DISP_YN}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="column">
                    <label>List of Holiday for Past Year Display ? </label>
                  </div>
                  <div className="column">
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="LVE_PST_HLDCLNR_DISP_YN"
                        value={form.name}
                        onChange={handleChangethirdtab}
                      >
                        <FormControlLabel
                          className="mgl_1"
                          value="yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="column">
                    {errors.LVE_PST_HLDCLNR_DISP_YN && (
                      <span className="error-message">
                        {errors.LVE_PST_HLDCLNR_DISP_YN}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="column">
                    <label>User ID Max Length :</label>
                  </div>
                  <div className="column">
                    <div className="field">
                      <span>
                        <i className="fas fa-address-book"></i>
                      </span>
                      <input
                        className="input_wd"
                        type="number"
                        name="USR_ID_MAX_LNTH"
                        value={form.name}
                        onChange={handleChangethirdtab}
                      />
                    </div>
                  </div>
                  <div className="column">
                    {errors.USR_ID_MAX_LNTH && (
                      <span className="error-message">
                        {errors.USR_ID_MAX_LNTH}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="column">
                    <label>User ID Min Length :</label>
                  </div>
                  <div className="column">
                    <div className="field">
                      <span>
                        <i className="fas fa-address-book"></i>
                      </span>
                      <input
                        className="input_wd"
                        type="number"
                        name="USR_ID_MIN_LNTH"
                        value={form.name}
                        onChange={handleChangethirdtab}
                      />
                    </div>
                  </div>
                  <div className="column">
                    {errors.USR_ID_MIN_LNTH && (
                      <span className="error-message">
                        {errors.USR_ID_MIN_LNTH}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="column">
                    <label>Password Max Length :</label>
                  </div>
                  <div className="column">
                    <div className="field">
                      <span>
                        <i className="fas fa-address-book"></i>
                      </span>
                      <input
                        className="input_wd"
                        type="number"
                        name="PWD_MAX_LNTH"
                        value={form.name}
                        onChange={handleChangethirdtab}
                      />
                    </div>
                  </div>
                  <div className="column">
                    {errors.PWD_MAX_LNTH && (
                      <span className="error-message">
                        {errors.PWD_MAX_LNTH}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="column">
                    <label>Password Min Length :</label>
                  </div>
                  <div className="column">
                    <div className="field">
                      <span>
                        <i className="fas fa-address-book"></i>
                      </span>
                      <input
                        className="input_wd"
                        type="number"
                        name="PWD_MIN_LNTH"
                        value={form.name}
                        onChange={handleChangethirdtab}
                      />
                    </div>
                  </div>
                  <div className="column">
                    {errors.PWD_MIN_LNTH && (
                      <span className="error-message">
                        {errors.PWD_MIN_LNTH}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="column">
                    <label>Password Number is Allowed ? </label>
                  </div>
                  <div className="column">
                    <FormControl>
                      <RadioGroup
                        row
                        aria-labelledby="demo-row-radio-buttons-group-label"
                        name="PWD_NUMBR_YN"
                        value={form.name}
                        onChange={handleChangethirdtab}
                      >
                        <FormControlLabel
                          className="mgl_1"
                          value="yes"
                          control={<Radio />}
                          label="Yes"
                        />
                        <FormControlLabel
                          value="no"
                          control={<Radio />}
                          label="No"
                        />
                      </RadioGroup>
                    </FormControl>
                  </div>
                  <div className="column">
                    {errors.PWD_NUMBR_YN && (
                      <span className="error-message">
                        {errors.PWD_NUMBR_YN}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="column">
                    <label>
                      List of Allowed Special Character in Password :
                    </label>
                  </div>
                  <div className="column">
                    <div className="field">
                      <span>
                        <i className="fas fa-envelope"></i>
                      </span>
                      <input
                        className="input_wd"
                        type="text"
                        name="PWD_SPL_CHAR_LST"
                        value={form.name}
                        onChange={handleChangethirdtab}
                      />
                    </div>
                  </div>
                  <div className="column">
                    {errors.PWD_SPL_CHAR_LST && (
                      <span className="error-message">
                        {errors.PWD_SPL_CHAR_LST}
                      </span>
                    )}
                  </div>
                </div>
                <div className="row" style={{ marginBottom: "15px" }}>
                  <div className="column">
                    <button
                      type="button"
                      className="btn-action action-button"
                      onClick={() => handleTabChange(2)}
                    >
                      Previous
                    </button>
                  </div>
                  <div className="column"></div>
                  <div className="column"></div>
                  <div className="column"></div>
                  <div className="column">
                    <div className="field">
                      <button
                        type="submit"
                        className="btn-action action-button"
                        onClick={sub_fourth_tab}
                      >
                        Submit
                      </button>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </>
        )}

        <div className="signup-link">
          Already a member? <Link to="/login">Login now</Link>
        </div>
      </form>
    </div>
  );
};

export default RegisterForm;

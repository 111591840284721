import React, { useState, useEffect } from "react";
import services from "./apiServices";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Grid,
} from "@mui/material";
import moment from 'moment';

export default function Manager_Report_Team_Mem_Lists() {
    const [leavecnt, Setleavecnt] = useState({
        login_lve_bal_cnt: [], lve_apl_dt: [], login_team_membr_inf_res: [],
        shw_upcom_hlod_res: [], empwise_lve_bal_res: [], all_usr_cnt_data: [], aflt_annocement_news_result: []
    });

    useEffect(() => {
        const lev_bal_cnt = async () => {
            try {
                const lve_cnt = await services.get_lev_bal_cnt();

                if (lve_cnt) {
                    Setleavecnt(lve_cnt);
                } else {
                    console.error("Unexpected data format:", lve_cnt);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        lev_bal_cnt();
    }, []);

    function formatDate(dateString) {
        return moment(dateString).format('DD/MMM/YYYY').replace(/\/([a-z])/g, (match) => '/' + match[1].toUpperCase());
    }

    console.log("leavecnt", leavecnt);

    // Create a unique list of employees based on USR_EMP_ID
    const uniqueEmployees = Array.from(new Map(leavecnt.empwise_lve_bal_res.map(item => [item.USR_EMP_ID, item])).values());

    return (
        <div className='wrapper_form'>
            <Grid item xs={12} style={{ paddingLeft: "1%", paddingRight: "1%" }}>
                <div className="title" style={{ marginTop: "2%" }}>Team Members Details</div>
                <TableContainer
                    component={Paper}
                    style={{
                        margin: "5px auto 25px auto",
                        overflow: "hidden",
                        boxShadow: "0px 6px 16px 3px #bababa",
                        borderRadius: "20px",
                        border: "2px solid gray",
                    }}
                >
                    <Table style={{ width: "100%" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Sr.No.</TableCell>
                                <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Name</TableCell>
                                <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Emp ID</TableCell>
                                <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Post</TableCell>
                                <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Joining Date</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {uniqueEmployees.map((employee, index) => (
                                <TableRow key={employee.USR_EMP_ID}>
                                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                        {index + 1}
                                    </TableCell>
                                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                        {employee.USR_DISPLAY_NAME}
                                    </TableCell>
                                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                        {employee.USR_EMP_ID}
                                    </TableCell>
                                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                        {employee.USR_POST}
                                    </TableCell>
                                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                        {formatDate(employee.USR_JOD)}
                                    </TableCell>
                                </TableRow>
                            ))}
                            {uniqueEmployees.length < 3 &&
                                Array.from({ length: 3 - uniqueEmployees.length }).map((_, index) => (
                                    < TableRow style={{ height: "10px" }} key={`empty-row-${index}`}>
                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray", lineHeight: ".2" }}>
                                            &nbsp; {/* Empty cell for spacing */}
                                        </TableCell>
                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray", lineHeight: ".2" }}>
                                            &nbsp; {/* Empty cell for spacing */}
                                        </TableCell>
                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray", lineHeight: ".2" }}>
                                            &nbsp; {/* Empty cell for spacing */}
                                        </TableCell>
                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray", lineHeight: ".2" }}>
                                            &nbsp; {/* Empty cell for spacing */}
                                        </TableCell>
                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray", lineHeight: ".2" }}>
                                            &nbsp; {/* Empty cell for spacing */}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </div>
    );
}
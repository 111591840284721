import React from "react";
import services from "./apiServices";
import { useNavigate } from 'react-router-dom';

export const Modal = ({ isOpen, onClose, onSubmit, formData, setFormData, title, timer }) => {
    const navigate = useNavigate();
    if (!isOpen) return null;

    const handleModalClick = (e) => {
        e.stopPropagation();
    };
    const forgetpass = async (e) => {
        e.preventDefault(); // Prevent default form submission
        try {
            const res = await services.forgetpassword(formData);
            if (res) {
                console.log("Password reset successfully");
                alert("Password Reset Successfully");
                navigate('/');
                // onClose(); // Close the modal after successful reset
            }
        } catch (error) {
            console.error("Error resetting password:", error);
        }
    };

    return (
        <div className='wrapper_form'>
            <div className="modal-overlay" onClick={onClose}>
                <div className="modal" onClick={handleModalClick}>
                    <div className="modal-header">
                        <h4 className="modal-title">{title}</h4>
                        <button className="close-button" onClick={onClose}>✖</button>
                    </div>
                    <form onSubmit={title === "Reset Password" ? forgetpass : onSubmit} style={{ padding: "10px 30px 100px 30px", borderRadius: "5px" }}>
                        {title === "Verify OTP" && (
                            <>
                                <div className="field">
                                    <div className="modal-title" style={{ marginBottom: "10px", color: "red" }}>
                                        Please check your email for the OTP.
                                    </div>
                                    <input
                                        style={{ marginTop: "5%" }}
                                        type="text"
                                        name="otp"
                                        value={formData.otp}
                                        placeholder="Enter OTP"
                                        onChange={(e) => setFormData({ ...formData, otp: e.target.value })}
                                    />
                                    <label style={{ marginTop: "14%" }}>Enter OTP</label>
                                    <button style={{ width: "40%", float: "right" }} type="submit" className="btn-action action-button">Verify OTP</button>
                                </div>
                                <div className="modal-title" style={{ marginTop: "15%", color: "#4a6d8c" }}>
                                    Time remaining: {timer} seconds
                                </div>
                            </>
                        )}
                        {title === "Reset Password" && (
                            <>
                                <div className="field">
                                    <input
                                        style={{ marginTop: "5%" }}
                                        type="password"
                                        name="new_pass"
                                        value={formData.new_pass}
                                        placeholder="Enter New Password"
                                        onChange={(e) => setFormData({ ...formData, new_pass: e.target.value })}
                                    />
                                    <label style={{ marginTop: "3%" }}>New Password</label>
                                </div>
                                <div className="field">
                                    <input
                                        style={{ marginTop: "8%" }}
                                        type="password"
                                        name="conf_pass"
                                        value={formData.conf_pass}
                                        placeholder="Enter Confirm Password"
                                        onChange={(e) => setFormData({ ...formData, conf_pass: e.target.value })}
                                    />
                                    <label style={{ marginTop: "7%" }}>Confirm Password</label>
                                </div>
                                <button style={{ width: "40%", float: "right" }} type="submit" className="btn-action action-button">Reset Password</button>
                            </>
                        )}
                    </form>
                </div>
            </div>
        </div>
    );
};

export default Modal;
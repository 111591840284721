import React, { useState, useEffect } from "react";
import services from "./apiServices";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Grid,
} from "@mui/material";
import moment from 'moment';
export default function Manager_Agine_Report_Of_Lve() {
    const [agingrpt, setagingrpt] = useState({ agine_rpt_mng_res: [] });
    useEffect(() => {
        const emp_aging_cnt = async () => {
            try {
                const res = await services.get_aging_dt_mn();

                if (res) {
                    setagingrpt(res)
                    console.log("Aging Data fetched succesfully of team members for manager")
                }
                else {
                    console.log("Failed to fetch data of  Aging report for manager")
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        }
        emp_aging_cnt();
    }, [])
    console.log("agingrpt", agingrpt)
    function formatDate(dateString) {
        // Format the date to 'DD/MMM/YYYY' with the first letter of the month capitalized
        return moment(dateString).format('DD/MMM/YYYY').replace(/\/([a-z])/g, (match) => '/' + match[1].toUpperCase());
    }
    // Calculate totals
    const totals = agingrpt.agine_rpt_mng_res.reduce((acc, applicantdata) => {
        acc.Less_Than_30_Days += applicantdata.Less_Than_30_Days || 0;
        acc.Days_31_To_60 += applicantdata.Days_31_To_60 || 0;
        acc.Days_61_To_90 += applicantdata.Days_61_To_90 || 0;
        acc.More_Than_90_Days += applicantdata.More_Than_90_Days || 0;
        return acc;
    }, {
        Less_Than_30_Days: 0,
        Days_31_To_60: 0,
        Days_61_To_90: 0,
        More_Than_90_Days: 0
    });
    return (
        <div className="wrapper_form">
            <Grid item xs={6} style={{ paddingLeft: "1%", paddingRight: "1%" }}>
                <div className="title" style={{ marginTop: "2%" }}>Team Members Aging Analyasis Details</div>
                <TableContainer
                    component={Paper}
                    style={{
                        margin: "5px auto 25px auto",
                        // maxWidth: "50%",
                        overflow: "hidden", // Prevents content overflow
                        boxShadow: "0px 6px 16px 3px #bababa", // Shadow only on the top and bottom
                        borderRadius: "20px", // Rounded corners for the container
                        border: "2px solid gray",

                    }}
                >
                    <Table style={{ width: "100%", }}>
                        <TableHead>
                            <TableRow style={{ width: "100%", }}>
                                <TableCell
                                    style={{
                                        fontSize: "0.8rem",
                                        padding: "1px 18px",
                                        backgroundColor: "#4a6d8c",
                                        border: "1px solid gray", color: "white", textAlign: "center"
                                    }}
                                    className=" txt_ttl1"
                                >
                                    Sr.No.
                                </TableCell>
                                <TableCell
                                    style={{
                                        fontSize: "0.8rem",
                                        padding: "1px 18px",
                                        backgroundColor: "#4a6d8c",
                                        border: "1px solid gray", color: "white", textAlign: "center"
                                    }}
                                    className=" txt_ttl1"
                                >
                                    Emp. ID
                                </TableCell>

                                <TableCell
                                    style={{
                                        fontSize: "0.8rem",
                                        padding: "1px",
                                        backgroundColor: "#4a6d8c",
                                        border: "1px solid gray", color: "white", textAlign: "center"
                                    }}
                                    className=" txt_ttl1"
                                >
                                    Name
                                </TableCell>
                                <TableCell
                                    style={{
                                        fontSize: "0.8rem",
                                        padding: "1px",
                                        backgroundColor: "#4a6d8c",
                                        border: "1px solid gray", color: "white", textAlign: "center"
                                    }}
                                    className=" txt_ttl1"
                                >
                                    Application Date
                                </TableCell>
                                <TableCell
                                    style={{
                                        fontSize: "0.8rem",
                                        padding: "1px",
                                        backgroundColor: "#4a6d8c",
                                        border: "1px solid gray", color: "white", textAlign: "center"
                                    }}
                                    className=" txt_ttl1"
                                >
                                    Leave Type
                                </TableCell>
                                <TableCell
                                    style={{
                                        fontSize: "0.8rem",
                                        padding: "1px",
                                        backgroundColor: "#4a6d8c",
                                        border: "1px solid gray", color: "white", textAlign: "center"
                                    }}
                                    className=" txt_ttl1"
                                >
                                    Upto 30 Days
                                </TableCell>

                                <TableCell
                                    style={{
                                        fontSize: "0.8rem",
                                        padding: "1px",
                                        backgroundColor: "#4a6d8c",
                                        border: "1px solid gray", color: "white", textAlign: "center"
                                    }}
                                    className=" txt_ttl1"
                                >
                                    31 To 60 Days
                                </TableCell>
                                <TableCell
                                    style={{
                                        fontSize: "0.8rem",
                                        padding: "1px",
                                        backgroundColor: "#4a6d8c",
                                        border: "1px solid gray", color: "white", textAlign: "center"
                                    }}
                                    className=" txt_ttl1"
                                >
                                    61 To 90 Days
                                </TableCell>
                                <TableCell
                                    style={{
                                        fontSize: "0.8rem",
                                        padding: "1px",
                                        backgroundColor: "#4a6d8c",
                                        border: "1px solid gray", color: "white", textAlign: "center"
                                    }}
                                    className=" txt_ttl1"
                                >
                                    More than 90 Days
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {agingrpt.agine_rpt_mng_res.map((aplicantdata, index) => (
                                <TableRow key={aplicantdata.leaveTypeId}>
                                    <TableCell
                                        align="center" // Center the content horizontally
                                        style={{
                                            fontSize: "0.8rem",
                                            padding: "1px",
                                            border: "1px solid gray",
                                        }}
                                    >
                                        {index + 1}
                                    </TableCell>
                                    <TableCell
                                        align="center" // Center the content horizontally
                                        style={{
                                            fontSize: "0.8rem",
                                            padding: "1px",
                                            border: "1px solid gray",
                                        }}
                                    >
                                        {aplicantdata.APP_EMP_ID}
                                    </TableCell>
                                    <TableCell
                                        align="center" // Center the content horizontally
                                        style={{
                                            fontSize: "0.8rem",
                                            padding: "1px",
                                            border: "1px solid gray",
                                        }}
                                    >
                                        {aplicantdata.USR_DISPLAY_NAME}
                                    </TableCell>
                                    <TableCell
                                        align="center" // Center the content horizontally
                                        style={{
                                            fontSize: "0.8rem",
                                            padding: "1px 18px",
                                            border: "1px solid gray",
                                        }}
                                    >
                                        {formatDate(aplicantdata.APP_DT)}
                                    </TableCell>
                                    <TableCell
                                        align="center" // Center the content horizontally
                                        style={{
                                            fontSize: "0.8rem",
                                            padding: "1px",
                                            border: "1px solid gray",
                                        }}
                                    >
                                        {aplicantdata.disp_nm}
                                    </TableCell>
                                    <TableCell
                                        align="center" // Center the content horizontally
                                        style={{
                                            fontSize: "0.8rem",
                                            padding: "1px 18px",
                                            border: "1px solid gray",
                                        }}
                                    >
                                        {aplicantdata.Less_Than_30_Days} {/* Format leave to date */}
                                    </TableCell>
                                    <TableCell
                                        align="center" // Center the content horizontally
                                        style={{
                                            fontSize: "0.8rem",
                                            padding: "1px",
                                            border: "1px solid gray",
                                        }}
                                    >
                                        {aplicantdata.Days_31_To_60}
                                    </TableCell>
                                    <TableCell
                                        align="center" // Center the content horizontally
                                        style={{
                                            fontSize: "0.8rem",
                                            padding: "1px",
                                            border: "1px solid gray",
                                        }}
                                    >
                                        {aplicantdata.Days_61_To_90}
                                    </TableCell>
                                    <TableCell
                                        align="center" // Center the content horizontally
                                        style={{
                                            fontSize: "0.8rem",
                                            padding: "1px",
                                            border: "1px solid gray",
                                        }}
                                    >
                                        {aplicantdata.More_Than_90_Days}
                                    </TableCell>


                                </TableRow>
                            ))}
                            {agingrpt.agine_rpt_mng_res.length < 10 &&
                                Array.from({ length: 10 - agingrpt.agine_rpt_mng_res.length }).map((_, index) => (
                                    <TableRow key={`empty-row-manager-${index}`}>
                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>

                                    </TableRow>
                                ))}
                            <TableRow style={{ border: "2px solid black", fontWeight: "bold" }}>
                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>&nbsp;</TableCell>
                                <TableCell align="center" style={{ fontSize: "16px", padding: "1px", border: "1px solid gray", fontWeight: "bold" }}>Total</TableCell>
                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>&nbsp;</TableCell>
                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>&nbsp;</TableCell>
                                <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>&nbsp;</TableCell>
                                <TableCell align="center" style={{ fontSize: "16px", padding: "1px", border: "1px solid gray", fontWeight: "bold" }}>{totals.Less_Than_30_Days}</TableCell>
                                <TableCell align="center" style={{ fontSize: "16px", padding: "1px", border: "1px solid gray", fontWeight: "bold" }}>{totals.Days_31_To_60}</TableCell>
                                <TableCell align="center" style={{ fontSize: "16px", padding: "1px", border: "1px solid gray", fontWeight: "bold" }}>{totals.Days_61_To_90}</TableCell>
                                <TableCell align="center" style={{ fontSize: "16px", padding: "1px", border: "1px solid gray", fontWeight: "bold" }}>{totals.More_Than_90_Days}</TableCell>
                            </TableRow>
                        </TableBody>

                    </Table>
                </TableContainer>
            </Grid>
        </div>
    )
}

import React from 'react';
import im1 from '../images/image1.jpg';
import client1 from '../images/client1.png';
import client2 from '../images/client2.png';
import client3 from '../images/client3.png';
import client4 from '../images/client4.png';
import slide1 from '../images/slide1.jpg';
import slide2 from '../images/slide2.jpg';
import slide3 from '../images/slide2.jpg';



export default function Home() {
  return (
  <>
<div id="content">
  <div id="slider">
    <div class="container_12">
      <div class="grid_12">
        <div class="camera_wrap camera_azure_skin" id="camera_wrap_1">
          <div data-src={slide1}>
            <div class="camera_caption fadeIn">
              <h2>Our Recent Projects</h2>
              Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua.
              <p><a href="#" class="button">More Info</a></p>
            </div>
          </div>
          <div data-src={slide2}>
            <div class="camera_caption fadeIn">
              <h2>biz.Power</h2>
              Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat uis aute irure dolor reprehender.
              <p><a href="#" class="button">More Info</a></p>
            </div>
          </div>
          <div data-src={slide3}>
            <div class="camera_caption fadeIn">
              <h2>Development</h2>
              Voluptate velit esse cillum dolore eu fugiat nulla pariatur. Excepteur sint occaecat cupidatat roident, sunt in culpa qui officia.
              <p><a href="#" class="button">More Info</a></p>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <div class="inner">
    <div class="container_12">
      <div class="wrapper">
        <div class="grid_12">
          <div class="block">
            <div class="info-block"> <a href="#" class="link">Click here</a> for more info about this free website template created by Template Monster. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor. </div>
            <a href="#" class="button">Click here!</a> </div>
        </div>
      </div>
      <div class="wrapper">
        <div class="block">
          <div class="grid_8">
            <div class="grid-inner">
              <h2>Hello There!</h2>
              <div class="wrapper">
                <figure class="img-indent"><img src={im1} alt=""/></figure>
                Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua. Ut enim ad minim veniam, quis nostrud exercitation ullamco laboris nisi ut aliquip ex ea commodo consequat. Duis aute irure dolor in reprehenderit in voluptate velit esse cillum dolore eu fugiat nulla pariatur. </div>
              <p class="pad">At vero eos et accusamus et iusto odio dignissimos ducimus qui blanditiis praesentium voluptatum deleniti atque corrupti quos dolores et quas molestias excepturi sint occaecati cupiditate non provident, similique sunt in culpa.</p>
            </div>
          </div>
          <div class="grid_4">
            <h2>Testimonials</h2>
            <div class="testimonial-block"> <em>Lorem ipsum dolor sit amet, consectetur adipisicing elit, sed do eiusmod tempor incididunt ut labore et dolore magna aliqua enim ad minim veniam, quis nostrud.</em>
              <p><strong>— Patrick Pool, Romonson</strong></p>
            </div>
          </div>
        </div>
      </div>
      <div class="wrapper">
        <div class="grid_12">
          <h2 class="h-pad">Our Clients</h2>
          <ul class="clients-list">
            <li><a href="#"><img src={client1} alt=""/></a></li>
            <li><a href="#"><img src={client2} alt=""/></a></li>
            <li><a href="#"><img src={client3} alt=""/></a></li>
            <li><a href="#"><img src={client4} alt=""/></a></li>
          </ul>
        </div>
      </div>
    </div>
  </div>
</div>/
  </>
  )
}

import React, { useState, useRef } from 'react';
import axios from 'axios';
import services from "./apiServices";

export default function Adm_Lve_Policy() {
    const [selectedOption, setSelectedOption] = useState('');
    const [textareaValue, setTextareaValue] = useState({
        lve_policy: ""  // Make sure we initialize lve_policy as an empty string
    });
    const [file, setFile] = useState(null);
    const fileInputRef = useRef(null); // Create a ref for the file input

    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const handleSave = async (e) => {
        console.log('Saved:', textareaValue);
        try {
            const response = await services.add_lve_policy(textareaValue);
            if (response) {
                console.log(response.data);
                alert("Leave Policy Added Successfully");
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    const handleFileChange = (e) => {
        setFile(e.target.files[0]);
    };

    const handleUploadSubmit = async (e) => {
        e.preventDefault();
        const formData = new FormData();
        formData.append('file', file);

        try {
            const response = await services.upload_file(formData);
            if (response) {
                console.log(response.data);
                alert("Leave Policy Added Successfully");
                setFile(null); // Reset the file state
                if (fileInputRef.current) {
                    fileInputRef.current.value = ''; // Clear the input value
                }
            }
        } catch (error) {
            console.error('Error uploading file:', error);
        }
    };

    // Ensure that the textarea value is updated correctly for lve_policy field
    const handleTextareaChange = (e) => {
        const { value } = e.target;
        setTextareaValue({
            ...textareaValue,
            lve_policy: value, // Update the lve_policy field specifically
        });
    };

    return (
        <div className='wrapper_form'>
            <div className="title" style={{ marginTop: ".3%" }}>Leave Policy</div>

            <div className="radio-row" style={{ marginBottom: "5px" }}>
                <label>
                    <input
                        type="radio"
                        name="addOption"
                        value="addFile"
                        checked={selectedOption === "addFile"}
                        onChange={handleOptionChange}
                        style={{ marginRight: "5px", width: "3%" }}
                    />
                    Add Files
                </label>
            </div>
            <hr className="hr1" />
            {selectedOption === "addFile" && (
                <div className="radio-content">
                    <form className="pdm" onSubmit={handleUploadSubmit}>
                        <input
                            type="file"
                            accept="*/*" // Allow all file formats
                            onChange={handleFileChange}
                            ref={fileInputRef} // Attach the ref to the input
                        />
                        <button type="submit" className="btn-action action-button pd2">
                            Upload File
                        </button>
                    </form>
                </div>
            )}
            <div className="radio-row" style={{ marginBottom: "5px" }}>
                <label>
                    <input
                        type="radio"
                        name="addOption"
                        value="addManually"
                        checked={selectedOption === "addManually"}
                        onChange={handleOptionChange}
                        style={{ marginRight: "5px", width: "3%" }}
                    />
                    Add Manually
                </label>
            </div>

            {selectedOption === "addManually" && (
                <div className="manual-entry ">
                    <form className="frm_wd" onSubmit={(e) => { e.preventDefault(); handleSave(); }}>
                        <textarea
                            value={textareaValue.lve_policy} // Bind to lve_policy in the state
                            onChange={handleTextareaChange} // Call handleTextareaChange on user input
                            placeholder="Enter your policy details here..."
                            rows="10"
                            style={{ width: "100%", marginTop: "10px" }}
                            name='lve_policy'
                        />
                        <button
                            className="btn-action action-button"
                            style={{
                                marginTop: "10px",
                                padding: "5px 10px",
                                backgroundColor: "blue",
                                color: "white",
                                border: "none",
                                cursor: "pointer",
                                width: "10%",
                                float: "right"
                            }}
                            type="submit"
                        >
                            Save
                        </button>
                    </form>
                </div>
            )}
        </div>
    );
}

import React, { useState, useEffect } from "react";
import services from "./apiServices";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
} from "@mui/material";

export default function Cust_Rep_Lve_Bal_Consume() {

  const [leavecnt, Setleavecnt] = useState({
    login_lve_bal_cnt: [], lve_apl_dt: [], login_team_membr_inf_res: [],
    shw_upcom_hlod_res: [], empwise_lve_bal_res: [], all_usr_cnt_data: [], aflt_annocement_news_result: []
  })

  useEffect(() => {
    const lev_bal_cnt = async () => {
      try {
        const lve_cnt = await services.get_lev_bal_cnt();

        if (lve_cnt) {
          Setleavecnt(lve_cnt); // Wrap it in an object if necessary
        } else {
          console.error("Unexpected data format:", lve_cnt);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    lev_bal_cnt();

  }, [])

  console.log("leavecnt", leavecnt)

  return (
    <div className="wrapper_form">
      <Grid item xs={6} style={{ paddingLeft: "1%", paddingRight: "1%" }}>
        <div className="title" style={{ marginTop: "2%" }}>Leave Balance & Consumption Details</div>
        <TableContainer
          component={Paper}
          style={{
            margin: "5px auto 25px auto",
            // maxWidth: "50%",
            overflow: "hidden", // Prevents content overflow
            boxShadow: "0px 6px 16px 3px #bababa", // Shadow only on the top and bottom
            borderRadius: "20px", // Rounded corners for the container
            border: "2px solid gray",

          }}
        >
          <Table style={{ width: "100%", }}>
            <TableHead>
              <TableRow style={{ width: "100%", }}>
                <TableCell
                  style={{
                    fontSize: "0.8rem",
                    padding: "1px 18px",
                    backgroundColor: "#4a6d8c",
                    border: "1px solid gray", color: "white", textAlign: "center"
                  }}
                  className=" txt_ttl1"
                >
                  Leave Type
                </TableCell>
                <TableCell
                  style={{
                    fontSize: "0.8rem",
                    padding: "1px 18px",
                    backgroundColor: "#4a6d8c",
                    border: "1px solid gray", color: "white", textAlign: "center"
                  }}
                  className=" txt_ttl1"
                >
                  Op. Bal.
                </TableCell>
                <TableCell
                  style={{
                    fontSize: "0.8rem",
                    padding: "1px",
                    backgroundColor: "#4a6d8c",
                    border: "1px solid gray", color: "white", textAlign: "center"
                  }}
                  className=" txt_ttl1"
                >
                  Consume
                </TableCell>
                <TableCell
                  style={{
                    fontSize: "0.8rem",
                    padding: "1px",
                    backgroundColor: "#4a6d8c",
                    border: "1px solid gray", color: "white", textAlign: "center"
                  }}
                  className=" txt_ttl1"
                >
                  Balance
                </TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {leavecnt.login_lve_bal_cnt.slice(0, 10).map((leave) => (
                <TableRow style={{ height: "10px" }} key={leave.leaveTypeId}>
                  <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                    {leave.disp_nm}
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                    {leave.LVE_TYP_OP_BAL_CNT}
                  </TableCell>

                  <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                    {leave.LVE_TYP_CNMSN_CNT}
                  </TableCell>
                  <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                    {leave.LVE_TYP_RN_BAL_CNT}
                  </TableCell>
                </TableRow>
              ))}
              {/* Add empty rows if there are less than 10 rows */}
              {leavecnt.login_lve_bal_cnt.length < 10 &&
                Array.from({ length: 10 - leavecnt.login_lve_bal_cnt.length }).map((_, index) => (
                  <TableRow style={{ height: "10px" }} key={`empty-row-${index}`}>
                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray", lineHeight: ".2" }}>
                      &nbsp; {/* Empty cell for spacing */}
                    </TableCell>

                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                      &nbsp; {/* Empty cell for spacing */}
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                      &nbsp; {/* Empty cell for spacing */}
                    </TableCell>
                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                      &nbsp; {/* Empty cell for spacing */}
                    </TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </div>
  )
}

import axios from "axios";

const services = {
  getlvetypdata: async () => {
    try {
      const res = await axios.get("/rdbs/register");
      return res.data;
    } catch (error) {
      throw new Error(`Failed  to receive data ${error}`);
    }
  },
  getaffilatedata: async () => {
    console.log("i am in get_affliate_data api....");
    try {
      const res = await axios.get("/rdbs/login/get_affliate_data");
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`);
    }
  },

  get_lev_bal_cnt: async () => {
    console.log("hello from get_lev_bal_cnt Api")
    try {
      const res = await axios.get("rdbs/dash_cust/dash_fetch_data");
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`);
    }
  },
  get_emp_lve_on_today: async () => {
    console.log("hello from get_emp_lve_on_today Api")
    try {
      const res = await axios.get("rdbs/dash_cust/emp_lve_on_today");
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`);
    }
  },

  getAffiliateLeaveTypes: async () => {
    try {
      console.log("hello from api");
      const res = await axios.get(
        "/rdbs/cust_application_form/get_aff_lve_typ"
      );
      return res.data;
    } catch (error) {
      throw new Error(`Failed  to receive data ${error}`);
    }
  },

  getdropdowndata: async (name) => {
    console.log(name);
    try {
      const res = await axios.post("/rdbs/register", { name });
      return res.data;
    } catch (error) {
      throw new Error(`Failed  to receive data ${error}`);
    }
  },
  getLeaveStatuses: async () => {
    console.log("hello from getLeaveStatuses");
    try {
      const res = await axios.post("/rdbs/cust_application_form/getlevsts");
      return res.data;
    } catch (error) {
      throw new Error(`Failed  to receive data ${error}`);
    }
  },

  getapplicantdata: async () => {
    console.log("hello from getapplicantdata ");
    try {
      const res = await axios.get("/rdbs/cust_application_form/applicantdata");
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`);
    }
  },

  insert_reg_tab1: async (values) => {
    console.log(values);
    try {
      const res = await axios.post("/rdbs/register/insert_first_tab", values);
      return res.data;
    } catch (error) {
      throw new Error(`Failed  to receive data ${error}`);
    }
  },
  insert_reg_tab2: async (values) => {
    console.log(values);
    try {
      const res = await axios.post("/rdbs/register/insert_second_tab", values);
      return res.data;
    } catch (error) {
      throw new Error(`Failed  to receive data ${error}`);
    }
  },
  insert_reg_tab3: async (values) => {
    console.log("val", values);
    try {
      const res = await axios.post("/rdbs/register/insert_third_tab", values);
      return res.data;
    } catch (error) {
      throw new Error(`Failed  to receive data ${error}`);
    }
  },
  insert_reg_tab4: async (values) => {
    console.log("val", values);
    try {
      const res = await axios.post("/rdbs/register/insert_fourth_tab", values);
      console.log("hello from try");
      return res.data;
    } catch (error) {
      throw new Error(`Failed  to receive data ${error}`);
    }
  },
  usr_login: async (values) => {
    try {
      const res = await axios.post("/rdbs/login/usr_login", values);
      return res.data;
    } catch (error) {
      console.error("Service error caught:", error); // Log any error from the service
      throw error; // Re-throw the error to propagate it to the calling function
    }
  },
  reg_adm_addusr_afflit: async (values) => {
    console.log(values);
    try {
      const res = await axios.post(
        "/rdbs/register/insrt_adm_addusr_afflit",
        values
      );
      return res.data;
    } catch (error) {
      throw new Error(`Failed  to receive data ${error}`);
    }
  },
  sbmit_lve_app: async (values) => {
    console.log("in am from sbmit_lve_app api");
    // console.log(values);
    try {
      const res = await axios.post(
        "rdbs/cust_application_form/sbmit_lve_app_form",
        values
      );
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`);
    }
  },
  send_mail: async (values) => {
    console.log("in am from send_mail api", values);
    try {
      const res = await axios.post(
        "rdbs/cust_application_form/send_mail",
        values
      );
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`);
    }
  },
  approveLeaveApplication: async (values) => {
    console.log("in am from approveLeaveApplication api", values);
    try {
      const res = await axios.post(
        "rdbs/cust_application_form/updtapplsts",
        values
      );
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`);
    }
  },
  rejectLeaveApplication: async (values) => {
    console.log("in am from rejectLeaveApplication api", values);
    try {
      const res = await axios.post(
        "rdbs/cust_application_form/updt_app_lve_rej",
        values
      );
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`);
    }
  },
  getselecempdetails: async (values) => {
    console.log("Hello from getselecempdetails", values);
    try {
      const res = await axios.post(
        "rdbs/cust_application_form/getseleentity",
        values
      );
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`);
    }
  },
  updateLeaveApplication: async (values) => {
    console.log("Hello from updateLeaveApplication", values);
    try {
      const res = await axios.post(
        "rdbs/cust_application_form/updt_canc_lve",
        values
      );
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`);
    }
  },
  cncl_for_apprval: async (values) => {
    console.log("Hello from cncl_for_apprval API", values)
    try {
      const res = await axios.post("rdbs/cust_application_form/cncl_aprvl", values);
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`);
    }
  },
  edit_lve_Application: async (values) => {
    console.log("hello from edit_lve_Application Api", values)
    try {
      const res = await axios.post("rdbs/cust_application_form/edit_lve_Apl", values);
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`);
    }
  },
  generatePdf: async (aplicantdata) => {
    console.log(`In generatePdf API with data: ${JSON.stringify(aplicantdata)}`);
    try {
      // Make a POST request with the entire `orderData`
      const response = await axios.post('rdbs/dash_cust/generate-pdf', aplicantdata, {
        responseType: 'blob'
      });
      return response;
    } catch (error) {
      console.error('Error downloading the invoice:', error);
      throw error;
    }
  },
  get_aging_dt_mn: async () => {
    console.log("Hello from get_aging_dt_mn apl")
    try {
      const res = await axios.get("rdbs/reports/agine_rpt_mng")
      return res.data

    } catch (error) {
      throw new Error(`Failed to receive data ${error}`);
    }
  },
  get_adm_agine_rpt: async () => {
    console.log("hello from get_adm_agine_rpt api")
    try {
      const res = await axios.get("rdbs/reports/adm_agine_rpt");
      return res.data
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  updated_prf_data: async (values) => {
    console.log("hello from the updated_prf_data api", values)
    try {
      const res = await axios.post("rdbs/reports/updt_prf_dt", values)
      return res.data
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  changepassword: async (values) => {
    console.log("Hello from the changepassword api", values);
    try {
      const res = await axios.post("rdbs/login/chng_pass", values)
      return res.data
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  fechdata_forgot_pass: async (values) => {
    console.log("Hello from the fechdata_forgot_pass api", values);
    try {
      const res = await axios.post("rdbs/login/fechdata_forgot_pass", values)
      return res.data
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  forgetpassword: async (values) => {
    console.log("Hello from the forgetpassword api", values);
    try {
      const res = await axios.post("rdbs/login/forget_password", values)
      return res.data
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  get_lev_holiday_cal: async () => {
    try {
      const res = await axios.get("rdbs/dash_cust/lev_holiday_cal")
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  add_lev_holiday_cal: async (values) => {
    console.log("Hello from the add_lev_holiday_cal api", values);
    try {
      const res = await axios.post("rdbs/dash_cust/add_holiday_cal_dt", values)
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  upload_file: async (values) => {
    console.log("Hello from the upload_file api", values);
    try {
      const res = await axios.post("rdbs/lve_policy/upload_lve_policy", values)
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  get_lve_policy_details: async () => {
    console.log("Hello from the get_lve_policy_details api");
    try {
      const res = await axios.get("rdbs/lve_policy/lve_policy_details")
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },
  add_lve_policy: async (VALUES) => {
    console.log("Hello from the add_lve_policy api", VALUES);
    try {
      const res = await axios.post("rdbs/lve_policy/add_manualy_lve_policy", VALUES)
      return res.data;
    } catch (error) {
      throw new Error(`Failed to receive data ${error}`)
    }
  },

};

export default services;

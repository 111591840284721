import React, { useState } from "react";
import services from "./apiServices";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    TextField,
    Grid,
} from "@mui/material";
import * as XLSX from "xlsx";
import moment from 'moment';

function Adm_Add_Holiday() {
    const [holidayDate, setHolidayDate] = useState('');
    const [holidayType, setHolidayType] = useState('');
    const [isDisplay, setIsDisplay] = useState('yes');
    const [holidayDescription, setHolidayDescription] = useState('');
    const [holidays, setHolidays] = useState([]);
    const [selectedOption, setSelectedOption] = useState('');
    const [error, setError] = useState(null);
    const [fileData, setFileData] = useState(null);

    const handleAddHoliday = () => {
        const newHoliday = {
            date: holidayDate,
            type: holidayType,
            display: isDisplay,
            description: holidayDescription,
        };
        setHolidays([...holidays, newHoliday]);
        // Clear input fields
        setHolidayDate('');
        setHolidayType('');
        setIsDisplay('yes');
        setHolidayDescription('');
    };
    const handleOptionChange = (e) => {
        setSelectedOption(e.target.value);
    };

    const handle_excel_FileUpload = (event) => {
        const file = event.target.files[0];
        const reader = new FileReader();

        reader.onload = (e) => {
            const data = new Uint8Array(e.target.result);
            const workbook = XLSX.read(data, { type: "array" });
            const sheetName = workbook.SheetNames[0];
            const sheet = workbook.Sheets[sheetName];
            const jsonData = XLSX.utils.sheet_to_json(sheet, { header: 1 }); // Extract rows as arrays without headers

            // Map the columns based on their index and filter out empty rows
            const mappedData = jsonData
                .slice(1) // Skip the header row
                .filter((row) =>
                    row.some((cell) => cell !== undefined && cell !== null && cell !== "")
                ) // Filter out empty rows
                .map((row) => ({
                    col1: row[0] ?? null, // Use ?? null to set null if value is undefined
                    col2: row[1] ?? null,
                    col3: row[2] ?? null,
                    col4: row[3] ?? null,
                    col5: row[4] ?? null,
                }));

            setFileData(mappedData); // Store the filtered and mapped data
        };

        if (file) {
            reader.readAsArrayBuffer(file);
        }
    };
    console.log("filedata is ..", fileData)
    const handle_upload_excel_submit = async (event) => {
        event.preventDefault();
        if (!fileData) {
            alert("Please upload a file.");
            return;
        }

        try {
            const response = await fetch(
                "/rdbs/dash_cust/add_holiday_excel",
                {
                    method: "POST",
                    headers: {
                        "Content-Type": "application/json",
                    },
                    body: JSON.stringify(fileData),
                }
            );
            if (response.ok) {
                alert("Data uploaded successfully!");
                console.log("Error:", error);
            } else {
                alert("Error uploading data.");
            }
        } catch (error) {
            console.error("Error:", error);
        }
    };
    const handleSubmitAllHolidays = async () => {
        try {
            const add_holiday_dt = await services.add_lev_holiday_cal(holidays);
            if (add_holiday_dt) {
                alert("Holidays Added Successfully...");
                // Clear the holidays state to clear the table
                setHolidays([]); // This will clear the table data
            } else {
                alert("Failed to Add Holidays ...");
                console.error("Unexpected data format:", add_holiday_dt);
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const formatDate = (dateString) => {
        return moment(dateString).format('DD/MMM/YYYY').replace(/\/([a-z])/g, (match) => '/' + match[1].toUpperCase());
    };

    return (
        <div className="wrapper_form" >
            <div className="title" style={{ marginBottom: "1.4%" }}>Holiday Management</div>

            {/* Radio Buttons for Selection */}

            <div className="radio-row" style={{ marginBottom: "5px" }}>
                <label>
                    <input
                        type="radio"
                        name="addOption"
                        value="uploadExcel"
                        checked={selectedOption === "uploadExcel"}
                        onChange={handleOptionChange}
                        style={{ width: "2%" }}
                    />
                    Add .CSV File
                    <hr className="hr1" />
                </label>
                {selectedOption === "uploadExcel" && (
                    <div className="radio-content">
                        <form className="pdm" onSubmit={handle_upload_excel_submit}>
                            <input
                                type="file"
                                accept=".xlsx, .xls, .csv"
                                onChange={handle_excel_FileUpload}
                            />
                            <button type="submit" className="btn-action action-button pd2">
                                Upload File
                            </button>
                        </form>
                    </div>
                )}
            </div>

            <div className="radio-row" style={{ marginBottom: "5px" }}>
                <label>
                    <input
                        type="radio"
                        name="addOption"
                        value="addManually"
                        checked={selectedOption === "addManually"}
                        onChange={handleOptionChange}
                        style={{ width: "2%" }}
                    />
                    Add Manually

                </label>
                {selectedOption === "addManually" && (
                    <>
                        <form className="frm_wd">
                            <hr className="hr_ttl" />
                            <p
                                style={{
                                    fontSize: "25px",
                                    paddingTop: "5px",
                                    color: "black",
                                }}
                            >
                                Holiday information
                            </p>
                            <hr className="hr_dt" />
                            <div className="row">
                                <div className="column drp_ln_hg">
                                    <label className="drp_mg" style={{ marginLeft: "5px" }}>Holiday Date:</label>
                                    <div className="field">
                                        <input
                                            type="date"
                                            value={holidayDate}
                                            onChange={(e) => setHolidayDate(e.target.value)}
                                            style={{ marginBottom: '10px', lineHeight: "25px", height: "25px", padding: "2px", borderRadius: "25px" }}
                                        />
                                    </div>
                                </div>
                                <div className="column drp_ln_hg">
                                    <label className="drp_mg" style={{ marginLeft: "1%" }}>Holiday Type:</label>
                                    <div className="field">
                                        <input
                                            type="text"
                                            value={holidayType}
                                            onChange={(e) => setHolidayType(e.target.value)}
                                            style={{ marginBottom: '10px', lineHeight: "25px", height: "25px", borderRadius: "25px" }}
                                            placeholder="Enter Holiday Type"
                                        />
                                    </div>
                                </div>
                                <div className="column" style={{ lineHeight: "12px", marginTop: "5px" }}>
                                    <label style={{ marginLeft: "0%" }}>Display On Screen?</label>
                                    <div style={{ marginLeft: "0%" }}>
                                        <FormControl>
                                            <RadioGroup row>
                                                <FormControlLabel
                                                    value="Y"
                                                    control={<Radio />}
                                                    label="Yes"
                                                    checked={isDisplay === 'Y'}
                                                    onChange={() => setIsDisplay('Y')}
                                                />
                                                <FormControlLabel
                                                    value="N"
                                                    control={<Radio />}
                                                    label="No"
                                                    checked={isDisplay === 'N'}
                                                    onChange={() => setIsDisplay('N')}
                                                />
                                            </RadioGroup>
                                        </FormControl>
                                    </div>
                                </div>
                            </div>
                            <div className="row">
                                <div className="column drp_ln_hg">
                                    <label className="drp_mg" style={{ marginLeft: "1%" }}>
                                        Holiday Description:
                                        <textarea
                                            value={holidayDescription}
                                            placeholder="Enter Holiday Description"
                                            onChange={(e) => setHolidayDescription(e.target.value)}
                                            style={{ marginBottom: '10px', width: '100%', height: '50px', borderRadius: "15px" }}
                                        />
                                    </label>
                                </div>
                            </div>
                            <hr className="hr_dt" />
                            <Button className="btn-action action-button" style={{ lineHeight: "30px", height: "30px", marginLeft: "5%", width: "10%", fontSize: "bold", textAlign: "right", float: "right", marginBottom: ".5%" }} onClick={handleAddHoliday}>
                                Add Holiday
                            </Button>

                            <hr className="hr_dt" />

                            <Grid item xs={12}>
                                <TableContainer
                                    component={Paper}
                                    style={{
                                        margin: "5px auto 25px auto",
                                        overflow: "hidden",
                                        boxShadow: "0px 6px 16px 3px #bababa",
                                        borderRadius: "20px",
                                        border: "2px solid gray",
                                    }}
                                >
                                    <Table style={{ width: "100%" }}>
                                        <TableHead>
                                            <TableRow style={{ width: "100%" }}>
                                                <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>
                                                    Sr.No.
                                                </TableCell>
                                                <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>
                                                    Date
                                                </TableCell>
                                                <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>
                                                    Holiday Type
                                                </TableCell>
                                                <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>
                                                    Display
                                                </TableCell>
                                                <TableCell style={{ fontSize: "0.8rem", padding: "1px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>
                                                    Description
                                                </TableCell>
                                            </TableRow>
                                        </TableHead>
                                        <TableBody>
                                            {holidays.map((leave, index) => (
                                                <TableRow style={{ height: "10px" }} key={index}>
                                                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                        {index + 1}
                                                    </TableCell>
                                                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                        {formatDate(leave.date)}
                                                    </TableCell>
                                                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                        {leave.type}
                                                    </TableCell>
                                                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                                        {leave.display}
                                                    </TableCell>
                                                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                                        {leave.description}
                                                    </TableCell>
                                                </TableRow>
                                            ))}
                                            {/* Add empty rows if there are less than 3 rows */}
                                            {holidays.length < 3 &&
                                                Array.from({ length: 3 - holidays.length }).map((_, index) => (
                                                    <TableRow style={{ height: "10px" }} key={`empty-row-${index}`}>
                                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                            &nbsp; {/* Empty cell for spacing */}
                                                        </TableCell>
                                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                            &nbsp; {/* Empty cell for spacing */}
                                                        </TableCell>
                                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                                            &nbsp; {/* Empty cell for spacing */}
                                                        </TableCell>
                                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                                            &nbsp; {/* Empty cell for spacing */}
                                                        </TableCell>
                                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                                            &nbsp; {/* Empty cell for spacing */}
                                                        </TableCell>
                                                    </TableRow>
                                                ))}
                                        </TableBody>
                                    </Table>
                                </TableContainer>
                            </Grid>

                            {/* Add All Holidays Button */}
                            {holidays.length > 0 && (
                                <div>
                                    <hr className="hr_dt" />
                                    <Button
                                        className="btn-action action-button"
                                        style={{
                                            lineHeight: "30px",
                                            height: "30px",
                                            marginTop: ".3%",
                                            marginLeft: "5%",
                                            width: "10%",
                                            fontSize: "bold",
                                            textAlign: "right",
                                            float: "right",
                                            marginBottom: ".5%"
                                        }}
                                        onClick={handleSubmitAllHolidays}
                                    >
                                        Add Holidays
                                    </Button>
                                    <hr className="hr_dt" />
                                </div>
                            )}
                        </form>
                    </>
                )}
            </div>
        </div>
    );
}

export default Adm_Add_Holiday;
import React, { useState, useEffect } from "react";
import services from "./apiServices";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from '@mui/icons-material/Cancel';
import moment from 'moment';
import {
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Button,
  TextField,
  Grid,
  Typography,
} from "@mui/material";
export default function Emp_Leave_Application() {
  const storedUserData = sessionStorage.getItem("user_data");
  let usr_log = null;
  if (storedUserData) {
    usr_log = JSON.parse(storedUserData); // Convert the JSON string back into an object/array
    // console.log(usr_log); // Now you have access to the original usr_log object
  }
  console.log(usr_log);

  const [usrdata, setUsrdata] = useState({
    usrtype: [],
    usrcat: [],
    usrtitle: [],
    Desgn: [],
    lve_status: [],
  });
  console.log(usrdata);
  const [gender, setgender] = useState();
  const [data, setData] = useState({
    result: [],
    resul1: [],
    result2: [],
    result3: [],
    result4: [],
  });

  const [formData, setFormData] = useState({
    leaveType: "",
    leaveBalance: "",
    employeeId: "",
    approvalId: "",
    leaveStatus: "",
    cc_id: "",
    lve_from: "",
    lve_to: "",
    half_day: "", // Set an initial value for radio buttons
    which_half: "", // Initial value
    conjugative_half_day: "", // Initial value
    half_day_from: "",
    daily_half_day: "", // Initial value
    half_day_to: "",
    leaveReason: "", // For Leave Reason textarea
    address: "", // For Employee Address
    mobileno: "", // For Employee Phone
    cellphno: "", // For Employee CellPhone
    usrmail: "",
    ccmail: "",
    approvalemail: "",
    leaveDuration: ""
  });
  const [editdata, setEditdata] = useState({
    leaveType: "",
    leaveBalance: "",
    employeeId: "",
    approvalId: "",
    leaveStatus: "",
    cc_id: "",
    lve_from: "",
    lve_to: "",
    half_day: "", // Set an initial value for radio buttons
    which_half: "", // Initial value
    conjugative_half_day: "", // Initial value
    half_day_from: "",
    daily_half_day: "", // Initial value
    half_day_to: "",
    leaveReason: "", // For Leave Reason textarea
    address: "", // For Employee Address
    mobileno: "", // For Employee Phone
    cellphno: "", // For Employee CellPhone
    usrmail: "",
    ccmail: "",
    approvalemail: "",
    leaveDuration: "",
    applicationDate: ""
  });
  const [afltdata, setAffdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [firstcomplete, setfirstcomplete] = useState(false);
  const [errors, setErrors] = useState({});

  // const [isEditable, setIsEditable] = useState(false);
  const [originalMobileno] = useState(usr_log[0].USR_CELL_PH || "");
  const [originalCellphno] = useState(usr_log[0].USR_CELL_PH2 || "");
  const [originalAddress] = useState(
    [
      usr_log[0].ADD_LN1,
      usr_log[0].ADD_LN2,
      usr_log[0].ADD_LN3,
      usr_log[0].ADD_CT,
      usr_log[0].ADD_PIN_ZIP,
    ]
      .filter(Boolean)
      .join(", ") || ""
  );
  const [mobileno, setMobileno] = useState(originalMobileno);
  const [cellphno, setCellphno] = useState(originalCellphno);
  const [address, setAddress] = useState(originalAddress);

  const [leaveDuration, setLeaveDuration] = useState(0); // State for leave duration
  const [formVisible, setFormVisible] = useState(false);
  const [formVisible1, setFormVisible1] = useState(false);
  const [formVisible2, setFormVisible2] = useState(false);
  const [selectedRecord, setSelectedRecord] = useState(null);
  const [selectedRecord1, setSelectedRecord1] = useState(null);
  const [editMode, setEditMode] = useState(false);
  const [isEditMode, setIsEditMode] = useState(false);
  const [isEditable, setIsEditable] = useState(true); // Initially set to true

  const toggleFormVisibility = () => {
    setFormVisible((prev) => !prev); // Toggle form visibility
  };
  const toggleFormVisibility1 = (resetSelectedRecord = false) => {
    if (resetSelectedRecord) {
      setSelectedRecord(null);
    }
    setFormVisible1((prev) => !prev); // Toggle form visibility
  };
  const toggleFormVisibility2 = (resetSelectedRecord = false) => {
    if (resetSelectedRecord) {
      setSelectedRecord1(null);
      console.log()
    }
    setFormData({
      leaveType: "",
      leaveBalance: "",
      employeeId: "",
      approvalId: "",
      leaveStatus: "",
      cc_id: "",
      lve_from: "",
      lve_to: "",
      half_day: "",
      which_half: "",
      conjugative_half_day: "",
      half_day_from: "",
      daily_half_day: "",
      half_day_to: "",
      leaveReason: "",
      address: "",
      mobileno: "",
      cellphno: "",
      usrmail: "",
      ccmail: "",
      approvalemail: "",
      leaveDuration: ""
    });
    setEditdata({
      leaveType: "",
      leaveBalance: "",
      employeeId: "",
      approvalId: "",
      leaveStatus: "",
      cc_id: "",
      lve_from: "",
      lve_to: "",
      half_day: "",
      which_half: "",
      conjugative_half_day: "",
      half_day_from: "",
      daily_half_day: "",
      half_day_to: "",
      leaveReason: "",
      address: "",
      mobileno: "",
      cellphno: "",
      usrmail: "",
      ccmail: "",
      approvalemail: "",
      leaveDuration: ""
    });
    setSelectedLeaveType(""); // Reset selectedLeaveType state
    setLeaveBalance(null); // Reset leaveBalance state
    setFormVisible2((prev) => !prev); // Toggle form visibility
  };
  const convertISODatetoDDMMYYYY = (isoDate) => {
    if (!isoDate) return "";
    const date = new Date(isoDate);
    const day = date.getDate().toString().padStart(2, '0');
    const month = (date.getMonth() + 1).toString().padStart(2, '0');
    const year = date.getFullYear();
    return `${day}/${month}/${year}`;
  };
  // In your handleEdit1 function
  const handleEdit1 = (empid, lvetyp, appdt, appid) => {
    console.log(`Editing record with empid ${empid}, lvetyp ${lvetyp}, appdt ${appdt}, appid ${appid}`);

    if (!data.result4 || data.result4.length === 0) {
      console.error("Data not available");
      return;
    }

    const recordToEdit = data.result4.find((app) =>
      app.APP_EMP_ID === empid &&
      app.APP_TYP === lvetyp &&
      app.APP_DT === appdt &&
      app.APP_ID === appid
    );

    if (!recordToEdit) {
      console.error(`No record found with id ${empid}`);
      return;
    }

    console.log("Record to edit:", recordToEdit);

    // Set the form data with the selected record's values
    setEditdata({
      leaveType: recordToEdit.APP_TYP,
      leaveBalance: recordToEdit.APP_LVE_BAL,
      employeeId: recordToEdit.APP_EMP_ID,
      approvalId: recordToEdit.APP_APRVL_ID,
      leaveStatus: recordToEdit.APP_LVE_STTS,
      cc_id: recordToEdit.APP_CC_TO_ID,
      lve_from: moment(recordToEdit.APP_LVE_FRM_DT).format('YYYY-MM-DD'), // Format correctly
      lve_to: moment(recordToEdit.APP_LVE_TO_DT).format('YYYY-MM-DD'), // Format correctly
      half_day: recordToEdit.APP_HLF_DE === "Y" ? "yes" : "no",
      which_half: recordToEdit.APP_HLF_1_2 === "1" ? "first" : "second",
      conjugative_half_day: recordToEdit.APP_CNZTV_HLF_DE_YN === "Y" ? "first_day" : recordToEdit.APP_CNZTV_HLF_DE_YN === "N" ? "last_day" : "both_day",
      daily_half_day: recordToEdit.APP_DLY_HLF_DE_YN === "Y" ? "yes" : "no",
      half_day_from: convertISODatetoDDMMYYYY(recordToEdit.APP_HLF_FRM_DT), // Format the half day from date
      half_day_to: convertISODatetoDDMMYYYY(recordToEdit.APP_HLF_TO_DT), // Format the half day to date
      leaveReason: recordToEdit.APP_LVE_RES,
      address: recordToEdit.APP_LVE_ADD,
      mobileno: recordToEdit.APP_PH_NO,
      cellphno: recordToEdit.APP_PH_NO,
      applicationDate: moment(recordToEdit.APP_DT).format('YYYY-MM-DD'), // Set application date in yyyy-mm-dd format
    });

    // Additional logic for setting selected leave type and balance
    setSelectedLeaveType(recordToEdit.APP_TYP);
    const selectedLeave = data.result.find((type) => type.lve_typ_cd === recordToEdit.APP_TYP);
    setLeaveBalance(selectedLeave ? selectedLeave.LVE_TYP_RN_BAL_CNT : null);

    // Set the selected record for editing
    setSelectedRecord1(recordToEdit);
    setIsEditMode(true);
    setIsEditable(recordToEdit.APP_LVE_STTS === "Pending"); // Set isEditable based on leave status

    // Toggle the form visibility
    toggleFormVisibility1();
  };
  useEffect(() => {
    // Check the leave status and set isEditable accordingly
    if (selectedRecord1 && selectedRecord1.APP_LVE_STTS !== "Pending") {
      setIsEditable(false);
    } else {
      setIsEditable(true);
    }

    // Populate form data with selected record values if available
    if (selectedRecord1) {
      setEditdata({
        leaveType: selectedRecord1.APP_TYP,
        leaveBalance: selectedRecord1.APP_LVE_BAL,
        employeeId: selectedRecord1.APP_EMP_ID,
        approvalId: selectedRecord1.APP_APRVL_ID,
        leaveStatus: selectedRecord1.APP_LVE_STTS,
        cc_id: selectedRecord1.APP_CC_TO_ID,
        lve_from: moment(selectedRecord1.APP_LVE_FRM_DT).format('YYYY-MM-DD'), // Format correctly
        lve_to: moment(selectedRecord1.APP_LVE_TO_DT).format('YYYY-MM-DD'), // Format correctly
        half_day: selectedRecord1.APP_HLF_DE === "Y" ? "yes" : "no",
        which_half: selectedRecord1.APP_HLF_1_2 === "1" ? "first" : "second",
        conjugative_half_day: selectedRecord1.APP_CNZTV_HLF_DE_YN === "Y" ? "first_day" : selectedRecord1.APP_CNZTV_HLF_DE_YN === "N" ? "last_day" : "both_day",
        daily_half_day: selectedRecord1.APP_DLY_HLF_DE_YN === "Y" ? "yes" : "no",
        half_day_from: selectedRecord1.APP_HLF_FRM_DT,
        half_day_to: selectedRecord1.APP_HLF_TO_DT,
        leaveDuration: selectedRecord1.APP_LVE_DES,
        leaveReason: selectedRecord1.APP_LVE_RES,
        address: selectedRecord1.APP_LVE_ADD,
        mobileno: selectedRecord1.APP_PH_NO,
        cellphno: selectedRecord1.APP_PH_NO,
        applicationDate: moment(selectedRecord1.APP_DT).format('YYYY-MM-DD'), // Set application date in yyyy-mm-dd format
      });
    }
  }, [selectedRecord1]);

  const handleInputChange = (event) => {
    const { name, value } = event.target;
    setEditdata((prevData) => ({
      ...prevData,
      [name]: value,
      leaveDuration: leaveDuration // Update the leaveDuration property
    }));
  };
  console.log("updated after selecting record data is formdata..", editdata);
  // Add a new function to handle the edit button click
  console.log("isEditable", isEditable)
  const handleDelete = async (empId, appType, appDate, app_id) => {
    // Find the selected application based on employee ID
    const selectedData = data.result4.find((app) => app.APP_EMP_ID === empId);

    if (!selectedData) {
      console.error("Selected data not found");
      return; // Exit early if no selected data is found
    }

    // Find the specific record to edit using employee ID, application type, and application date
    const recordToEdit = data.result4.find((app) =>
      app.APP_EMP_ID === empId &&
      app.APP_TYP === appType &&
      app.APP_DT === appDate &&
      app.APP_ID === app_id
    );

    console.log("recordToEdit", recordToEdit);

    if (!recordToEdit) {
      console.error("Record to edit not found");
      return; // Exit early if no record is found to edit
    }

    // Determine the action based on the leave status
    let updatedApp = null;
    let successMessage = "";

    if (recordToEdit.APP_LVE_STTS === "Pending") {
      updatedApp = { ...recordToEdit, APP_LVE_STTS: "Cancel" };
      successMessage = "Leave application cancelled successfully.";
    } else if (recordToEdit.APP_LVE_STTS === "Cancel for Approval") {
      updatedApp = { ...recordToEdit, APP_LVE_STTS: "Approved" };
      successMessage = "Leave application approved successfully.";
    } else {
      alert("Leave application is not in a cancellable or approvable state.");
      return; // Exit early if the state is not valid
    }

    // Try to update the application status
    try {
      const result = await services.updateLeaveApplication(updatedApp);
      alert(successMessage);
      // Refresh data after the operation
      await refreshData(); // Use the helper function
    } catch (error) {
      console.error(`Error processing application: ${error}`);
      alert(`Failed to process leave application. Please try again.`);
    }
  };
  // Helper function to refresh data
  const refreshData = async () => {
    try {
      const result = await services.getAffiliateLeaveTypes(); // Fetch the data from the service
      if (result) {
        // Check if the result has the expected properties
        setData({
          result: result.result || [],    // Use a fallback of an empty array if not found
          resul1: result.resul1 || [],
          result2: result.result2 || [],
          result3: result.result3 || [],
          result4: result.result4 || [],
        });
      } else {
        console.error("Unexpected data format:", result);
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };

  const handleView = (record) => {
    setSelectedRecord(record);
    setEditMode(false);
    toggleFormVisibility1(); // If this opens the view form/modal
  };
  console.log("selectedRecord", selectedRecord)
  console.log("selectedRecord1", selectedRecord1)

  const calculateLeaveDuration = () => {
    const {
      lve_from,
      lve_to,
      half_day,
      conjugative_half_day,
      daily_half_day,
      half_day_from,
      half_day_to,
    } = isEditMode ? editdata : formData; // Use editdata if in edit mode, otherwise use formData

    let totalDuration = 0;

    // Extract working days from afltdata
    const workingDays = afltdata
      .filter((day) => day.AFLT_CONF_PARA === "WORKING_DAYS")
      .map((day) => day.AFLT_CONF_PARA_VAL.toLowerCase());

    // Calculate full days based on lve_from and lve_to
    if (lve_from && lve_to) {
      const startDate = new Date(lve_from);
      const endDate = new Date(lve_to);

      // Loop through the date range to count valid working days
      let currentDate = new Date(startDate);
      while (currentDate <= endDate) {
        const dayName = currentDate
          .toLocaleString("en-US", { weekday: "long" })
          .toLowerCase();
        if (workingDays.includes(dayName)) {
          totalDuration++;
        }
        currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
      }
    }

    // Handle half-day logic based on daily_half_day or conjugative_half_day
    let halfDayCount = 0;

    if (half_day === "yes") {
      if (daily_half_day === "yes" && half_day_from && half_day_to) {
        // Daily half-day calculation
        const halfStartDate = new Date(half_day_from);
        const halfEndDate = new Date(half_day_to);

        let currentHalfDate = new Date(halfStartDate);
        while (currentHalfDate <= halfEndDate) {
          const dayName = currentHalfDate
            .toLocaleString("en-US", { weekday: "long" })
            .toLowerCase();
          if (workingDays.includes(dayName)) {
            halfDayCount += 0.5; // Add half a day for each valid working day
          }
          currentHalfDate.setDate(currentHalfDate.getDate() + 1);
        }
      } else {
        // Conjugative half-day calculation
        switch (conjugative_half_day) {
          case "first_day":
            if (
              workingDays.includes(
                new Date(lve_from)
                  .toLocaleString("en-US", { weekday: "long" })
                  .toLowerCase()
              )
            ) {
              halfDayCount += 0.5; // Subtract half day from first working day
            }
            break;
          case "last_day":
            if (
              workingDays.includes(
                new Date(lve_to)
                  .toLocaleString("en-US", { weekday: "long" })
                  .toLowerCase()
              )
            ) {
              halfDayCount += 0.5; // Subtract half day from last working day
            }
            break;
          case "both_day":
            if (
              workingDays.includes(
                new Date(lve_from)
                  .toLocaleString("en-US", { weekday: "long" })
                  .toLowerCase()
              )
            ) {
              halfDayCount += 0.5; // Subtract half day from first working day
            }
            if (
              workingDays.includes(
                new Date(lve_to)
                  .toLocaleString("en-US", { weekday: "long" })
                  .toLowerCase()
              )
            ) {
              halfDayCount += 0.5; // Subtract half day from last working day
            }
            break;
          default:
            break;
        }
      }
    }

    // Subtract half days from the total leave duration
    totalDuration -= halfDayCount;

    // Set the total leave duration
    setLeaveDuration(totalDuration);
  };

  // Effect to calculate leave duration when relevant fields change
  useEffect(() => {
    calculateLeaveDuration();
  }, [
    formData.lve_from,
    formData.lve_to,
    formData.half_day,
    formData.conjugative_half_day,
    formData.daily_half_day,
    formData.half_day_from,
    formData.half_day_to,
    afltdata,
    isEditMode, // Add isEditMode as a dependency
    editdata.lve_from, // Add editdata dependencies
    editdata.lve_to,
    editdata.half_day,
    editdata.conjugative_half_day,
    editdata.daily_half_day,
    editdata.half_day_from,
    editdata.half_day_to,
  ]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // If the field being changed is "conjugative_half_day", update "daily_half_day" to "no"
    if (name === "conjugative_half_day") {
      setFormData({
        ...formData,
        [name]: value,
        daily_half_day: "no", // Automatically set to "no" when conjugative_half_day changes
      });
    } else {
      // For all other fields, update normally
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  const updateFormData = () => {
    const newEmployeeId = usr_log[0]?.USR_EMP_ID;
    const newApprovalId = usr_log[0]?.USR_APRVL_ID;
    const newApprovalEmail = data.result2[0]?.USR_EMAIL;

    // Assuming userdata.lve_status[0]?.ATTRIB_NAME holds the current leave status
    const newLeaveStatus = usrdata.lve_status[0]?.ATTRIB_NAME;

    // Only update if values have changed
    if (
      newEmployeeId !== formData.employeeId ||
      newApprovalId !== formData.approvalId ||
      newApprovalEmail !== formData.approvalemail ||
      newLeaveStatus !== formData.leaveStatus // Compare with userdata
    ) {
      setFormData({
        employeeId: newEmployeeId,
        approvalId: newApprovalId,
        approvalemail: newApprovalEmail,
        leaveStatus: newLeaveStatus, // Updating leave status from userdata
      });
    }
  };

  const handleClick = () => {
    setIsEditable(true);
  };

  const handlesetaddress = (event) => {
    const newAddress = event.target.value;
    setAddress(newAddress);
    setFormData((prev) => ({ ...prev, address: newAddress }));
  };

  const handlesetmobileno = (event) => {
    const newMobileno = event.target.value;
    setMobileno(newMobileno);
    setFormData((prev) => ({ ...prev, mobileno: newMobileno }));
  };

  const handlesetcelleno = (event) => {
    const newCellphno = event.target.value;
    setCellphno(newCellphno);
    setFormData((prev) => ({ ...prev, cellphno: newCellphno }));
  };

  const handleBlur = () => {
    setIsEditable(false);
    // Log the updated values
    // console.log("Updated Address:", address);
  };
  useEffect(() => {
    if (usr_log.length > 0 && usrdata.lve_status.length > 0) {
      updateFormData();
    }
  }, [usr_log, usrdata]); // Dependency array only runs when these values change

  const [selectedLeaveType, setSelectedLeaveType] = useState("");
  const [leaveBalance, setLeaveBalance] = useState(null);
  const [ccmail, setccmail] = useState(null); // State to hold approval email

  const handleLeaveTypeChange = (e) => {
    const selectedType = e.target.value;

    // Check if data.result exists and is an array
    const leaveArray = Array.isArray(data.result) ? data.result : [];

    // Find the corresponding leave type object from the data.result array
    const selectedLeave = leaveArray.find(
      (type) => type.lve_typ_cd === selectedType
    );

    setSelectedLeaveType(selectedType);
    setLeaveBalance(selectedLeave ? selectedLeave.LVE_TYP_RN_BAL_CNT : null);

    // Store the selected leave type's LVE_BAL_IN_DBORD
    if (selectedLeave) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        leaveType: selectedType,
        leaveBalance: selectedLeave.LVE_TYP_RN_BAL_CNT,
        leaveInDbord: selectedLeave.LVE_BAL_IN_DBORD // Store the LVE_BAL_IN_DBORD value

      }));

    }
  };

  // console.log(leaveBalance);
  console.log(selectedLeaveType);
  console.log("lve_from", editdata.lve_from)
  // Toggle editable state when input is clicked

  const currentDate = new Date();
  const day = currentDate.getDate().toString().padStart(2, "0");
  const monthShort = currentDate.toLocaleString("default", { month: "short" });
  const year = currentDate.getFullYear();
  const formattedDate = `${day}/${monthShort}/${year}`;

  // console.log(formattedDate);
  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await services.getAffiliateLeaveTypes(); // Fetch the data

        // Check if the result has the expected structure
        if (result) {
          setData({
            result: result.result || [],    // Ensure proper mapping and fallback
            resul1: result.resul1 || [],
            result2: result.result2 || [],
            result3: result.result3 || [],
            result4: result.result4 || [],
          });
        } else {
          console.error("Unexpected data format:", result);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetchData(); // Invoke the fetch function
  }, []); // Empty dependency array to run once on mount


  useEffect(() => {
    const fg = async () => {
      try {
        // Make multiple requests
        const res = await Promise.all([
          services.getdropdowndata("APCBL_GNDR"),
          services.getdropdowndata("User_Type"),
          services.getdropdowndata("User_Category"),
          services.getdropdowndata("User_Title"),
          services.getdropdowndata("APCBL_POST"),
          services.getdropdowndata("LVE_STTS"),
        ]);

        const newUsrdata = {
          usrtype: [],
          usrcat: [],
          usrtitle: [],
          Desgn: [],
          lve_status: [],
        };

        // Process responses based on the name identifier
        res.forEach((response) => {
          const { name, dpresult } = response;

          switch (name) {
            case "APCBL_GNDR":
              setgender(dpresult);
              break;
            case "User_Type":
              newUsrdata.usrtype = dpresult;
              break;
            case "User_Category":
              newUsrdata.usrcat = dpresult;
              break;
            case "User_Title":
              newUsrdata.usrtitle = dpresult;
              break;
            case "APCBL_POST":
              newUsrdata.Desgn = dpresult;
              break;
            case "LVE_STTS":
              newUsrdata.lve_status = dpresult;
              break;
            default:
              break;
          }
        });

        setUsrdata(newUsrdata);
      } catch (err) {
        // Handle errors
        setError(err);
      } finally {
        // Set loading to false when requests are completed
        setLoading(false);
      }
    };

    fg();
  }, []);
  useEffect(() => {
    const fetch = async () => {
      try {
        const affdata = await services.getaffilatedata();
        if (affdata) {
          setAffdata(affdata);
          // console.log("affdata", affdata);
        } else {
          console.log("Error for fetching data:");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetch();
  }, []);
  console.log(data);
  const validateForm = () => {
    const newErrors = {};

    // Check each field for emptiness
    if (!formData.leaveType) newErrors.leaveType = "Leave Type is not selected";
    if (!formData.cc_id) newErrors.cc_id = "CC ID is not selected";
    if (!formData.lve_from)
      newErrors.lve_from = "Leave From date is not selected";
    if (!formData.lve_to) newErrors.lve_to = "Leave To date is not selected";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };
  // Function to get email address by cc_id

  const getEmailByCcId = (cc_id) => {
    console.log("Searching for cc_id:", cc_id); // Log cc_id
    const employee = data.resul1.find((emp) => {
      console.log("Checking employee:", emp); // Log each employee being checked
      return emp.USR_EMP_ID === cc_id;
    });

    if (employee) {
      console.log("Found employee:", employee); // Log the found employee
      return employee.USR_EMAIL; // Return the email if found
    } else {
      console.log("Employee not found"); // Log if not found
      return null;
    }
  };
  console.log(formData);
  // Example usage
  useEffect(() => {
    const emailAddress = getEmailByCcId(formData.cc_id);
    setccmail(emailAddress); // Update state with the email
    console.log(emailAddress); // Log the email address or do something with it
  }, [formData.cc_id, data.resul1]);

  const submit_app = async () => {
    // Update formData with the current state values, including the approval email from state
    const updatedFormData = {
      ...formData,
      address: address,
      mobileno: mobileno,
      cellphno: cellphno,
      usrmail: usr_log[0].ADD_EMAIL,
      ccmail: ccmail, // Use the state variable here
      leaveDuration: leaveDuration
    };
    // Check if leave balance is 0
    if (leaveBalance <= 0) {
      // If balance is 0, check leaveInDbord
      if (formData.leaveInDbord === "Y") {
        alert("Your leave balance for this type of leave is 0. You cannot submit the application.");
        return; // Prevent further execution
      } else if (formData.leaveInDbord === "N") {
        // Allow submission if leaveInDbord is "N"
        console.log("Leave balance is 0, but leaveInDbord is 'N'. Proceeding with submission.");
      }
    }
    console.log("Final data before submission:", updatedFormData);

    if (validateForm()) {
      try {
        // Call the submission service with the updated data
        const insrt_res = await services.sbmit_lve_app(updatedFormData);

        if (insrt_res) {
          setfirstcomplete(true);
          console.log("Leave Application Submitted Successfully....");
          alert("Leave Application Submitted Successfully....");
          // Send the email through the backend API
          const sendmaildata = await services.send_mail(updatedFormData);

          if (sendmaildata.ok) {
            console.log("Email sent successfully.");
          } else {
            console.error("Error sending email:", await sendmaildata.text());
          }
        }
      } catch (error) {
        console.error("Leave Application failed to Submit...", error);
        alert("Failed to Submit Leave Application.. ");
      }
    }
  };

  const req_apl_for_cnl_aprvl = async () => {
    const updatedFormData = {
      ...selectedRecord1,
      APP_LVE_STTS: "Cancel for Approval"
    };
    console.log("Final data before submission for cancel the approval:", updatedFormData);
    try {
      // Call the submission service with the updated data
      const cancel_approval = await services.cncl_for_apprval(updatedFormData);

      if (cancel_approval) {
        setfirstcomplete(true);
        console.log("Leave Application of Cancel for Approval Submitted Successfully ....");
        alert("Leave Application of Cancel for Approval Submitted Successfully ....");
        // Send the email through the backend API
        // const sendmaildata = await services.send_mail(selectedRecord1);

        // if (sendmaildata.ok) {
        //   console.log("Email sent successfully.");
        // } else {
        //   console.error("Error sending email:", await sendmaildata.text());
        // }
      }
    } catch (error) {
      console.error("Leave Application of Cancel for Approval failed to Submit...", error);
      // alert("Failed to Submit Leave Application.. ");
    }
  }
  const edit_apliction = async () => {
    const updateddata = {
      ...editdata,
      leaveDuration: leaveDuration,
      applicationDate: editdata.applicationDate
    }
    console.log("Data for editing Leave Application from edit_apliction button ", updateddata)
    try {
      const edit_aplction = await services.edit_lve_Application(updateddata)

      if (edit_aplction) {
        setfirstcomplete(true);
        console.log(" edited Leave Application Submitted Successfully ....");
        alert(" Leave Application Edited Successfully ....");
      }

    } catch (error) {
      console.error("Edited apllication failed to Submit...", error);
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault();
  };

  // console.log(afltdata);
  // console.log(usrdata);
  // console.log(formData);
  return (
    <div className="wrapper_form">
      <div className="title">Leave Application Form</div>
      <div className="wrapper_form" style={{ border: "1px solid #4a6d8c" }}>
        {formVisible ? (
          <div>
            {/* Your existing form content */}
            <form method="Post" onSubmit={handleSubmit}>
              <div className="form-container">
                <hr className="hr_ttl" />
                <p
                  style={{
                    fontSize: "25px",
                    paddingTop: "5px",
                    color: "black",
                  }}
                >
                  Employee information
                </p>
                <hr className="hr_dt" style={{ marginBottom: "19px" }} />

                <div className="row">
                  <div className="column drp_ln_hg">
                    <label> Today's Date :</label>
                    <div className="date-display">
                      <label
                        className="drp_mg"
                        style={{ border: "1px black solid;" }}
                      >
                        <b>{formattedDate}</b>
                      </label>
                    </div>
                  </div>

                  <div className="column drp_ln_hg">
                    <label className="drp_mg">
                      Leave Type{" "}
                      {errors.leaveType && (
                        <span className="error-message">
                          {errors.leaveType}
                        </span>
                      )}{" "}
                    </label>
                    <div className="field">
                      <span>
                        <i className="fas fa-unlock"></i>
                      </span>
                      <select
                        className="drp1"
                        value={selectedLeaveType}
                        onChange={handleLeaveTypeChange}
                        name="leaveType"
                      >
                        <option value="">Select Leave Type</option>

                        {data.result.map((type) => (
                          <option key={type.lve_typ_cd} value={type.lve_typ_cd}>
                            {type.disp_nm}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="column drp_ln_hg">
                    <label>Leave Balance: </label>
                    <div className="field">
                      {/* Display the leave balance if selected, else show a placeholder */}
                      <b>
                        {leaveBalance !== null
                          ? leaveBalance
                          : "Select a leave type to see balance"}
                      </b>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <label className="mg_l">Employee ID :</label>
                  <div className="column">
                    <div className="field">
                      <b>{formData.employeeId}</b> {/* Using formData */}
                    </div>
                  </div>

                  <label className="mg_l">Approval ID :</label>
                  <div className="column">
                    <div className="field">
                      <b>{formData.approvalId}</b> {/* Using formData */}
                    </div>
                  </div>

                  <label htmlFor="leaveStatus">Leave Status :</label>
                  <div className="column">
                    <div className="field">
                      {formData.leaveStatus && ( // Using formData to display leaveStatus
                        <b style={{ paddingTop: ".1px" }}>
                          {formData.leaveStatus}
                        </b>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="column drp_ln_hg">
                    <label className="drp_mg">
                      CC ID :{" "}
                      {errors.cc_id && (
                        <span className="error-message">{errors.cc_id}</span>
                      )}{" "}
                    </label>
                    <div className="field">
                      <select
                        className="drp1"
                        name="cc_id"
                        value={formData.cc_id}
                        onChange={handleChange}
                      >
                        <option value="">Select CC ID</option>
                        {data.resul1.map((type) => (
                          <option key={type.USR_NTT_ID} value={type.USR_EMP_ID}>
                            {type.USR_EMP_ID} / {type.USR_DISPLAY_NAME}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="column drp_ln_hg">
                    <label className="drp_mg">
                      Leave From :{" "}
                      {errors.lve_from && (
                        <span className="error-message">{errors.lve_from}</span>
                      )}{" "}
                    </label>
                    <div className="field">
                      <input
                        type="date"
                        name="lve_from"
                        value={formData.lve_from}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="column drp_ln_hg">
                    <label className="drp_mg">
                      Leave To :{" "}
                      {errors.lve_to && (
                        <span className="error-message">{errors.lve_to}</span>
                      )}{" "}
                    </label>
                    <div className="field">
                      <input
                        type="date"
                        name="lve_to"
                        value={formData.lve_to}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <hr className="hr_ttl" />
                <p
                  style={{
                    fontSize: "25px",
                    paddingTop: "5px",
                    color: "black",
                  }}
                >
                  Half Day Leave Information
                </p>
                <hr className="hr_dt" />

                <div className="row">
                  <div
                    className="column "
                    style={{ lineHeight: "12px", marginTop: "5px" }}
                  >
                    <label style={{ marginLeft: "0%" }}> Half Day ?</label>
                    <div style={{ marginLeft: "5%" }}>
                      <FormControl>
                        <RadioGroup
                          row
                          name="half_day"
                          value={formData.half_day}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>

                  <div
                    className="column "
                    style={{ lineHeight: "12px", marginTop: "5px" }}
                  >
                    <label style={{ marginLeft: "0%" }}>Which Half ?</label>
                    <div style={{ marginLeft: "5%" }}>
                      <FormControl>
                        <RadioGroup
                          row
                          name="which_half"
                          value={formData.which_half}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value="first"
                            control={<Radio />}
                            label="First Half"
                          />
                          <FormControlLabel
                            value="second"
                            control={<Radio />}
                            label="Second Half"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>

                  <div
                    className="column "
                    style={{ lineHeight: "12px", marginTop: "5px" }}
                  >
                    <label style={{ marginLeft: "0%" }}>
                      Conjugative Half Day ?
                    </label>
                    <div style={{ marginLeft: "5%" }}>
                      <FormControl>
                        <RadioGroup
                          row
                          name="conjugative_half_day"
                          value={formData.conjugative_half_day}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value="first_day"
                            control={<Radio />}
                            label="First Day"
                          />
                          <FormControlLabel
                            value="last_day"
                            control={<Radio />}
                            label="Last Day"
                          />
                          <FormControlLabel
                            value="both_day"
                            control={<Radio />}
                            label="Both"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="column "
                    style={{ lineHeight: "12px", marginTop: "5px" }}
                  >
                    <label style={{ marginLeft: "0%" }}>Daily Half Day ?</label>
                    <div style={{ marginLeft: "5%" }}>
                      <FormControl>
                        <RadioGroup
                          row
                          name="daily_half_day"
                          value={formData.daily_half_day}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                  <div className="column drp_ln_hg">
                    <label className="drp_mg">Half Day From :</label>
                    <div className="field">
                      <input
                        type="date"
                        name="half_day_from"
                        value={formData.half_day_from}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="column drp_ln_hg">
                    <label className="drp_mg">Half Day To :</label>
                    <div className="field">
                      <input
                        type="date"
                        name="half_day_to"
                        value={formData.half_day_to}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="column drp_ln_hg">
                    <label className="">Leave Duration:</label>
                    <div className="date-display">
                      <label className="drp_mg">
                        <b>{leaveDuration} day(s)</b>
                      </label>
                      {/* Display calculated leave duration */}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="column drp_ln_hg">
                    <label style={{ marginLeft: "1%" }}>Leave Reason :</label>
                    <div className="field">
                      <textarea
                        style={{ width: "100%" }}
                        placeholder="enter leave reason"
                        name="leaveReason"
                        value={formData.leaveReason || ""} // Ensure it's controlled with a value
                        onChange={handleChange} // Use the handleChange function
                      />
                    </div>
                    {/* {errors.usr_typ && <span className="error-message">{errors.usr_typ}</span>} */}
                  </div>
                </div>

                <hr className="hr_ttl" style={{ marginTop: "2%" }} />
                <p
                  style={{
                    fontSize: "25px",
                    paddingTop: "5px",
                    color: "black",
                  }}
                >
                  Address Details
                </p>
                <hr className="hr_dt" />

                <div className="row " style={{ marginTop: ".5%" }}>
                  <div className="column drp_ln_hg">
                    <label className="drp_mg">Employee Address:</label>
                    <div className="field">
                      <textarea
                        className="emp_add_text"
                        id="address"
                        name="address"
                        value={address || ""}
                        onClick={handleClick}
                        onChange={handlesetaddress}
                        onBlur={handleBlur}
                        readOnly={!isEditable}
                      />
                    </div>
                  </div>

                  <div className="column drp_ln_hg">
                    <label className="drp_mg">Employee Phone:</label>
                    <div className="field">
                      <input
                        className="emp_add_text"
                        type="text"
                        id="mobileno"
                        name="mobileno"
                        value={mobileno || ""}
                        onClick={handleClick}
                        onChange={handlesetmobileno}
                        onBlur={handleBlur}
                        readOnly={!isEditable}
                      />
                    </div>
                  </div>

                  <div className="column drp_ln_hg">
                    <label className="drp_mg">Employee CellPhone:</label>
                    <div className="field">
                      <input
                        className="emp_add_text"
                        type="text"
                        id="cellphno"
                        name="cellphno"
                        value={cellphno || ""}
                        onClick={handleClick}
                        onChange={handlesetcelleno}
                        onBlur={handleBlur}
                        readOnly={!isEditable}
                      />
                    </div>
                  </div>
                </div>

                <div className="row" style={{ display: "flex", justifyContent: "space-between", marginTop: "2.5%" }}>
                  <hr className="hr_dt" style={{ marginBottom: "1%" }} />
                  <Button
                    className="btn-action action-button"
                    style={{ width: "10%" }}
                    onClick={() => toggleFormVisibility(true)}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{ width: "10%" }}
                    type="submit"
                    className="btn-action action-button"
                    onClick={submit_app}
                  >
                    Submit
                  </Button>
                </div>
              </div>
            </form>
          </div>
        ) : selectedRecord ? (
          <div>
            {/* Your existing form content */}
            <form>
              <div className="form-container">
                <hr className="hr_ttl" />
                <p
                  style={{
                    fontSize: "25px",
                    paddingTop: "5px",
                    color: "black",
                  }}
                >
                  Employee information
                </p>
                <hr className="hr_dt" style={{ marginBottom: "19px" }} />

                <div className="row">
                  <div className="column drp_ln_hg">
                    <label> Application Date :</label>
                    <div className="date-display">
                      <label
                        className="drp_mg"
                        style={{ border: "1px black solid;" }}
                      >
                        <b>{selectedRecord.APP_DT}</b>
                      </label>
                    </div>
                  </div>

                  <div className="column drp_ln_hg">
                    <label className="drp_mg">
                      Leave Type{" "}

                    </label>
                    <div className="field ">
                      <span>
                        <i className="fas fa-unlock"></i>
                      </span>
                      <select
                        className="drp1"
                        value={selectedRecord.APP_TYP}
                        // onChange={handleLeaveTypeChange}
                        name="leaveType"
                      >
                        <option value="">Select Leave Type</option>

                        {data.result.map((type) => (
                          <option key={type.lve_typ_cd} value={type.lve_typ_cd}>
                            {type.disp_nm}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="column drp_ln_hg">
                    <label>Leave Balance: </label>
                    <div className="field">
                      {/* Display the leave balance if selected, else show a placeholder */}
                      <b>
                        {selectedRecord.APP_LVE_BAL !== null
                          ? selectedRecord.APP_LVE_BAL
                          : "Select a leave type to see balance"}
                      </b>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <label className="mg_l">Employee ID :</label>
                  <div className="column">
                    <div className="field">
                      <b>{selectedRecord.APP_EMP_ID}</b> {/* Using formData */}
                    </div>
                  </div>

                  <label className="mg_l">Approval ID :</label>
                  <div className="column">
                    <div className="field">
                      <b>{selectedRecord.APP_APRVL_ID}</b> {/* Using formData */}
                    </div>
                  </div>

                  <label htmlFor="leaveStatus">Leave Status :</label>
                  <div className="column">
                    <div className="field">
                      {selectedRecord.APP_LVE_STTS && ( // Using formData to display leaveStatus
                        <b style={{ paddingTop: ".1px" }}>
                          {selectedRecord.APP_LVE_STTS}
                        </b>
                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="column drp_ln_hg">
                    <label className="drp_mg">
                      CC ID :

                    </label>
                    <div className="field">
                      <select
                        className="drp1"
                        name="cc_id"
                        value={selectedRecord.APP_CC_TO_ID}
                        onChange={handleChange}
                      >
                        <option value="">Select CC ID</option>
                        {data.resul1.map((type) => (
                          <option key={type.USR_NTT_ID} value={type.USR_EMP_ID}>
                            {type.USR_EMP_ID} / {type.USR_DISPLAY_NAME}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="column drp_ln_hg">
                    <label className="drp_mg">
                      Leave From :{" "}

                    </label>
                    <div className="field">
                      <input
                        type="date"
                        name="lve_from"
                        value={selectedRecord.APP_LVE_FRM_DT ? moment(selectedRecord.APP_LVE_FRM_DT).format('YYYY-MM-DD') : ""}
                        onChange={handleChange}
                        disabled
                      />

                    </div>


                  </div>

                  <div className="column drp_ln_hg">
                    <label className="drp_mg">
                      Leave To :{" "}

                    </label>
                    <div className="field">
                      <input
                        type="date"
                        name="lve"
                        value={selectedRecord.APP_LVE_TO_DT ? moment(selectedRecord.APP_LVE_TO_DT).format('YYYY-MM-DD') : ""}
                        onChange={handleChange}
                        disabled
                      />
                    </div>
                  </div>
                </div>

                <hr className="hr_ttl" />
                <p
                  style={{
                    fontSize: "25px",
                    paddingTop: "5px",
                    color: "black",
                  }}
                >
                  Half Day Leave Information
                </p>
                <hr className="hr_dt" />

                <div className="row">
                  <div
                    className="column "
                    style={{ lineHeight: "12px", marginTop: "5px" }}
                  >
                    <label style={{ marginLeft: "0%" }}> Half Day ?</label>
                    <div style={{ marginLeft: "5%" }}>
                      <FormControl>
                        <RadioGroup
                          row
                          name="half_day"
                          value={selectedRecord.APP_HLF_DE}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value="Y"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="N"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>

                  <div
                    className="column "
                    style={{ lineHeight: "12px", marginTop: "5px" }}
                  >
                    <label style={{ marginLeft: "0%" }}>Which Half ?</label>
                    <div style={{ marginLeft: "5%" }}>
                      <FormControl>
                        <RadioGroup
                          row
                          name="which_half"
                          value={selectedRecord.APP_HLF_1_2}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value="1"
                            control={<Radio />}
                            label="First Half"
                          />
                          <FormControlLabel
                            value="2"
                            control={<Radio />}
                            label="Second Half"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>

                  <div
                    className="column "
                    style={{ lineHeight: "12px", marginTop: "5px" }}
                  >
                    <label style={{ marginLeft: "0%" }}>
                      Conjugative Half Day ?
                    </label>
                    <div style={{ marginLeft: "5%" }}>
                      <FormControl>
                        <RadioGroup
                          row
                          name="conjugative_half_day"
                          value={selectedRecord.APP_CNZTV_HLF_DE_YN}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value="Y"
                            control={<Radio />}
                            label="First Day"
                          />
                          <FormControlLabel
                            value="N"
                            control={<Radio />}
                            label="Last Day"
                          />
                          <FormControlLabel
                            value="M"
                            control={<Radio />}
                            label="Both"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="column "
                    style={{ lineHeight: "12px", marginTop: "5px" }}
                  >
                    <label style={{ marginLeft: "0%" }}>Daily Half Day ?</label>
                    <div style={{ marginLeft: "5%" }}>
                      <FormControl>
                        <RadioGroup
                          row
                          name="daily_half_day"
                          value={selectedRecord.APP_DLY_HLF_DE_YN}
                          onChange={handleChange}
                        >
                          <FormControlLabel
                            value="Y"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="N"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                  <div className="column drp_ln_hg">
                    <label className="drp_mg">Half Day From :</label>
                    <div className="field">
                      <input
                        type="date"
                        name="half_day_from"
                        value={selectedRecord.APP_HLF_FRM_DT ? moment(selectedRecord.APP_HLF_FRM_DT).format('YYYY-MM-DD') : ""}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="column drp_ln_hg">
                    <label className="drp_mg">Half Day To :</label>
                    <div className="field">
                      <input
                        type="date"
                        name="half_day_to"
                        value={selectedRecord.APP_HLF_TO_DT ? moment(selectedRecord.APP_HLF_TO_DT).format('YYYY-MM-DD') : ""}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                  <div className="column drp_ln_hg">
                    <label className="">Leave Duration:</label>
                    <div className="date-display">
                      <label className="drp_mg">
                        <b>{selectedRecord.APP_LVE_DES} day(s)</b>
                      </label>
                      {/* Display calculated leave duration */}
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div className="column drp_ln_hg">
                    <label style={{ marginLeft: "1%" }}>Leave Reason :</label>
                    <div className="field">
                      <textarea
                        style={{ width: "100%" }}
                        placeholder="No leave reason"
                        name="leaveReason"
                        value={selectedRecord.APP_LVE_RES || ""} // Ensure it's controlled with a value
                        onChange={handleChange} // Use the handleChange function
                      />
                    </div>
                    {/* {errors.usr_typ && <span className="error-message">{errors.usr_typ}</span>} */}
                  </div>
                </div>

                <hr className="hr_ttl" style={{ marginTop: "2%" }} />
                <p
                  style={{
                    fontSize: "25px",
                    paddingTop: "5px",
                    color: "black",
                  }}
                >
                  Address Details
                </p>
                <hr className="hr_dt" />

                <div className="row " style={{ marginTop: ".5%" }}>
                  <div className="column drp_ln_hg">
                    <label className="drp_mg">Employee Address:</label>
                    <div className="field">
                      <textarea
                        className="emp_add_text"
                        // id=" address"
                        name="address"
                        value={selectedRecord.APP_LVE_ADD}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="column drp_ln_hg">
                    <label className="drp_mg">Employee Phone:</label>
                    <div className="field">
                      <input
                        className="emp_add_text"
                        type="text"
                        // id="mobileno"
                        name="mobileno"
                        value={selectedRecord.APP_PH_NO}
                        onChange={handleChange}
                      />
                    </div>
                  </div>

                  <div className="column drp_ln_hg">
                    <label className="drp_mg">Employee CellPhone:</label>
                    <div className="field">
                      <input
                        className="emp_add_text"
                        type="text"
                        // id="cellphno"
                        name="cellphno"
                        value={selectedRecord.APP_PH_NO}
                        onChange={handleChange}
                      />
                    </div>
                  </div>
                </div>

                <div className="row" style={{ display: "flex", justifyContent: "space-between", marginTop: "1.8%" }}>
                  <hr className="hr_dt" style={{ marginBottom: ".5%" }} />
                  <Button
                    className="btn-action action-button"
                    style={{ width: "10%" }}
                    onClick={() => toggleFormVisibility1(true)}
                  >
                    Cancel
                  </Button>
                  {/* <Button
                    style={{ width: "10%" }}
                    type="submit"
                    className="btn-action action-button"
                    onClick={submit_app}
                  >
                    Submit
                  </Button> */}
                </div>
              </div>
            </form>
          </div>
        ) : selectedRecord1 ? (
          <div>
            {/* Your existing form content */}

            <form method="Post" onSubmit={handleSubmit}>
              <div className="form-container">
                <hr className="hr_ttl" />
                <p
                  style={{
                    fontSize: "25px",
                    paddingTop: "5px",
                    color: "black",
                  }}
                >
                  Employee information
                </p>
                <hr className="hr_dt" style={{ marginBottom: "19px" }} />

                <div className="row">
                  <div className="column drp_ln_hg">
                    <label> Application Date :</label>
                    <div className="date-display">
                      <label
                        className="drp_mg"
                        style={{ border: "1px black solid;" }}
                      >
                        <b>{selectedRecord1.APP_DT}</b>
                      </label>
                    </div>
                  </div>

                  <div className="column drp_ln_hg">
                    <label className="drp_mg">
                      Leave Type{" "}
                      {errors.leaveType && (
                        <span className="error-message">
                          {errors.leaveType}
                        </span>
                      )}{" "}
                    </label>
                    <div className="field">
                      <select
                        className="drp1"
                        name="leaveType"
                        value={editdata.leaveType} // Controlled input
                        // onChange={handleInputChange}
                        disabled // Disable if not editable
                      >
                        <option value="">Select Leave Type</option>
                        {data.result.map((type) => (
                          <option key={type.lve_typ_cd} value={type.lve_typ_cd}>
                            {type.disp_nm}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="column drp_ln_hg">
                    <label>Leave Balance: </label>
                    <div className="field">
                      {/* Display the leave balance if selected, else show a placeholder */}
                      <b>
                        {editdata.leaveBalance ? selectedRecord1.APP_LVE_BAL : "Select a leave type to see balance"}
                      </b>
                    </div>
                  </div>
                </div>
                <div className="row">
                  <label className="mg_l">Employee ID :</label>
                  <div className="column">
                    <div className="field">
                      <b>{selectedRecord1 ? selectedRecord1.APP_EMP_ID : ""}</b> {/* Using formData */}
                    </div>
                  </div>

                  <label className="mg_l">Approval ID :</label>
                  <div className="column">
                    <div className="field">
                      <b>{selectedRecord1 ? selectedRecord1.APP_APRVL_ID : ""}</b> {/* Using formData */}
                    </div>
                  </div>

                  <label htmlFor="leaveStatus">Leave Status :</label>
                  <div className="column">
                    <div className="field">
                      {editdata.leaveStatus && editdata.leaveStatus && ( // Using formData to display leaveStatus
                        <b style={{ paddingTop: ".1px" }}>
                          {editdata.leaveStatus}
                        </b>

                      )}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="column drp_ln_hg">
                    <label className="drp_mg">
                      CC ID :{" "}
                      {errors.cc_id && (
                        <span className="error-message">{errors.cc_id}</span>
                      )}{" "}
                    </label>
                    <div className="field">
                      <select
                        className="drp1"
                        name="cc_id"
                        value={editdata.cc_id ? editdata.cc_id : ""}
                        onChange={handleInputChange}
                        disabled={!isEditable} // Add disabled attribute
                      >
                        <option value="">Select CC ID</option>
                        {data.resul1.map((type) => (
                          <option key={type.USR_NTT_ID} value={type.USR_EMP_ID}>
                            {type.USR_EMP_ID} / {type.USR_DISPLAY_NAME}
                          </option>
                        ))}
                      </select>
                    </div>
                  </div>

                  <div className="column drp_ln_hg">
                    <label className="drp_mg">
                      Leave From :{" "}
                      {errors.lve_from && (
                        <span className="error-message">{errors.lve_from}</span>
                      )}{" "}
                    </label>
                    <div className="field">
                      <input
                        type="date"
                        name="lve_from"
                        value={editdata.lve_from ? moment(editdata.lve_from).format('YYYY-MM-DD') : ""} // Format the date using moment.js
                        onChange={handleInputChange}
                        readOnly={!isEditable} // Conditionally render the readOnly attribute
                      />
                    </div>
                  </div>
                  <div className="column drp_ln_hg">
                    <label className="drp_mg">
                      Leave To :{" "}
                      {errors.lve_to && (
                        <span className="error-message">{errors.lve_to}</span>
                      )}{" "}
                    </label>
                    <div className="field">
                      <input
                        type="date"
                        name="lve_to"
                        value={editdata.lve_to ? moment(editdata.lve_to).format('YYYY-MM-DD') : ""} // Format the date using moment.js
                        onChange={handleInputChange}
                        readOnly={!isEditable} // Conditionally render the readOnly attribute
                      />
                    </div>
                  </div>
                </div>

                <hr className="hr_ttl" />
                <p
                  style={{
                    fontSize: "25px",
                    paddingTop: "5px",
                    color: "black",
                  }}
                >
                  Half Day Leave Information
                </p>
                <hr className="hr_dt" />

                <div className="row">
                  <div
                    className="column "
                    style={{ lineHeight: "12px", marginTop: "5px" }}
                  >
                    <label style={{ marginLeft: "0%" }}> Half Day ?</label>
                    <div style={{ marginLeft: "5%" }}>
                      <FormControl>
                        <RadioGroup
                          row
                          name="half_day"
                          value={editdata ? editdata.half_day : ""}
                          onChange={handleInputChange}
                        >
                          <FormControlLabel
                            value="yes"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="no"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>

                  <div
                    className="column "
                    style={{ lineHeight: "12px", marginTop: "5px" }}
                  >
                    <label style={{ marginLeft: "0%" }}>Which Half ?</label>
                    <div style={{ marginLeft: "5%" }}>
                      <FormControl>
                        <RadioGroup
                          row
                          name="which_half"
                          value={editdata ? editdata.which_half : ""}
                          onChange={handleInputChange}
                        >
                          <FormControlLabel
                            value="first"
                            control={<Radio />}
                            label="First Half"
                          />
                          <FormControlLabel
                            value="second"
                            control={<Radio />}
                            label="Second Half"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>

                  <div
                    className="column "
                    style={{ lineHeight: "12px", marginTop: "5px" }}
                  >
                    <label style={{ marginLeft: "0%" }}>
                      Conjugative Half Day ?
                    </label>
                    <div style={{ marginLeft: "5%" }}>
                      <FormControl>
                        <RadioGroup
                          row
                          name="conjugative_half_day"
                          value={editdata ? editdata.conjugative_half_day : "N"}
                          onChange={handleInputChange}
                        >
                          <FormControlLabel
                            value="first_day"
                            control={<Radio />}
                            label="First Day"
                          />
                          <FormControlLabel
                            value="last_day"
                            control={<Radio />}
                            label="Last Day"
                          />
                          <FormControlLabel
                            value="both_day"
                            control={<Radio />}
                            label="Both"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                </div>

                <div className="row">
                  <div
                    className="column "
                    style={{ lineHeight: "12px", marginTop: "5px" }}
                  >
                    <label style={{ marginLeft: "0%" }}>Daily Half Day ?</label>
                    <div style={{ marginLeft: "5%" }}>
                      <FormControl>
                        <RadioGroup
                          row
                          name="daily_half_day"
                          value={editdata ? editdata.daily_half_day : "N"}
                          onChange={handleInputChange}
                        >
                          <FormControlLabel
                            value="Y"
                            control={<Radio />}
                            label="Yes"
                          />
                          <FormControlLabel
                            value="N"
                            control={<Radio />}
                            label="No"
                          />
                        </RadioGroup>
                      </FormControl>
                    </div>
                  </div>
                  <div className="column drp_ln_hg">
                    <label className="drp_mg">Half Day From :</label>
                    <div className="field">
                      <input
                        type="date"
                        name="half_day_from"
                        value={editdata.half_day_from ? moment(editdata.half_day_from).format('YYYY-MM-DD') : ""} // Format the date using moment.js
                        onChange={handleInputChange}
                        readOnly={!isEditable} // Conditionally render the readOnly attribute
                      />
                    </div>
                  </div>

                  <div className="column drp_ln_hg">
                    <label className="drp_mg">Half Day To :</label>
                    <div className="field">
                      <input
                        type="date"
                        name="half_day_to"
                        value={editdata.half_day_to ? moment(editdata.half_day_to).format('YYYY-MM-DD') : ""} // Format the date using moment.js
                        onChange={handleInputChange}
                        readOnly={!isEditable} // Conditionally render the readOnly attribute
                      />
                    </div>
                  </div>
                  <div className="column drp_ln_hg">
                    <label className="">Leave Duration:</label>
                    <div className="date-display">
                      <label className=" drp_mg">
                        <b>
                          <b>{leaveDuration} day(s)</b>
                        </b>
                      </label>
                      {/* Display calculated leave duration */}
                    </div>
                  </div>
                </div>
                <div className="row">
                  <div className="column drp_ln_hg">
                    <label style={{ marginLeft: "1%" }}>Leave Reason :</label>
                    <div className="field">
                      <textarea
                        style={{ width: "100%" }}
                        placeholder="Enter leave reason"
                        name="leaveReason"
                        value={editdata.leaveReason} // Controlled input
                        onChange={handleInputChange}
                        readOnly={!isEditable} // Make non-editable if isEditable is false
                      />
                    </div>
                    {/* {errors.usr_typ && <span className="error-message">{errors.usr_typ}</span>} */}
                  </div>
                </div>

                <hr className="hr_ttl" style={{ marginTop: "2%" }} />
                <p
                  style={{
                    fontSize: "25px",
                    paddingTop: "5px",
                    color: "black",
                  }}
                >
                  Address Details
                </p>
                <hr className="hr_dt" />

                <div className="row " style={{ marginTop: ".5%" }}>
                  <div className="column drp_ln_hg">
                    <label className="drp_mg">Employee Address:</label>
                    <div className="field">
                      <textarea
                        className="emp_add_text"
                        id="address"
                        name="address"
                        value={editdata.address ? editdata.address : ""}

                        onChange={handleInputChange}

                        readOnly={!isEditable} // Conditionally render the readOnly attribute
                      />
                    </div>
                  </div>

                  <div className="column drp_ln_hg">
                    <label className="drp_mg">Employee Phone:</label>
                    <div className="field">
                      <input
                        className="emp_add_text"
                        type="text"
                        id="mobileno"
                        name="mobileno"
                        value={editdata.mobileno ? editdata.mobileno : ""}

                        onChange={handleInputChange}
                        readOnly={!isEditable} // Conditionally render the readOnly attribute
                      />
                    </div>
                  </div>

                  <div className="column drp_ln_hg">
                    <label className="drp_mg">Employee CellPhone:</label>
                    <div className="field">
                      <input
                        className="emp_add_text"
                        type="text"
                        id="cellphno"
                        name="cellphno"
                        value={editdata.cellphno ? editdata.cellphno : ""}

                        onChange={handleInputChange}
                        readOnly={!isEditable} // Conditionally render the readOnly attribute
                      />
                    </div>
                  </div>
                </div>

                <div className="row" style={{ display: "flex", justifyContent: "space-between", marginTop: "2.5%" }}>
                  <hr className="hr_dt" style={{ marginBottom: "1%" }} />
                  <Button
                    className="btn-action action-button"
                    style={{ width: "10%" }}
                    onClick={() => {
                      // setIsEditMode(false);
                      toggleFormVisibility2(true);
                    }}
                  >
                    Cancel
                  </Button>
                  <Button
                    style={{ width: "auto" }}
                    type="submit"
                    className="btn-action action-button"
                    onClick={edit_apliction}
                    disabled={selectedRecord1?.APP_LVE_STTS !== "Pending"} // Disable if leave status is not Pending
                  >
                    Edit Application
                  </Button>
                  <Button
                    style={{ width: "auto" }}
                    type="submit"
                    className="btn-action action-button"
                    onClick={req_apl_for_cnl_aprvl}
                  >
                    Cancel for Approval
                  </Button>
                </div>
              </div>
            </form>
          </div>
        ) : (
          <div
            style={{
              display: "flex",
              flexDirection: "column",
              alignItems: "center",
            }}
          >

            {/* HR before the table */}
            <Box display="flex" justifyContent="flex-end" mb={2} width="100%">
              <Button
                className="new_app_btn"
                onClick={toggleFormVisibility}
                style={{ margin: '10px' }} // Adjust margin as needed
                startIcon={<AddIcon />} // This adds the icon before the text
              >
                New Application
              </Button>
            </Box>



            <hr
              style={{
                border: "1px solid #4a6d8c",
                width: "90%", // Set HR width to 90%
                margin: "0", // Remove margin
              }}
            />{" "}
            <TableContainer
              style={{
                margin: "10px auto",
                maxWidth: "85%", // Keeps the table centered and limited to 90% width
                overflow: "visible", // Prevents scrollbars
              }}
            >
              <Table style={{ width: "100%" }}>
                <TableHead>
                  <TableRow>
                    <TableCell style={{ fontSize: "0.8rem", padding: "8px 18px" }} className="txt_ttl">
                      Name
                    </TableCell>
                    <TableCell style={{ fontSize: "0.8rem", padding: "8px 18px" }} className="txt_ttl">
                      Employee ID
                    </TableCell>
                    <TableCell style={{ fontSize: "0.8rem", padding: "8px" }} className="txt_ttl">
                      OBO ID
                    </TableCell>
                    <TableCell style={{ fontSize: "0.8rem", padding: "8px" }} className="txt_ttl">
                      Date Submitted
                    </TableCell>
                    <TableCell style={{ fontSize: "0.8rem", padding: "8px" }} className="txt_ttl">
                      Leave Type
                    </TableCell>
                    <TableCell style={{ fontSize: "0.8rem", padding: "8px" }} className="txt_ttl">
                      Leave From
                    </TableCell>
                    <TableCell style={{ fontSize: "0.8rem", padding: "8px" }} className="txt_ttl">
                      Leave To
                    </TableCell>
                    <TableCell style={{ fontSize: "0.8rem", padding: "8px" }} className="txt_ttl">
                      Status
                    </TableCell>
                    <TableCell style={{ fontSize: "0.8rem", padding: "8px", width: "120px", textAlign: "center" }} className="txt_ttl">
                      Actions
                    </TableCell>
                  </TableRow>
                  <TableRow>
                    <TableCell colSpan={9} style={{ padding: 0 }}>
                      <hr style={{ border: "2px solid gray", margin: 0 }} />
                    </TableCell>
                  </TableRow>
                </TableHead>
                <TableBody>
                  {data.result4.map((app, index) => (
                    <React.Fragment key={index}>
                      <TableRow style={{ height: "30px" }}>
                        <TableCell style={{ fontSize: "0.8rem", padding: "8px 18px" }}>
                          {app.USR_DISPLAY_NAME}
                        </TableCell>
                        <TableCell style={{ fontSize: "0.8rem", padding: "8px 18px" }}>
                          {app.APP_EMP_ID}
                        </TableCell>
                        <TableCell style={{ fontSize: "0.8rem", padding: "4px" }}>
                          {app.APP_OBO_ID}
                        </TableCell>
                        <TableCell style={{ fontSize: "0.8rem", padding: "4px" }}>
                          {(app.APP_DT)}
                        </TableCell>
                        <TableCell style={{ fontSize: "0.8rem", padding: "4px" }}>
                          {app.APP_TYP}
                        </TableCell>
                        <TableCell style={{ fontSize: "0.8rem", padding: "4px" }}>
                          {(app.APP_LVE_FRM_DT)}
                        </TableCell>
                        <TableCell style={{ fontSize: "0.8rem", padding: "4px" }}>
                          {(app.APP_LVE_TO_DT)}
                        </TableCell>
                        <TableCell style={{ fontSize: "0.8rem", padding: "4px" }}>
                          {app.APP_LVE_STTS}
                        </TableCell>
                        <TableCell
                          style={{
                            padding: "4px",
                            display: "flex",
                            justifyContent: "center", // Align the buttons centrally
                            gap: "2px", // Minimal gap between buttons
                          }}
                        >
                          <Button style={{ minWidth: "auto" }} onClick={() => handleView(app)} size="small">
                            <VisibilityIcon style={{ color: "#4a6d8c" }} fontSize="small" />
                          </Button>
                          <Button
                            style={{ minWidth: "auto" }}
                            onClick={() => handleEdit1(app.APP_EMP_ID, app.APP_TYP, app.APP_DT, app.APP_ID)}
                            size="small"
                          >
                            <EditIcon style={{ color: "#4a6d8c" }} fontSize="small" />
                          </Button>
                          <Button
                            style={{ minWidth: "auto" }}
                            onClick={() => handleDelete(app.APP_EMP_ID, app.APP_TYP, app.APP_DT, app.APP_ID)}
                            size="small"
                          >
                            <CancelIcon style={{ color: "#4a6d8c" }} fontSize="small" />
                          </Button>
                        </TableCell>
                      </TableRow>
                      {index < data.result4.length - 1 && (
                        <TableRow>
                          <TableCell colSpan={9} style={{ padding: "0" }}>
                            <hr style={{ border: "1px solid gray", margin: "0", width: "100%" }} />
                          </TableCell>
                        </TableRow>
                      )}
                    </React.Fragment>
                  ))}
                </TableBody>
              </Table>
            </TableContainer>


            <hr
              style={{
                border: "1px solid #4a6d8c",
                width: "90%", // Set HR width to 90%
                marginBottom: "3%", // Remove margin
              }}
            />{" "}
            {/* HR after the table */}
          </div>
        )}


      </div>
    </div >
  );
}

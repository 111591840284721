import React, { useState, useEffect } from "react";
import services from "./apiServices";
import Radio from "@mui/material/Radio";
import RadioGroup from "@mui/material/RadioGroup";
import FormControlLabel from "@mui/material/FormControlLabel";
import FormControl from "@mui/material/FormControl";

export default function OBO_Emp_Leave_Application() {
  const storedUserData = sessionStorage.getItem("user_data");
  let usr_log = null;
  if (storedUserData) {
    usr_log = JSON.parse(storedUserData); // Convert the JSON string back into an object/array
    // console.log(usr_log); // Now you have access to the original usr_log object
  }
  console.log(usr_log);

  const [usrdata, setUsrdata] = useState({
    usrtype: [],
    usrcat: [],
    usrtitle: [],
    Desgn: [],
    lve_status: [],
  });
  const [gender, setgender] = useState();
  const [data, setData] = useState({
    result: [],
    resul1: [],
    result2: [],
    result3: [],
  });
  const [selectedLeaveType, setSelectedLeaveType] = useState("");
  const [leaveBalance, setLeaveBalance] = useState(null);
  const [ccmail, setccmail] = useState(null); // State to hold approval email

  const [afltdata, setAffdata] = useState([]);
  const [loading, setLoading] = useState(true);
  const [error, setError] = useState(null);
  const [firstcomplete, setfirstcomplete] = useState(false);
  const [errors, setErrors] = useState({});
  const [leaveDuration, setLeaveDuration] = useState(0); // State for leave duration
  const initialFormData = {
    employeeId: "",
    approvalId: "",
    address: "",
    mobileno: "",
    cellphno: "",
    usrmail: "",
    oboid: "",
    obomail: "",
  };
  const [formData, setFormData] = useState({
    leaveInDbord:"",
    leaveType: "",
    leaveBalance: "",
    employeeId: "",
    approvalId: "",
    leaveStatus: "",
    oboid: "",
    cc_id: "",
    lve_from: "",
    lve_to: "",
    half_day: "", // Set an initial value for radio buttons
    which_half: "", // Initial value
    conjugative_half_day: "", // Initial value
    half_day_from: "",
    daily_half_day: "", // Initial value
    half_day_to: "",
    leaveReason: "", // For Leave Reason textarea
    address: "", // For Employee Address
    mobileno: "", // For Employee Phone
    cellphno: "", // For Employee CellPhone
    usrmail: "",
    ccmail: "",
    approvalemail: "",
    obomail: "",
  });
  const calculateLeaveDuration = () => {
    const {
      lve_from,
      lve_to,
      half_day,
      conjugative_half_day,
      daily_half_day,
      half_day_from,
      half_day_to,
    } = formData;
    let totalDuration = 0;

    // Extract working days from afltdata
    const workingDays = afltdata
      .filter((day) => day.AFLT_CONF_PARA === "WORKING_DAYS")
      .map((day) => day.AFLT_CONF_PARA_VAL.toLowerCase());

    // Calculate full days based on lve_from and lve_to
    if (lve_from && lve_to) {
      const startDate = new Date(lve_from);
      const endDate = new Date(lve_to);

      // Loop through the date range to count valid working days
      let currentDate = new Date(startDate);
      while (currentDate <= endDate) {
        const dayName = currentDate
          .toLocaleString("en-US", { weekday: "long" })
          .toLowerCase();
        if (workingDays.includes(dayName)) {
          totalDuration++;
        }
        currentDate.setDate(currentDate.getDate() + 1); // Move to the next day
      }
    }

    // Handle half-day logic based on daily_half_day or conjugative_half_day
    let halfDayCount = 0;

    if (half_day === "yes") {
      if (daily_half_day === "yes" && half_day_from && half_day_to) {
        // Daily half-day calculation
        const halfStartDate = new Date(half_day_from);
        const halfEndDate = new Date(half_day_to);

        let currentHalfDate = new Date(halfStartDate);
        while (currentHalfDate <= halfEndDate) {
          const dayName = currentHalfDate
            .toLocaleString("en-US", { weekday: "long" })
            .toLowerCase();
          if (workingDays.includes(dayName)) {
            halfDayCount += 0.5; // Add half a day for each valid working day
          }
          currentHalfDate.setDate(currentHalfDate.getDate() + 1);
        }
      } else {
        // Conjugative half-day calculation
        switch (conjugative_half_day) {
          case "first_day":
            if (
              workingDays.includes(
                new Date(lve_from)
                  .toLocaleString("en-US", { weekday: "long" })
                  .toLowerCase()
              )
            ) {
              halfDayCount += 0.5; // Subtract half day from first working day
            }
            break;
          case "last_day":
            if (
              workingDays.includes(
                new Date(lve_to)
                  .toLocaleString("en-US", { weekday: "long" })
                  .toLowerCase()
              )
            ) {
              halfDayCount += 0.5; // Subtract half day from last working day
            }
            break;
          case "both_day":
            if (
              workingDays.includes(
                new Date(lve_from)
                  .toLocaleString("en-US", { weekday: "long" })
                  .toLowerCase()
              )
            ) {
              halfDayCount += 0.5; // Subtract half day from first working day
            }
            if (
              workingDays.includes(
                new Date(lve_to)
                  .toLocaleString("en-US", { weekday: "long" })
                  .toLowerCase()
              )
            ) {
              halfDayCount += 0.5; // Subtract half day from last working day
            }
            break;
          default:
            break;
        }
      }
    }

    // Subtract half days from the total leave duration
    totalDuration -= halfDayCount;

    // Set the total leave duration
    setLeaveDuration(totalDuration);
  };

  // Effect to calculate leave duration when relevant fields change
  useEffect(() => {
    calculateLeaveDuration();
  }, [
    formData.lve_from,
    formData.lve_to,
    formData.half_day,
    formData.conjugative_half_day,
    formData.daily_half_day,
    formData.half_day_from,
    formData.half_day_to,
    afltdata, // Add afltdata to the dependency array
  ]);

  const handleChange = (e) => {
    const { name, value } = e.target;

    // If the field being changed is "conjugative_half_day", update "daily_half_day" to "no"
    if (name === "conjugative_half_day") {
      setFormData({
        ...formData,
        [name]: value,
        daily_half_day: "no", // Automatically set to "no" when conjugative_half_day changes
      });
    } else {
      // For all other fields, update normally
      setFormData({
        ...formData,
        [name]: value,
      });
    }
  };

  useEffect(() => {
    console.log("EmployeeForm rendered");
    console.log("Data result3:", data.result3);
  }, [data]);

  const handleChange1 = (event) => {
    const selectedEmployeeId = event.target.value;
    const selectedEmployee = data.result3.find(
      (emp) => emp.USR_EMP_ID === selectedEmployeeId
    );

    console.log("Handle change called");
    console.log("selectedEmployee:", selectedEmployee);

    if (selectedEmployee) {
      // Collect address parts in an array
      const addressParts = [
        selectedEmployee.ADD_LN1,
        selectedEmployee.ADD_LN2,
        selectedEmployee.ADD_LN3,
        selectedEmployee.ADD_CT,
        selectedEmployee.ADD_PIN_ZIP,
      ];

      // Filter out empty parts and join them with ", "
      const fullAddress = addressParts.filter((part) => part).join(", ");

      setFormData((prevFormData) => ({
        ...prevFormData,
        employeeId: selectedEmployee.USR_EMP_ID,
        approvalId: selectedEmployee.USR_APRVL_ID || "",
        address: fullAddress, // Store the full address in one field
        mobileno: selectedEmployee.USR_CELL_PH || "",
        cellphno: selectedEmployee.USR_CELL_PH2 || "",
        usrmail: selectedEmployee.USR_EMAIL || "",
      }));
    } else {
      setFormData(initialFormData);
    }
  };

  useEffect(() => {
    console.log("Updated formData:", formData);
  }, [formData]);

  const updateFormData = () => {
    const newLeaveStatus = usrdata.lve_status[0]?.ATTRIB_NAME;
    const newApprovalemail = data.result2[0]?.USR_EMAIL;

    // Only update if values have changed
    if (
      newApprovalemail !== formData.approvalemail ||
      newLeaveStatus !== formData.leaveStatus
    ) {
      setFormData({
        approvalemail: newApprovalemail,
        leaveStatus: newLeaveStatus,
      });
    }
  };

  useEffect(() => {
    if (usr_log.length > 0 && usrdata.lve_status.length > 0) {
      updateFormData();
    }
  }, [usr_log, usrdata]); // Dependency array only runs when these values change

  const handleLeaveTypeChange = (e) => {
    const selectedType = e.target.value;

    // Check if data.result exists and is an array
    const leaveArray = Array.isArray(data.result) ? data.result : [];

    // Find the corresponding leave type object from the data.result array
    const selectedLeave = leaveArray.find(
      (type) => type.lve_typ_cd === selectedType
    );

    setSelectedLeaveType(selectedType);
    setLeaveBalance(selectedLeave ? selectedLeave.LVE_TYP_RN_BAL_CNT : null);

    // Store the selected leave type's LVE_BAL_IN_DBORD
    if (selectedLeave) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        leaveType: selectedType,
        leaveBalance: selectedLeave.LVE_TYP_RN_BAL_CNT,
        leaveInDbord: selectedLeave.LVE_BAL_IN_DBORD // Store the LVE_BAL_IN_DBORD value
        
      }));
      
    }
  };

  // console.log(leaveBalance);
  // console.log(selectedLeaveType);

  // Toggle editable state when input is clicked

  const currentDate = new Date();
  const day = currentDate.getDate().toString().padStart(2, "0");
  const monthShort = currentDate.toLocaleString("default", { month: "short" });
  const year = currentDate.getFullYear();
  const formattedDate = `${day}/${monthShort}/${year}`;

  // console.log(formattedDate);
  useEffect(() => {
    const fetch = async () => {
      try {
        const result = await services.getAffiliateLeaveTypes();

        // console.log("Fetched data:", result);

        // Adjust this based on actual data structure
        if (result) {
          setData(result); // Wrap it in an object if necessary
        } else {
          console.error("Unexpected data format:", result);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };

    fetch();
  }, []);

  useEffect(() => {
    const fg = async () => {
      try {
        // Make multiple requests
        const res = await Promise.all([
          services.getdropdowndata("APCBL_GNDR"),
          services.getdropdowndata("User_Type"),
          services.getdropdowndata("User_Category"),
          services.getdropdowndata("User_Title"),
          services.getdropdowndata("APCBL_POST"),
          services.getdropdowndata("LVE_STTS"),
        ]);

        const newUsrdata = {
          usrtype: [],
          usrcat: [],
          usrtitle: [],
          Desgn: [],
          lve_status: [],
        };

        // Process responses based on the name identifier
        res.forEach((response) => {
          const { name, dpresult } = response;

          switch (name) {
            case "APCBL_GNDR":
              setgender(dpresult);
              break;
            case "User_Type":
              newUsrdata.usrtype = dpresult;
              break;
            case "User_Category":
              newUsrdata.usrcat = dpresult;
              break;
            case "User_Title":
              newUsrdata.usrtitle = dpresult;
              break;
            case "APCBL_POST":
              newUsrdata.Desgn = dpresult;
              break;
            case "LVE_STTS":
              newUsrdata.lve_status = dpresult;
              break;
            default:
              break;
          }
        });

        setUsrdata(newUsrdata);
      } catch (err) {
        // Handle errors
        setError(err);
      } finally {
        // Set loading to false when requests are completed
        setLoading(false);
      }
    };

    fg();
  }, []);
  useEffect(() => {
    const fetch = async () => {
      try {
        const affdata = await services.getaffilatedata();
        if (affdata) {
          setAffdata(affdata);
          // console.log("affdata", affdata);
        } else {
          console.log("Error for fetching data:");
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    fetch();
  }, []);

  console.log(data);

 
  // Function to get email address by cc_id

  const getEmailByCcId = (cc_id) => {
    console.log("Searching for cc_id:", cc_id); // Log cc_id
    const employee = data.resul1.find((emp) => {
      console.log("Checking employee:", emp); // Log each employee being checked
      return emp.USR_EMP_ID === cc_id;
    });

    if (employee) {
      console.log("Found employee:", employee); // Log the found employee
      return employee.USR_EMAIL; // Return the email if found
    } else {
      console.log("Employee not found"); // Log if not found
      return null;
    }
  };

  // Example usage
  useEffect(() => {
    const emailAddress = getEmailByCcId(formData.cc_id);
    setccmail(emailAddress); // Update state with the email
    console.log(emailAddress); // Log the email address or do something with it
  }, [formData.cc_id, data.resul1]);
  
  const validateForm = () => {
    const newErrors = {};

    // Check each field for emptiness
    if (!formData.leaveType) newErrors.leaveType = "Leave Type is not selected";
    if (!formData.cc_id) newErrors.cc_id = "CC ID is not selected";
    // if (!formData.oboid) newErrors.oboid = "OBO ID is not selected";
    if (!formData.lve_from)
      newErrors.lve_from = "Leave From date is not selected";
    if (!formData.lve_to) newErrors.lve_to = "Leave To date is not selected";

    setErrors(newErrors);
    return Object.keys(newErrors).length === 0; // Return true if no errors
  };

  const submit_app = async () => {
    // Update formData with the current state values, including the approval email from state
    const updatedFormData = {
      ...formData,
      ccmail: ccmail, // Use the state variable here
    };

    // Check if leave balance is 0
    if (leaveBalance <= 0) {
      // If balance is 0, check leaveInDbord
      if (formData.leaveInDbord === "Y") {
        alert("Your leave balance for this type of leave is 0. You cannot submit the application.");
        return; // Prevent further execution
      } else if (formData.leaveInDbord === "N") {
        // Allow submission if leaveInDbord is "N"
        console.log("Leave balance is 0, but leaveInDbord is 'N'. Proceeding with submission.");
      }
    }

    console.log("Final data before submission:", updatedFormData);

    if (validateForm()) {
      try {
        // Call the submission service with the updated data
        const insrt_res = await services.sbmit_lve_app(updatedFormData);

        if (insrt_res) {
          setfirstcomplete(true);
          console.log("Leave Application Submitted Successfully....");
          alert("OBO Leave Application Submitted Successfully....");

          // Send the email through the backend API
          const sendmaildata = await services.send_mail(updatedFormData);

          if (sendmaildata.ok) {
            console.log("Email sent successfully.");
          } else {
            console.error("Error sending email:", await sendmaildata.text());
          }
        }
      } catch (error) {
        console.error("Leave Application failed to Submit...", error);
      }
    }
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
  };
  useEffect(() => {
    // Set OBO ID to formData when the component mounts, but only if not already set
    if (usr_log && usr_log.length > 0 && !formData.oboId) {
      setFormData((prevFormData) => ({
        ...prevFormData,
        oboId: usr_log[0].USR_EMP_ID, // Set oboId from usr_log
        obomail: usr_log[0].ADD_EMAIL, // Set oboId from usr_log
      }));
    }
  }, [usr_log, formData.oboId, formData.obomail]); // Add oboId to the dependency array

  console.log(afltdata);
  // console.log(usrdata);
  console.log(formData);
  return (
    <div className="wrapper_form">
      <div className="title">Leave Application Form</div>
      <form method="Post" onSubmit={handleSubmit}>
        <div className="form-container">
          <hr className="hr_ttl" />
          <p
            style={{
              fontSize: "25px",
              paddingTop: "5px",
              color: "black",
            }}
          >
            Employee information
          </p>
          <hr className="hr_dt" style={{ marginBottom: "19px" }} />

          <div className="row">
            <label className="mg_l"> Today's Date :</label>
            <div className="column">
              <div className="date-display">
                <label
                  className="drp_mg"
                  style={{ border: "1px black solid;" }}
                >
                  <b>{formattedDate}</b>
                </label>
              </div>
            </div>
            <label>OBO ID</label>
            <div className="column">
              <div className="field">
                <b>{usr_log[0].USR_EMP_ID}</b>
              </div>
            </div>

            <label htmlFor="leaveStatus">Leave Status :</label>
            <div className="column">
              <div className="field">
                {formData.leaveStatus && ( // Using formData to display leaveStatus
                  <b style={{ paddingTop: ".1px" }}>{formData.leaveStatus}</b>
                )}
              </div>
            </div>
          </div>
          <div className="row">
            <div className="column drp_ln_hg">
              <label className="drp_mg">Employee ID :</label>
              <div className="field">
                <select
                  className="drp1"
                  value={formData.employeeId}
                  onChange={handleChange1}
                  name="employeeId"
                >
                  <option value="">Select Employee ID</option>
                  {data.result3.map((type) => (
                    <option key={type.USR_NTT_ID} value={type.USR_EMP_ID}>
                      {type.USR_EMP_ID} / {type.USR_DISPLAY_NAME}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="column drp_ln_hg">
              <label className="drp_mg">Approval ID :</label>
              <div className="field ">
                <b style={{ marginLeft: "22%" }}>{formData.approvalId}</b>
              </div>
            </div>
            <div className="column drp_ln_hg">
              <label className="drp_mg">
                Leave Type{" "}
                {errors.leaveType && (
                  <span className="error-message">{errors.leaveType}</span>
                )}{" "}
              </label>
              <div className="field">
                <span>
                  <i className="fas fa-unlock"></i>
                </span>
                <select
                  className="drp1"
                  value={selectedLeaveType}
                  onChange={handleLeaveTypeChange}
                  name="leaveType"
                >
                  <option value="">Select Leave Type</option>

                  {data.result.map((type) => (
                    <option key={type.lve_typ_cd} value={type.lve_typ_cd}>
                      {type.disp_nm}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="column drp_ln_hg">
              <label>Leave Balance: </label>
              <div className="field ">
                {/* Display the leave balance if selected, else show a placeholder */}
                <b style={{ marginLeft: "8%" }}>
                  {leaveBalance !== null
                    ? leaveBalance
                    : "Select a leave type to see balance"}
                </b>
              </div>
            </div>
          </div>
          <div className="row">
            <div className="column drp_ln_hg">
              <label className="drp_mg">
                CC ID :{" "}
                {errors.cc_id && (
                  <span className="error-message">{errors.cc_id}</span>
                )}{" "}
              </label>
              <div className="field">
                <select
                  className="drp1"
                  name="cc_id"
                  value={formData.cc_id}
                  onChange={handleChange}
                >
                  <option value="">Select CC ID</option>
                  {data.resul1.map((type) => (
                    <option key={type.USR_NTT_ID} value={type.USR_EMP_ID}>
                      {type.USR_EMP_ID} / {type.USR_DISPLAY_NAME}
                    </option>
                  ))}
                </select>
              </div>
            </div>

            <div className="column drp_ln_hg">
              <label className="drp_mg">
                Leave From :{" "}
                {errors.lve_from && (
                  <span className="error-message">{errors.lve_from}</span>
                )}{" "}
              </label>
              <div className="field">
                <input
                  type="date"
                  name="lve_from"
                  value={formData.lve_from}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="column drp_ln_hg">
              <label className="drp_mg">
                Leave To :{" "}
                {errors.lve_to && (
                  <span className="error-message">{errors.lve_to}</span>
                )}{" "}
              </label>
              <div className="field">
                <input
                  type="date"
                  name="lve_to"
                  value={formData.lve_to}
                  onChange={handleChange}
                />
              </div>
            </div>
          </div>

          <hr className="hr_ttl" />
          <p
            style={{
              fontSize: "25px",
              paddingTop: "5px",
              color: "black",
            }}
          >
            Half Day Leave Information
          </p>
          <hr className="hr_dt" />

          <div className="row">
            <div
              className="column "
              style={{ lineHeight: "12px", marginTop: "5px" }}
            >
              <label style={{ marginLeft: "0%" }}> Half Day ?</label>
              <div style={{ marginLeft: "5%" }}>
                <FormControl>
                  <RadioGroup
                    row
                    name="half_day"
                    value={formData.half_day}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>

            <div
              className="column "
              style={{ lineHeight: "12px", marginTop: "5px" }}
            >
              <label style={{ marginLeft: "0%" }}>Which Half ?</label>
              <div style={{ marginLeft: "5%" }}>
                <FormControl>
                  <RadioGroup
                    row
                    name="which_half"
                    value={formData.which_half}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="first"
                      control={<Radio />}
                      label="First Half"
                    />
                    <FormControlLabel
                      value="second"
                      control={<Radio />}
                      label="Second Half"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>

            <div
              className="column "
              style={{ lineHeight: "12px", marginTop: "5px" }}
            >
              <label style={{ marginLeft: "0%" }}>Conjugative Half Day ?</label>
              <div style={{ marginLeft: "5%" }}>
                <FormControl>
                  <RadioGroup
                    row
                    name="conjugative_half_day"
                    value={formData.conjugative_half_day}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="first_day"
                      control={<Radio />}
                      label="First Day"
                    />
                    <FormControlLabel
                      value="last_day"
                      control={<Radio />}
                      label="Last Day"
                    />
                    <FormControlLabel
                      value="both_day"
                      control={<Radio />}
                      label="Both"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
          </div>

          <div className="row">
            <div
              className="column "
              style={{ lineHeight: "12px", marginTop: "5px" }}
            >
              <label style={{ marginLeft: "0%" }}>Daily Half Day ?</label>
              <div style={{ marginLeft: "5%" }}>
                <FormControl>
                  <RadioGroup
                    row
                    name="daily_half_day"
                    value={formData.daily_half_day}
                    onChange={handleChange}
                  >
                    <FormControlLabel
                      value="yes"
                      control={<Radio />}
                      label="Yes"
                    />
                    <FormControlLabel
                      value="no"
                      control={<Radio />}
                      label="No"
                    />
                  </RadioGroup>
                </FormControl>
              </div>
            </div>
            <div className="column drp_ln_hg">
              <label className="drp_mg">Half Day From :</label>
              <div className="field">
                <input
                  type="date"
                  name="half_day_from"
                  value={formData.half_day_from}
                  onChange={handleChange}
                />
              </div>
            </div>

            <div className="column drp_ln_hg">
              <label className="drp_mg">Half Day To :</label>
              <div className="field">
                <input
                  type="date"
                  name="half_day_to"
                  value={formData.half_day_to}
                  onChange={handleChange}
                />
              </div>
            </div>
            <div className="column drp_ln_hg">
              <label className="">Leave Duration:</label>
              <div className="date-display">
                <label className="drp_mg">
                  <b>{leaveDuration} day(s)</b>
                </label>
                {/* Display calculated leave duration */}
              </div>
            </div>
          </div>

          <div className="row">
            <div className="column drp_ln_hg">
              <label style={{ marginLeft: "1%" }}>Leave Reason :</label>
              <div className="field">
                <textarea
                  style={{ width: "100%" }}
                  placeholder="enter leave reason"
                  name="leaveReason"
                  value={formData.leaveReason || ""} // Ensure it's controlled with a value
                  onChange={handleChange} // Use the handleChange function
                />
              </div>
              {/* {errors.usr_typ && <span className="error-message">{errors.usr_typ}</span>} */}
            </div>
          </div>

          <hr className="hr_ttl" style={{ marginTop: "2%" }} />
          <p
            style={{
              fontSize: "25px",
              paddingTop: "5px",
              color: "black",
            }}
          >
            Address Details
          </p>
          <hr className="hr_dt" />

          <div className="row" style={{ marginTop: ".5%" }}>
            <div className="column drp_ln_hg">
              <label className="drp_mg">Employee Address:</label>
              <div className="field">
                <textarea
                  className="emp_add_text"
                  id="address"
                  name="address"
                  value={formData.address} // Bind to formData.address
                  readOnly
                />
              </div>
            </div>

            <div className="column drp_ln_hg">
              <label className="drp_mg">Employee Phone:</label>
              <div className="field">
                <input
                  className="emp_add_text"
                  type="text"
                  id="mobileno"
                  name="mobileno"
                  value={formData.mobileno || ""} // Corrected to use formData
                  readOnly
                />
              </div>
            </div>

            <div className="column drp_ln_hg">
              <label className="drp_mg">Employee CellPhone:</label>
              <div className="field">
                <input
                  className="emp_add_text"
                  type="text"
                  id="cellphno"
                  name="cellphno"
                  value={formData.cellphno || ""} // Ensure it references formData
                  readOnly
                />
              </div>
            </div>
          </div>

          <div className="row">
            <div className="column"></div>
            <div className="column"></div>
            <div className="column"></div>
            <div className="column"></div>

            <div className="column">
              <div className="field">
                <button
                  type="submit"
                  className="btn-action action-button"
                  onClick={submit_app}
                >
                  Submit
                </button>
              </div>
            </div>
          </div>
        </div>
      </form>
    </div>
  );
}

import React, { useState, useEffect } from "react";
import services from "./apiServices";
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Grid,
} from "@mui/material";
import moment from 'moment';

function Adm_Agine_Report_of_Lve() {
    const [data, Setdata] = useState({ adm_agine_mng_rpt_res: [] });
    const [managerWiseEmployees, setManagerWiseEmployees] = useState([]);

    useEffect(() => {
        const adm_agine_rpt = async () => {
            try {
                const res = await services.get_adm_agine_rpt();
                if (res) {
                    Setdata(res);
                } else {
                    console.error("Unexpected data format:", res);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        adm_agine_rpt();
    }, []);

    const processManagerWiseData = (data) => {
        const managerMap = {};
        data.forEach((employee) => {
            const managerId = employee.USR_APRVL_ID;
            if (!managerMap[managerId]) {
                managerMap[managerId] = {
                    managerName: employee.Manager,
                    employees: [],
                };
            }
            managerMap[managerId].employees.push({
                name: employee.USR_DISPLAY_NAME,
                empId: employee.USR_NTT_ID,
                appDate: employee.APP_DT,
                leaveType: employee.APP_TYP,
                lessThan30Days: employee.Less_Than_30_Days,
                days31To60: employee.Days_31_To_60,
                days61To90: employee.Days_61_To_90,
                moreThan90Days: employee.More_Than_90_Days,
            });
        });
        return Object.values(managerMap);
    };

    useEffect(() => {
        if (data.adm_agine_mng_rpt_res.length > 0) {
            const managerWiseEmployees = processManagerWiseData(data.adm_agine_mng_rpt_res);
            setManagerWiseEmployees(managerWiseEmployees);
        }
    }, [data.adm_agine_mng_rpt_res]);

    const calculateTotal = (managerWiseEmployees) => {
        let totalEmployees = 0;
        let totalLessThan30 = 0;
        let total31To60 = 0;
        let total61To90 = 0;
        let totalMoreThan90 = 0;

        managerWiseEmployees.forEach((manager) => {
            totalEmployees += manager.employees.length;
            manager.employees.forEach((emp) => {
                totalLessThan30 += emp.lessThan30Days || 0;
                total31To60 += emp.days31To60 || 0;
                total61To90 += emp.days61To90 || 0;
                totalMoreThan90 += emp.moreThan90Days || 0;
            });
        });

        return {
            totalEmployees,
            totalLessThan30,
            total31To60,
            total61To90,
            totalMoreThan90,
        };
    };

    const totals = calculateTotal(managerWiseEmployees);

    function formatDate(dateString) {
        return moment(dateString).format('DD/MMM/YYYY').replace(/\/([a-z])/g, (match) => '/' + match[1].toUpperCase());
    }

    return (
        <div className="wrapper_form">
            <Grid item xs={12} style={{ padding: "1%" }}>
                <div className="title" style={{ marginTop: "2%", textAlign: "center", fontSize: "1.5rem" }}>
                    Manager Wise Agine Analysis
                </div>
                <TableContainer
                    component={Paper}
                    style={{
                        margin: "5px auto 25px auto",
                        boxShadow: "0px 6px 16px 3px #bababa",
                        borderRadius: "20px",
                        border: "2px solid gray",
                        maxHeight: "500px",
                        overflowY: "auto",
                    }}
                >
                    <Table style={{ width: "100%" }}>
                        <TableHead>
                            <TableRow>
                                <TableCell style={headerCellStyle}>Sr. No</TableCell>
                                <TableCell style={headerCellStyle}>Manager</TableCell>
                                <TableCell style={headerCellStyle}>Employee Name</TableCell>
                                <TableCell style={headerCellStyle}>Application Date</TableCell>
                                <TableCell style={headerCellStyle}>Leave Type</TableCell>
                                <TableCell style={headerCellStyle}>Upto 30 Days</TableCell>
                                <TableCell style={headerCellStyle}>31 To 60 Days</TableCell>
                                <TableCell style={headerCellStyle}>61 To 90 Days</TableCell>
                                <TableCell style={headerCellStyle}>More than 90 Days</TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {managerWiseEmployees.map((manager, managerIndex) => {
                                const employees = manager.employees.length > 0 ? manager.employees : [{ name: "No employees", appDate: "", leaveType: "", lessThan30Days: "", days31To60: "", days61To90: "", moreThan90Days: "" }];
                                return employees.map((emp, empIndex) => (
                                    <TableRow key={emp.empId || managerIndex}>
                                        {empIndex === 0 && (
                                            <TableCell rowSpan={employees.length} align="center" style={cellStyle}>
                                                {managerIndex + 1}
                                            </TableCell>
                                        )}
                                        {empIndex === 0 && (
                                            <TableCell rowSpan={employees.length} align="center" style={cellStyle}>
                                                {manager.managerName}
                                            </TableCell>
                                        )}
                                        <TableCell align="center" style={cellStyle}>
                                            {emp.name}
                                        </TableCell>
                                        <TableCell align="center" style={cellStyle}>
                                            {formatDate(emp.appDate)}
                                        </TableCell>
                                        <TableCell align="center" style={cellStyle}>
                                            {emp.leaveType}
                                        </TableCell>
                                        <TableCell align="center" style={cellStyle}>
                                            {emp.lessThan30Days}
                                        </TableCell>
                                        <TableCell align="center" style={cellStyle}>
                                            {emp.days31To60}
                                        </TableCell>
                                        <TableCell align="center" style={cellStyle}>
                                            {emp.days61To90}
                                        </TableCell>
                                        <TableCell align="center" style={cellStyle}>
                                            {emp.moreThan90Days}
                                        </TableCell>
                                    </TableRow>
                                ));
                            })}
                            <TableRow>
                                <TableCell colSpan={4} align="center" style={{ ...cellStyle1, fontWeight: "bold" }}>
                                    Total
                                </TableCell>
                                <TableCell align="center" style={cellStyle1}>
                                    {totals.totalEmployees}
                                </TableCell>
                                <TableCell align="center" style={cellStyle1}>
                                    {totals.totalLessThan30}
                                </TableCell>
                                <TableCell align="center" style={cellStyle1}>
                                    {totals.total31To60}
                                </TableCell>
                                <TableCell align="center" style={cellStyle1}>
                                    {totals.total61To90}
                                </TableCell>
                                <TableCell align="center" style={cellStyle1}>
                                    {totals.totalMoreThan90}
                                </TableCell>
                            </TableRow>
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </div>
    );
}

const headerCellStyle = {
    fontSize: "0.8rem",
    padding: "1px",
    backgroundColor: "#4a6d8c",
    color: "white",
    textAlign: "center",
    border: "1px solid gray",
};

const cellStyle = {
    fontSize: "0.8rem",
    padding: "2px",
    border: "1px solid gray",

};
const cellStyle1 = {
    fontSize: "0.8rem",
    padding: "1px",
    border: "1px solid gray",
    fontWeight: "bold"
};
export default Adm_Agine_Report_of_Lve;
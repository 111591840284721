import React, { useState, useEffect } from "react";
import services from "./apiServices";
import VisibilityIcon from "@mui/icons-material/Visibility";
import Box from "@mui/material/Box";
import AddIcon from "@mui/icons-material/Add";
import EditIcon from "@mui/icons-material/Edit";
import DeleteIcon from "@mui/icons-material/Delete";
import CancelIcon from '@mui/icons-material/Cancel';
import CheckIcon from '@mui/icons-material/Check';
import {
    Table,
    TableBody,
    TableCell,
    TableContainer,
    TableHead,
    TableRow,
    Paper,
    Button,
    Grid,
    Typography,
    Dialog,
    DialogTitle,
    DialogContent,
    DialogActions,
    IconButton,
    TextField
} from "@mui/material";
import CloseIcon from '@mui/icons-material/Close';
import moment from 'moment';

export default function Adm_Rpt_Today_Lev_Emp() {

    const [emplist, Setemplist] = useState({ emp_on_lev_today_result: [] })
    console.log("emplist", emplist)

    useEffect(() => {
        const emp_lve_on_today = async () => {
            try {
                const emp_lve_on_today_list = await services.get_emp_lve_on_today();

                if (emp_lve_on_today_list) {
                    Setemplist(emp_lve_on_today_list); // Wrap it in an object if necessary
                } else {
                    console.error("Unexpected data format:", emp_lve_on_today_list);
                }
            } catch (error) {
                console.error("Error fetching data:", error);
            }
        };
        emp_lve_on_today();

    }, [])

    const formatDate1 = (isoDateString) => {
        // Check for null or empty strings
        if (!isoDateString || typeof isoDateString !== 'string' || isoDateString.trim() === "") {
            return "N/A"; // Return "N/A" for null or empty strings
        }

        // Create a Date object from the ISO string
        const date = new Date(isoDateString);

        // Check if the date is valid
        if (isNaN(date.getTime())) {
            console.warn(`Invalid date format: ${isoDateString}`);
            return "Invalid Date"; // Return "Invalid Date" for unexpected formats
        }

        // Define an array for month names (abbreviated)
        const monthNames = ["Jan", "Feb", "Mar", "Apr", "May", "Jun", "Jul", "Aug", "Sep", "Oct", "Nov", "Dec"];

        // Get day, month, and year
        const day = date.getUTCDate(); // Use UTC date
        const monthIndex = date.getUTCMonth(); // Use UTC month
        const year = date.getUTCFullYear(); // Use UTC year

        // Format the date as 'DD MMM YYYY'
        return `${day}/${monthNames[monthIndex]}/${year}`;
    };

    return (
        <div className='wrapper_form'>
            <Grid item xs={6} style={{ paddingLeft: "1%", paddingRight: "1%" }}>
                <div className="title" style={{ marginTop: "1%" }}> Employee List who are on Leave Today</div>
                <TableContainer
                    component={Paper}
                    style={{
                        margin: "5px auto 25px auto",
                        // maxWidth: "50%",
                        overflow: "hidden", // Prevents content overflow
                        boxShadow: "0px 6px 16px 3px #bababa", // Shadow only on the top and bottom
                        borderRadius: "20px", // Rounded corners for the container
                        border: "2px solid gray",

                    }}
                >
                    <Table style={{ width: "100%", }}>
                        <TableHead>
                            <TableRow style={{ width: "100%", }}>
                                <TableCell
                                    style={{
                                        fontSize: "0.8rem",
                                        padding: "1px 18px",
                                        backgroundColor: "#4a6d8c",
                                        border: "1px solid gray", color: "white", textAlign: "center"
                                    }}
                                    className=" txt_ttl1"
                                >
                                    Sr.No.
                                </TableCell>
                                <TableCell
                                    style={{
                                        fontSize: "0.8rem",
                                        padding: "1px 18px",
                                        backgroundColor: "#4a6d8c",
                                        border: "1px solid gray", color: "white", textAlign: "center"
                                    }}
                                    className=" txt_ttl1"
                                >
                                    Name
                                </TableCell>
                                <TableCell
                                    style={{
                                        fontSize: "0.8rem",
                                        padding: "1px 18px",
                                        backgroundColor: "#4a6d8c",
                                        border: "1px solid gray", color: "white", textAlign: "center"
                                    }}
                                    className=" txt_ttl1"
                                >
                                    EMP ID
                                </TableCell>
                                <TableCell
                                    style={{
                                        fontSize: "0.8rem",
                                        padding: "1px 18px",
                                        backgroundColor: "#4a6d8c",
                                        border: "1px solid gray", color: "white", textAlign: "center"
                                    }}
                                    className=" txt_ttl1"
                                >
                                    From
                                </TableCell>
                                <TableCell
                                    style={{
                                        fontSize: "0.8rem",
                                        padding: "1px",
                                        backgroundColor: "#4a6d8c",
                                        border: "1px solid gray", color: "white", textAlign: "center"
                                    }}
                                    className=" txt_ttl1"
                                >
                                    To
                                </TableCell>
                                <TableCell
                                    style={{
                                        fontSize: "0.8rem",
                                        padding: "1px",
                                        backgroundColor: "#4a6d8c",
                                        border: "1px solid gray", color: "white", textAlign: "center"
                                    }}
                                    className=" txt_ttl1"
                                >
                                    Duration
                                </TableCell>
                                <TableCell
                                    style={{
                                        fontSize: "0.8rem",
                                        padding: "1px",
                                        backgroundColor: "#4a6d8c",
                                        border: "1px solid gray", color: "white", textAlign: "center"
                                    }}
                                    className=" txt_ttl1"
                                >
                                    Status
                                </TableCell>
                            </TableRow>
                        </TableHead>
                        <TableBody>
                            {emplist.emp_on_lev_today_result.map((leave, index) => (
                                <TableRow style={{ height: "15px" }} key={leave.leaveTypeId}>
                                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                        {index + 1}
                                    </TableCell>
                                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                        {leave.USR_DISPLAY_NAME}
                                    </TableCell>
                                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                        {leave.APP_EMP_ID}
                                    </TableCell>
                                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                        {formatDate1(leave.APP_LVE_FRM_DT)}
                                    </TableCell>
                                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                        {formatDate1(leave.APP_LVE_TO_DT)}
                                    </TableCell>
                                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                        {leave.APP_LVE_DES} days
                                    </TableCell>
                                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                        {leave.APP_LVE_STTS}
                                    </TableCell>
                                </TableRow>
                            ))}
                            {emplist.emp_on_lev_today_result.length < 10 &&
                                Array.from({ length: 10 - emplist.emp_on_lev_today_result.length }).map((_, index) => (
                                    <TableRow style={{ height: "5px" }} key={`empty-row-${index}`}>
                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray", lineHeight: ".2" }}>
                                            &nbsp; {/* Empty cell for spacing */}
                                        </TableCell>
                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray", lineHeight: ".2" }}>
                                            &nbsp; {/* Empty cell for spacing */}
                                        </TableCell>
                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                            &nbsp; {/* Empty cell for spacing */}
                                        </TableCell>
                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>
                                            &nbsp; {/* Empty cell for spacing */}
                                        </TableCell>
                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                            &nbsp; {/* Empty cell for spacing */}
                                        </TableCell>
                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                            &nbsp; {/* Empty cell for spacing */}
                                        </TableCell>
                                        <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px", border: "1px solid gray" }}>
                                            &nbsp; {/* Empty cell for spacing */}
                                        </TableCell>
                                    </TableRow>
                                ))}
                        </TableBody>
                    </Table>
                </TableContainer>
            </Grid>
        </div>
    )
}










import { Link, useLocation } from "react-router-dom";
import React, { useEffect, useState } from "react";
import services from "./apiServices";
import "../css/Navbar.css"; // Ensure you have the CSS in this file
import { logout } from "./Logout";

export default function Navbar(props) {

  const sessionDuration = 30 * 60 * 1000; // 30 minutes in milliseconds
  const [remainingTime, setRemainingTime] = useState(sessionDuration / 1000);
  const location = useLocation(); // Get the current location
  const [logoutSuccess, setLogoutSuccess] = useState(false);

  const resetTimer = () => {
    setRemainingTime(sessionDuration / 1000); // Reset remaining time to 30 minutes
  };

  // Determine the active tab based on the current location
  const getActiveTab = () => {
    switch (location.pathname) {
      case "/":
        return "dashboard";

      case "/emp_lev_app":

      case "/obo_emp_lev_app":
        return "application";

      case "/usr_profile":
        return "profile";

      case "/cust_rep_lve_bal_consume":
      case "/cust_rep_lve_apl_print":
      case "/cust_rep_lve_apl_print":
      case "/manager_rpt_team_mem_list":
      case "/manager_agine_rpt_of_lve":
      case "/manager_rpt_lve_apl_print":
      case "/manager_rpt_team_mem_le_bal_consm":
        return "reports";

      case "/adm_add_usr":
        return "admin";

      case "/log_out":
        return "logout"

      case "/cust_lve_policy":
      case "/cust_holliday_calendar":
      case "/adm_holiday_calendar":
      case "/adm_add_holiday":
        return "lve_policy"

      default:
        return "";
    }
  };
  const [activeTab, setActiveTab] = useState(getActiveTab());


  useEffect(() => {
    setActiveTab(getActiveTab()); // Update active tab on location change
  }, [location]);

  useEffect(() => {
    const intervalId = setInterval(() => {
      setRemainingTime(prevTime => {
        if (prevTime <= 0) {
          clearInterval(intervalId);
          handleLogout(); // Log out when time reaches 0
          return 0;
        }
        return prevTime - 1; // Decrement time
      });
    }, 1000); // Update every second

    // Add event listeners to reset timer on user activity
    window.addEventListener('mousemove', resetTimer);
    window.addEventListener('keypress', resetTimer);
    window.addEventListener('click', resetTimer);

    return () => {
      clearInterval(intervalId); // Cleanup on unmount
      window.removeEventListener('mousemove', resetTimer);
      window.removeEventListener('keypress', resetTimer);
      window.removeEventListener('click', resetTimer);
    };
  }, []);

  const formatTime = (seconds) => {
    const minutes = Math.floor(seconds / 60);
    const secs = seconds % 60;
    return `${String(minutes).padStart(2, "0")}:${String(secs).padStart(2, "0")}`;
  };

  const handleLogout = async () => {
    try {
      await logout(); // Call the logout API
      sessionStorage.removeItem('sessionStartTime'); // Clear session start time
      setLogoutSuccess(true);
      setTimeout(() => {
        setLogoutSuccess(false);
        window.location.href = '/'; // Redirect to homepage or login page
      }, 100); // Delay of 1 second
    } catch (error) {
      console.error('Logout failed:', error.message);
    }
  };

  return props.nav == "O" ? (
    <>
      <header class="fixedheader pd1 ">
        <div class="container_12">
          <div class="grid_12">
            <div class="wrapper">
              {" "}
              <Link to="index.html" class="logo">
                design.maniacs
              </Link>
              <div class="nav">
                <div className={`subnav ${activeTab === "dashboard" ? "active" : ""}`} style={{ marginTop: "1px" }}>
                  <button class="subnavbtn">
                    Dashboard<i class="fa fa-caret-down"></i>
                  </button>
                </div>
                <div class="subnav">
                  <button class="subnavbtn">
                    Client Configuration<i class="fa fa-caret-down"></i>
                  </button>
                  <div class="subnav-content">
                    <a href="#company">Client Creation</a>
                    <a href="#team">System Configuration</a>
                    <a href="#team">User Creation(obo)</a>
                    <a href="#team">Leave Status</a>
                  </div>
                </div>
                <div className={`subnav ${activeTab === "profile" ? "active" : ""}`}>
                  <button class="subnavbtn">
                    Profile <i class="fa fa-caret-down"></i>
                  </button>
                  <div class="subnav-content">
                    <Link to="/usr_profile" onClick={() => { setActiveTab("profile") }} >User Profile</Link>
                    <Link to="/change_pass" onClick={() => { setActiveTab("profile") }}>Change Password</Link>
                  </div>
                </div>
                <div class="subnav">
                  <button class="subnavbtn">
                    Admin<i class="fa fa-caret-down"></i>
                  </button>
                  <div class="subnav-content">
                    <a href="#link1">Leave Balance</a>
                    <a href="#link2">Agine Analyasis</a>
                    <a href="#link3">Leave Consumption</a>
                    <a href="#link4">Team Member List</a>
                    <a href="#link2">Team Leave Balance</a>
                    <a href="#link3">Team Leave Consumption</a>
                    <a href="#link4">Application Print</a>
                  </div>
                </div>
                <div class="subnav">
                  <button class="subnavbtn">
                    Reports<i class="fa fa-caret-down"></i>
                  </button>
                  <div class="subnav-content">
                    <a href="#link1">Leave Policy</a>
                    <a href="#link2">Holiday Calendar</a>
                  </div>
                </div>
                <div className={`subnav ${activeTab === "logout" ? "active" : ""}`} style={{ marginTop: "1px" }}>
                  <Link to="/log_out" className="subnavbtn" onClick={handleLogout}>
                    Logout
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row wrapper pd1" style={{ marginBottom: ".1%" }}>
          <div className="column " style={{ marginLeft: "2%" }}>
            <b>User: {props.nav1}</b>
          </div>
          <div className="column" style={{ textAlign: "end", marginRight: '2%' }}>
            <b>
              Session Time: {formatTime(remainingTime)}
            </b>
          </div>
        </div>
      </header>
    </>
  ) : props.nav == "C" ? (
    props.post === "MNGR" ? (
      <>
        <header class="fixedheader pd1 ">
          <div class="container_12">
            <div class="grid_12">
              <div class="wrapper">
                {" "}
                <Link to="index.html" class="logo">
                  Sutti
                </Link>
                <div class="nav">
                  <div className={`subnav ${activeTab === "dashboard" ? "active" : ""}`} style={{ marginTop: "1px" }}>
                    <Link to="/" className="subnavbtn" onClick={() => setActiveTab("dashboard")}>
                      Dashboard
                    </Link>
                  </div>
                  <div class="subnav">
                    <button class="subnavbtn">
                      Application<i class="fa fa-caret-down"></i>
                    </button>
                    <div class="subnav-content">
                      <Link to="/emp_lev_app">Application</Link>
                      <Link to="/obo_emp_lev_app">Application OBO</Link>
                    </div>
                  </div>
                  <div className={`subnav ${activeTab === "profile" ? "active" : ""}`}>
                    <button class="subnavbtn">
                      Profile <i class="fa fa-caret-down"></i>
                    </button>
                    <div class="subnav-content">
                      <Link to="/usr_profile" onClick={() => { setActiveTab("profile") }} >User Profile</Link>
                      <Link to="/change_pass" onClick={() => { setActiveTab("profile") }}>Change Password</Link>
                    </div>
                  </div>

                  <div className={`subnav ${activeTab === "reports" ? "active" : ""}`}>
                    <button className="subnavbtn">
                      Reports<i className="fa fa-caret-down"></i>
                    </button>

                    <div class="subnav-content">
                      <Link to="/cust_rep_lve_bal_consume" onClick={() => setActiveTab("reports")}>Leave Bal. and Consume</Link>
                      <Link to="/manager_agine_rpt_of_lve" onClick={() => setActiveTab("reports")}>Aging Analyasis</Link>
                      <Link to="/manager_rpt_team_mem_list" onClick={() => setActiveTab("reports")}>Team members</Link>
                      <Link to="/manager_rpt_team_mem_le_bal_consm" onClick={() => setActiveTab("reports")} >Team members Leave Bal. & Consume</Link>
                      <Link to="/manager_rpt_lve_apl_print" onClick={() => setActiveTab("reports")}>Applications Print </Link>
                    </div>
                  </div>
                  <div className={`subnav ${activeTab === "lve_policy" ? "active" : ""}`}>
                    <button className="subnavbtn">
                      Policy<i className="fa fa-caret-down"></i>
                    </button>
                    <div class="subnav-content">
                      <Link to="/cust_lve_policy" onClick={() => setActiveTab("lve_policy")}>Leave Policy</Link>
                      <Link to="/cust_holliday_calendar" onClick={() => setActiveTab("lve_policy")}>Holiday Calendar</Link>
                    </div>
                  </div>

                  <div className={`subnav ${activeTab === "logout" ? "active" : ""}`} style={{ marginTop: "1px" }}>
                    <Link to="/log_out" className="subnavbtn" onClick={handleLogout}>
                      Logout
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div>
          <div class="row wrapper pd1" style={{ marginBottom: ".1%" }}>
            <div className="column " style={{ marginLeft: "2%" }}>
              <b>User: {props.nav1}</b>
            </div>
            <div className="column" style={{ textAlign: "end", marginRight: '2%' }}>
              <b>
                Session Time: {formatTime(remainingTime)}
              </b>
            </div>
          </div>
        </header>
      </>
    ) : (
      <>
        <header class="fixedheader pd1 ">
          <div class="container_12">
            <div class="grid_12">
              <div class="wrapper">
                {" "}
                <Link to="index.html" class="logo">
                  Sutti
                </Link>
                <div className="nav">
                  <div className={`subnav ${activeTab === "dashboard" ? "active" : ""}`} style={{ marginTop: "1px" }}>
                    <Link to="/" className="subnavbtn" onClick={() => setActiveTab("dashboard")}>
                      Dashboard
                    </Link>
                  </div>
                  <div className={`subnav ${activeTab === "application" ? "active" : ""}`}>
                    <button className="subnavbtn">
                      Application<i className="fa fa-caret-down"></i>
                    </button>
                    <div className="subnav-content">
                      <Link to="/emp_lev_app" onClick={() => setActiveTab("application")}>Application</Link>
                      <Link to="/obo_emp_lev_app" onClick={() => setActiveTab("application")}>Application OBO</Link>
                    </div>
                  </div>
                  <div className={`subnav ${activeTab === "profile" ? "active" : ""}`}>
                    <button class="subnavbtn">
                      Profile <i class="fa fa-caret-down"></i>
                    </button>
                    <div class="subnav-content">
                      <Link to="/usr_profile" onClick={() => { setActiveTab("profile") }} >User Profile</Link>
                      <Link to="/change_pass" onClick={() => { setActiveTab("profile") }}>Change Password</Link>
                    </div>
                  </div>
                  <div className={`subnav ${activeTab === "reports" ? "active" : ""}`}>
                    <button className="subnavbtn">
                      Reports<i className="fa fa-caret-down"></i>
                    </button>
                    <div className="subnav-content">
                      <Link to="/cust_rep_lve_bal_consume" onClick={() => setActiveTab("reports")}>Leave Balance and Consumption</Link>
                      <Link to="/cust_rep_lve_apl_print" onClick={() => setActiveTab("reports")}>Applications Print</Link>
                    </div>
                  </div>
                  <div className={`subnav ${activeTab === "lve_policy" ? "active" : ""}`}>
                    <button className="subnavbtn">
                      Policy<i className="fa fa-caret-down"></i>
                    </button>
                    <div class="subnav-content">
                      <Link to="/cust_lve_policy" onClick={() => setActiveTab("lve_policy")}>Leave Policy</Link>
                      <Link to="/cust_holliday_calendar" onClick={() => setActiveTab("lve_policy")}>Holiday Calendar</Link>
                    </div>
                  </div>
                  <div className={`subnav ${activeTab === "logout" ? "active" : ""}`} style={{ marginTop: "1px" }}>
                    <Link to="/log_out" className="subnavbtn" onClick={handleLogout}>
                      Logout
                    </Link>
                  </div>
                </div>
              </div>
            </div>
          </div >
          <div class="row wrapper pd1" style={{ marginBottom: ".1%" }}>
            <div className="column " style={{ marginLeft: "2%" }}>
              <b>User: {props.nav1}</b>
            </div>
            <div className="column" style={{ textAlign: "end", marginRight: '2%' }}>
              <b>
                Session Time: {formatTime(remainingTime)}
              </b>
            </div>
          </div>
        </header >
      </>
    )
  ) : props.nav == "Adm" ? (
    <>
      <header class="fixedheader pd1 ">
        <div class="container_12">
          <div class="grid_12">
            <div class="wrapper">
              {" "}
              <Link to="index.html" class="logo">
                Sutti
              </Link>
              <div class="nav">
                <div className={`subnav ${activeTab === "dashboard" ? "active" : ""}`} style={{ marginTop: "1px" }}>
                  <Link to="/" class="subnavbtn">
                    Dashboard<i class="fa fa-caret-down"></i>
                  </Link>
                </div>
                <div className={`subnav ${activeTab === "application" ? "active" : ""}`} style={{ marginTop: "1px" }}>
                  <button class="subnavbtn">
                    Application<i class="fa fa-caret-down"></i>
                  </button>
                  <div class="subnav-content">
                    <Link to="/emp_lev_app" onClick={() => { setActiveTab("application") }}>Application</Link>
                    <Link to="/obo_emp_lev_app" onClick={() => { setActiveTab("application") }}>Application OBO</Link>
                  </div>
                </div>
                <div className={`subnav ${activeTab === "profile" ? "active" : ""}`}>
                  <button class="subnavbtn">
                    Profile <i class="fa fa-caret-down"></i>
                  </button>
                  <div class="subnav-content">
                    <Link to="/usr_profile" onClick={() => { setActiveTab("profile") }} >User Profile</Link>
                    <Link to="/change_pass" onClick={() => { setActiveTab("profile") }}>Change Password</Link>
                  </div>
                </div>

                <div className={`subnav ${activeTab === "admin" ? "active" : ""}`}>
                  <button class="subnavbtn">
                    Admin <i class="fa fa-caret-down"></i>
                  </button>
                  <div class="subnav-content">
                    <Link to="/adm_add_usr" onClick={() => setActiveTab("admin")}>Add User</Link>
                    <Link to="#deliver">Policy Managment</Link>
                    <Link to="#deliver">Post Managment</Link>
                    <Link to="#deliver">User Rights</Link>
                    <Link to="#deliver">Add/Edit Holliday Calendar</Link>
                    <Link to="#deliver">Leave Configuration</Link>
                    <Link to="#deliver">Add/Edit Annocements</Link>
                    <Link to="#deliver">Change/Reset Password</Link>
                    <Link to="#deliver">Month End Procedure</Link>
                    <Link to="#deliver">Year End Procedure</Link>
                  </div>
                </div>
                <div className={`subnav ${activeTab === "reports" ? "active" : ""}`}>
                  <button class="subnavbtn">
                    Reports<i class="fa fa-caret-down"></i>
                  </button>
                  <div class="subnav-content">
                    <Link to="/cust_rep_lve_bal_consume" onClick={() => setActiveTab("reports")}>Leave Bal. and Consume</Link>
                    <Link to="/adm_agine_rpt_of_lve" onClick={() => setActiveTab("reports")}>Agine Analyasis</Link>
                    <Link to="/manager_rpt_team_mem_list" >Team Members</Link>
                    <Link to="/manager_rpt_team_mem_le_bal_consm" onClick={() => setActiveTab("reports")} >Team members Leave Bal. and Consume</Link>
                    <Link to="/#" onClick={() => setActiveTab("reports")} >Leave types. Post, Status</Link>
                    <Link to="/adm_rpt_all_emp_lve_dtls" onClick={() => setActiveTab("reports")} >All Employees & Leave Details </Link>
                    <Link to="/adm_rpt_todays_lev_emp_list" onClick={() => setActiveTab("reports")} >Leave On Today Employees </Link>
                    <Link to="/manager_rpt_lve_apl_print" onClick={() => setActiveTab("reports")} >Application Print</Link>
                  </div>
                </div>
                <div className={`subnav ${activeTab === "lve_policy" ? "active" : ""}`}>
                  <button className="subnavbtn">
                    Policy<i className="fa fa-caret-down"></i>
                  </button>
                  <div class="subnav-content">
                    <Link to="/adm_lve_policy" onClick={() => setActiveTab("lve_policy")}>Leave Policy</Link>
                    <Link to="/adm_holiday_calendar" onClick={() => setActiveTab("lve_policy")}>Holiday Calendar</Link>
                    <Link to="/adm_add_holiday" onClick={() => setActiveTab("lve_policy")}>Holiday Management</Link>
                  </div>
                </div>
                <div className={`subnav ${activeTab === "logout" ? "active" : ""}`} style={{ marginTop: "1px" }}>
                  <Link to="/log_out" className="subnavbtn" onClick={handleLogout}>
                    Logout
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row wrapper pd1" style={{ marginBottom: ".1%" }}>
          <div className="column " style={{ marginLeft: "2%" }}>
            <b>User: {props.nav1}</b>
          </div>
          <div className="column" style={{ textAlign: "end", marginRight: '2%' }}>
            <b>
              Session Time: {formatTime(remainingTime)}
            </b>
          </div>
        </div>
      </header>
    </>
  ) : props.nav == "A" ? (
    <>
      <header class="fixedheader pd1 ">
        <div class="container_12">
          <div class="grid_12">
            <div class="wrapper">
              {" "}
              <Link to="index.html" class="logo">
                design.maniacs
              </Link>
              <div class="nav">
                <div className={`subnav ${activeTab === "dashboard" ? "active" : ""}`} style={{ marginTop: "1px" }}>
                  <button class="subnavbtn">
                    Dashboard Approvar<i class="fa fa-caret-down"></i>
                  </button>
                </div>
                <div className={`subnav ${activeTab === "application" ? "active" : ""}`} style={{ marginTop: "1px" }}>
                  <button class="subnavbtn">
                    Application<i class="fa fa-caret-down"></i>
                  </button>
                  <div class="subnav-content">
                    <Link to="/emp_lev_app" onClick={() => { setActiveTab("application") }}>Application</Link>
                    <Link to="/obo_emp_lev_app" onClick={() => { setActiveTab("application") }}>Application OBO</Link>
                  </div>
                </div>
                <div className={`subnav ${activeTab === "profile" ? "active" : ""}`}>
                  <button class="subnavbtn">
                    Profile <i class="fa fa-caret-down"></i>
                  </button>
                  <div class="subnav-content">
                    <Link to="/usr_profile" onClick={() => { setActiveTab("profile") }} >User Profile</Link>
                    <Link to="/change_pass" onClick={() => { setActiveTab("profile") }}>Change Password</Link>
                  </div>
                </div>
                <div class="subnav">
                  <button class="subnavbtn">
                    Reports<i class="fa fa-caret-down"></i>
                  </button>
                  <div class="subnav-content">
                    <a href="#link1">Leave Balance</a>
                    <a href="#link2">Agine Analyasis</a>
                    <a href="#link3">Leave Consumption</a>
                    <a href="#link4">Team Member List</a>
                    <a href="#link2">Team Leave Balance</a>
                    <a href="#link3">Team Leave Consumption</a>
                    <a href="#link4">Application Print</a>
                  </div>
                </div>
                <div className={`subnav ${activeTab === "lve_policy" ? "active" : ""}`}>
                  <button className="subnavbtn">
                    Policy<i className="fa fa-caret-down"></i>
                  </button>
                  <div class="subnav-content">
                    <Link to="/cust_lve_policy" onClick={() => setActiveTab("lve_policy")}>Leave Policy</Link>
                    <Link to="/cust_lve_policy" onClick={() => setActiveTab("lve_policy")}>Holiday Calendar</Link>
                  </div>
                </div>
                <div className={`subnav ${activeTab === "logout" ? "active" : ""}`} style={{ marginTop: "1px" }}>
                  <Link to="/log_out" className="subnavbtn" onClick={handleLogout}>
                    Logout
                  </Link>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div class="row wrapper pd1" style={{ marginBottom: ".1%" }}>
          <div className="column " style={{ marginLeft: "2%" }}>
            <b>User: {props.nav1}</b>
          </div>
          <div className="column" style={{ textAlign: "end", marginRight: '2%' }}>
            <b>
              Session Time: {formatTime(remainingTime)}
            </b>
          </div>
        </div>
      </header >
    </>
  ) : (
    ""
  );
}

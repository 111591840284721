import React, { useEffect, useState } from 'react';
import services from "./apiServices";
import FormLabel from '@mui/material/FormLabel';
import Checkbox from '@mui/material/Checkbox';
import { FormControl, FormControlLabel, Radio, RadioGroup, Button, Divider } from '@mui/material';
import { FormGroup, Typography } from '@mui/material';
export default function LveTypRegTab(props) {

  const [md, setMd] = useState([]);
  const [gender, setgender] = useState([]);

  const [Designation, setDesignation] = useState([]);
  const [checkh, setCheckh] = useState({
    check: [],
    checkName: []
  });
  const [data, setData] = useState({ result: [], result2: [] });
  const [clickIndex, setClickIndex] = useState(null);

  const [tabValue, setTabValue] = useState(0);
  const [formData, setFormData] = useState({});

  const [tabdata, settabdata] = useState([])

  const handleTabChange = (newValue) => {
    setTabValue(newValue);
  };

  const nextStep = () => {
    if (tabValue < 3) {
      setTabValue(tabValue + 1);
    }
  };

  const prevStep = () => {
    if (tabValue > 0) {
      setTabValue(tabValue - 1);
    }
  };

  useEffect(() => {
    const fd = async () => {
      const d = await services.getdropdowndata('APCBL_POST')
      // console.log(d)


      // console.log(d)
      setDesignation(d)
    }
    fd()
  }, [])

  useEffect(() => {
    const fetchData = async () => {
      try {
        const result = await services.getlvetypdata();
        // console.log('Fetched data:', result);

        if (result) {
          setData(result); // Wrap it in an object if necessary
        } else {
          console.error('Unexpected data format:', result);
        }
      } catch (error) {
        console.error('Error fetching data:', error);
      }
    };

    fetchData();
  }, []);

  useEffect(() => {
    if (data.result2.length > 0) {

      const inilentoblankarray = Array.from({ length: data.result2.length }, () => ({
        ques_code: "",
        lve_types: props.holidays.check,
        ans: []
      }))
      settabdata(inilentoblankarray)
    }

  }, [data.result2.length])

  // console.log(tabdata[3]?.lve_types ?? 'Index is out of range');
  // console.log(tabdata);

  useEffect(() => {
    setCheckh(props.holidays);
    setgender(props.gender)
    // Use a ref instead of document.querySelectorAll for React-specific DOM access
  }, [props.holidays, data, props.gender]);

  useEffect(() => {
    // console.log('clickIndex changed:', clickIndex);
  }, [clickIndex]);


  const [activeIndex, setActiveIndex] = useState(null);
  const handleToggle = (index, event) => {
    setActiveIndex(activeIndex === index ? null : index);

    if (!Array.isArray(tabdata)) {
      console.error('tabdata is not an array');
      return;
    }

    const newTabdata = [...tabdata];

    const updatedItem = { ...newTabdata[index] };

    updatedItem['ques_code'] = data.result2[index].ATTRIB_CD;

    newTabdata[index] = updatedItem;
    settabdata(newTabdata);

  };
  // Toggle visibility based on the index
  const handleToggle2 = (index, itemIndex, event) => {
    if (!Array.isArray(tabdata)) {
      console.error('tabdata is not an array');
      return;
    }

    const input_val = event.target.value;
    const newTabdata = [...tabdata];

    // Ensure the index is valid
    if (index < 0 || index >= newTabdata.length) {
      console.error('Index is out of bounds');
      return;
    }

    //
    // Copy the item at the specified index
    const updatedItem = { ...newTabdata[index] };

    // Ensure ans array is initialized
    if (!Array.isArray(updatedItem.ans)) {
      updatedItem.ans = [];
    }

    // Update the specific answer
    updatedItem.ans[itemIndex] = input_val;
    newTabdata[index] = updatedItem;

    // Update the state with the new array
    settabdata(newTabdata);

  };



  const [formValues, setFormValues] = useState(
    checkh.checkName.reduce((acc, item) => ({ ...acc, [item]: '' }), {})
  );

  const handleRadioChange = (item, value) => {
    setFormValues({ ...formValues, [item]: value });


  };
  const handleRadioChange1 = (index, itemIndex) => (event) => {
    if (!Array.isArray(tabdata)) {
      console.error('tabdata is not an array');
      return;
    }

    const selectedValue = event.target.value;
    const newTabdata = [...tabdata];

    // Ensure the index is valid
    if (index < 0 || index >= newTabdata.length) {
      console.error('Index is out of bounds');
      return;
    }

    // Copy the item at the specified index
    const updatedItem = { ...newTabdata[index] };

    // Ensure ans array is initialized
    if (!Array.isArray(updatedItem.ans)) {
      updatedItem.ans = [];
    }

    // Update the specific answer
    updatedItem.ans[itemIndex] = selectedValue;
    newTabdata[index] = updatedItem;

    // Update the state with the new array
    settabdata(newTabdata);
  };
  const [selectedDesignations, setSelectedDesignations] = useState({}); // Initial state


  const [seledesgination, setSeledesgination] = useState([]);

  // Update `ans` whenever `selectedDesignations` changes
  useEffect(() => {
    const updatedAns = Object.keys(selectedDesignations).map(key => selectedDesignations[key]);
    setSeledesgination(updatedAns);
  }, [selectedDesignations]);

  // Update state on checkbox change
  const handleCheckboxChange1 = (e, item2, isSelectAll = false) => {
    const value = e.target.value;
    const isChecked = e.target.checked;

    if (isSelectAll) {
      // Handle "Select All" case
      setSelectedDesignations(prev => {
        return {
          ...prev,
          [item2]: isChecked
            ? Designation.dpresult.map(desgn => desgn.ATTRIB_CD) // Select all items
            : [] // Deselect all items
        };
      });
    } else {
      // Handle individual checkbox change
      setSelectedDesignations(prev => {
        const currentSelection = prev[item2] || [];
        const newSelection = isChecked
          ? [...currentSelection, value]
          : currentSelection.filter(item => item !== value);

        return {
          ...prev,
          [item2]: newSelection
        };
      });
    }


  };

  // Convert the state to a 2D array
  const getSelectedDesignationsArray = () => {
    return Object.keys(selectedDesignations).map(key => selectedDesignations[key]);
  };

  // Example: you can use this function where you need the 2D array
  const sele_desg = getSelectedDesignationsArray();


  // console.log(seledesgination); // This will log the 2D array of selected items

  console.log(tabdata);


  const handleSubmit = () => {
    // Your submit logic here
    // console.log('Form Submitted', formValues);
  };


  useEffect(() => {
    // Initialize state for each item in checkh.checkName
    const initialSelections = {};
    checkh.checkName.forEach(item => {
      initialSelections[item] = [];
    });
    setSelectedDesignations(initialSelections);
  }, [checkh.checkName]);

  // const handleCheckboxChange = (event, item2) => {
  //   const value = event.target.value;

  //   setSelectedDesignations(prevState => {
  //     const updatedSelections = { ...prevState };

  //     if (event.target.checked) {
  //       updatedSelections[item2] = [...(updatedSelections[item2] || []), value];
  //     } else {
  //       updatedSelections[item2] = updatedSelections[item2].filter(item => item !== value);
  //     }

  //     return updatedSelections;
  //   });
  // };
  console.log(seledesgination);
  useEffect(() => {
    props.gtabdata({ tabdata, seledesgination })

  }, [tabdata, seledesgination])

  // const handleSelectAll = (e,event, item2) => {
  //   const isChecked = event.target.checked;

  //   setSelectedDesignations(prevState => ({
  //     ...prevState,
  //     [item2]: isChecked ? Designation.dpresult.map(desgn => desgn.ATTRIB_CD) : []
  //   }));

  //   const value = e.target.value;
  //   // const isChecked = e.target.checked;
  //   console.log(value, "nijdshiu")
  //   setSelectedDesignations(prev => {
  //     const currentSelection = prev[item2] || [];
  //     const newSelection = isChecked
  //       ? [...currentSelection, value]
  //       : currentSelection.filter(item => item !== value);

  //     return {
  //       ...prev,
  //       [item2]: newSelection
  //     };
  //   });
  // };

  // console.log(selectedDesignations)
  const [seledata, setSeledata] = useState({
    seledatan: [],
    seledatav: []
  })

  return (
    <>
      {data.result2.map((item, index) =>
      (
        <div className="register-form-container" key={index}>
          <div className="register-form-header" onClick={(event) => handleToggle(index, event)}>
            <label>{index + 1 + ")"}  {item.ATTRIB_NAME}</label>
            <button className="toggle-button">
              {activeIndex === index ? '-' : '+'}
            </button>
          </div>

          {activeIndex === index && (
            <div className="register-form-content">
              {/* Unique form for each label */}

              {index === 0 && (
                <>
                  {checkh.checkName.map((item2, itemIndex) => (
                    <div className='row' key={itemIndex}>
                      <div className='column'>
                        <label><b>{item2}</b></label>
                      </div>
                      <div className='column'>
                        <input
                          type="number"
                          required
                          onChange={(event) => handleToggle2(index, itemIndex, event)}
                        />
                      </div>
                    </div>
                  ))}
                </>
              )}
              {index === 1 && (
                <>
                  {checkh.checkName.map((item2, itemIndex) => (
                    <div className='row' key={itemIndex}>
                      <div className='column'>
                        <label><b>{item2}</b></label>
                      </div>
                      <div className='column'>
                        <input
                          type="number"
                          required onChange={(event) => handleToggle2(index, itemIndex, event)} />

                      </div>
                    </div>
                  ))}

                </>
              )}
              {index === 2 && (
                <>
                  {checkh.checkName.map((item2, itemIndex) => (
                    <div className='row' key={itemIndex}>
                      <div className='column'>
                        <label><b>{item2}</b></label>
                      </div>
                      <div className='column'>
                        <FormControl className='redio_btn_mrg'>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name={`radio-group-${itemIndex}`}
                            value={tabdata[index]?.ans[itemIndex] || ''} // Set the current value
                            onChange={handleRadioChange1(index, itemIndex)}
                          >
                            <FormControlLabel
                              className='mgl_1'
                              value="yes"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value="no"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  ))}
                </>
              )}
              {index === 3 && (
                <>
                  {checkh.checkName.map((item2, itemIndex) => (
                    <div className='row' key={itemIndex}>
                      <div className='column'>
                        <label><b>{item2}</b></label>
                      </div>
                      <div className='column'>
                        <input
                          type="number" required onChange={(event) => handleToggle2(index, itemIndex, event)} />
                      </div>

                    </div>
                  ))
                  }

                </>
              )}
              {index === 4 && (
                <>
                  {checkh.checkName.map((item2, itemIndex) => (
                    <div className='row' key={itemIndex}>
                      <div className='column'>
                        <label><b>{item2}</b></label>
                      </div>
                      <div className='column'>
                        <FormControl className='redio_btn_mrg'>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name={`radio-group-${itemIndex}`}
                            value={tabdata[index]?.ans[itemIndex] || ''} // Set the current value
                            onChange={handleRadioChange1(index, itemIndex)}
                          >
                            <FormControlLabel
                              className='mgl_1'
                              value="yes"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value="no"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>
                    </div>
                  ))
                  }

                </>
              )}
              {index === 5 && (
                <>
                  {checkh.checkName.map((item2, itemIndex) => (
                    <div className='row' key={itemIndex}>
                      <div className='column'>
                        <label><b>{item2}</b></label>
                      </div>
                      <div className='column'>
                        <input
                          type="number" required onChange={(event) => handleToggle2(index, itemIndex, event)} />
                      </div>

                    </div>
                  ))
                  }

                </>
              )}
              {index === 6 && (
                <>
                  {checkh.checkName.map((item2, itemIndex) => (
                    <div className='row' key={itemIndex}>
                      <div className='column'>
                        <label><b>{item2}</b></label>
                      </div>
                      <div className="column">


                        <FormControl className='redio_btn_mrg'>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name={`radio-group-${itemIndex}`}
                            value={tabdata[index]?.ans[itemIndex] || ''} // Set the current value
                            onChange={handleRadioChange1(index, itemIndex)}
                          >
                            <FormControlLabel
                              className='mgl_1'
                              value="yes"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value="no"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>

                      </div>

                    </div>
                  ))
                  }

                </>
              )}
              {index === 7 && (
                <div>
                  {checkh.checkName.map(item2 => (
                    <div className='row' key={item2}>
                      <div className='column'>
                        <FormControlLabel
                          control={
                            <Checkbox
                              checked={Designation.dpresult.length === (selectedDesignations[item2] || []).length}
                              onChange={(e) => handleCheckboxChange1(e, item2, true)} // Pass `true` for "Select All"
                            />
                          }
                          label={<label><b>{item2}</b></label>}
                        />
                        <FormGroup className="checkbox-group">
                          {Designation.dpresult.map(desgn => (
                            <FormControlLabel
                              key={desgn.ATTRIB_CD}
                              control={
                                <Checkbox
                                  checked={(selectedDesignations[item2] || []).includes(desgn.ATTRIB_CD)}
                                  onChange={(e) => handleCheckboxChange1(e, item2)} // Default to individual checkbox change
                                  value={desgn.ATTRIB_CD}
                                />
                              }
                              label={desgn.ATTRIB_NAME}
                            />
                          ))}
                          <hr className='hr1' />
                        </FormGroup>
                      </div>
                    </div>
                  ))}
                </div>
              )}



              {index === 8 && (
                <>
                  {checkh.checkName.map((item2, itemIndex) => (
                    <div className='row' key={itemIndex}>
                      <div className='column'>
                        <label><b>{item2}</b></label>
                      </div>
                      <div className='column'>
                        <FormControl className='redio_btn_mrg'>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name={`radio-group-${itemIndex}`}
                            value={tabdata[index]?.ans[itemIndex] || ''} // Set the current value
                            onChange={handleRadioChange1(index, itemIndex)}
                          >
                            <FormControlLabel
                              className='mgl_1'
                              value="yes"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value="no"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>

                    </div>
                  ))
                  }

                </>
              )}
              {index === 9 && (
                <>
                  {checkh.checkName.map((item2, itemIndex) => (
                    <div className='row' key={itemIndex}>
                      <div className='column'>
                        <label><b>{item2}</b></label>
                      </div>
                      <div className='column'>
                        <FormControl className='redio_btn_mrg'>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name={`radio-group-${itemIndex}`}
                            value={tabdata[index]?.ans[itemIndex] || ''} // Set the current value
                            onChange={handleRadioChange1(index, itemIndex)}
                          >
                            <FormControlLabel
                              className='mgl_1'
                              value="yes"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value="no"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>

                    </div>
                  ))
                  }

                </>
              )}
              {index === 10 && (
                <>
                  {checkh.checkName.map((item2, itemIndex) => (
                    <div className='row' key={itemIndex}>
                      <div className='column'>
                        <label><b>{item2}</b></label>
                      </div>
                      <div className='column'>
                        <FormControl className='redio_btn_mrg'>
                          <RadioGroup
                            row
                            aria-labelledby="demo-row-radio-buttons-group-label"
                            name={`radio-group-${itemIndex}`}
                            value={tabdata[index]?.ans[itemIndex] || ''} // Set the current value
                            onChange={handleRadioChange1(index, itemIndex)}
                          >
                            <FormControlLabel
                              className='mgl_1'
                              value="yes"
                              control={<Radio />}
                              label="Yes"
                            />
                            <FormControlLabel
                              value="no"
                              control={<Radio />}
                              label="No"
                            />
                          </RadioGroup>
                        </FormControl>
                      </div>

                    </div>
                  ))
                  }

                </>
              )}
              {index === 11 && (
                <>
                  {checkh.checkName.map((item2, itemIndex) => (
                    <div className='row' key={itemIndex}>
                      <div className='column'>
                        <label><b>{item2}</b></label>
                      </div>
                      <div className='column'>
                        <input type="TextField " label="code" onChange={(event) => handleToggle2(index, itemIndex, event)} />
                      </div>

                    </div>
                  ))
                  }

                </>
              )}
              {index === 12 && (
                <>
                  {
                    checkh.checkName.map((item2, itemIndex) => (
                      <div className='row' key={itemIndex}>
                        <div className='column'>
                          <label><b>{item2}</b></label>
                        </div>
                        <div className='column'>
                          <FormControl className='redio_btn_mrg'>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name={`radio-group-${itemIndex}`}
                              value={tabdata[index]?.ans[itemIndex] || ''} // Set the current value
                              onChange={handleRadioChange1(index, itemIndex)}
                            >
                              <FormControlLabel
                                className='mgl_1'
                                value="yes"
                                control={<Radio />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value="no"
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>
                    ))

                  }

                </>

              )}
              {index === 13 && (
                <>
                  {
                    checkh.checkName.map((item2, itemIndex) => (
                      <div className='row' key={itemIndex}>
                        <div className='column'>
                          <label><b>{item2}</b></label>
                        </div>
                        <div className='column'>
                          <FormControl className='redio_btn_mrg'>
                            <RadioGroup
                              row
                              aria-labelledby="demo-row-radio-buttons-group-label"
                              name={`radio-group-${itemIndex}`}
                              value={tabdata[index]?.ans[itemIndex] || ''} // Set the current value
                              onChange={handleRadioChange1(index, itemIndex)}
                            >
                              <FormControlLabel
                                className='mgl_1'
                                value="yes"
                                control={<Radio />}
                                label="Yes"
                              />
                              <FormControlLabel
                                value="no"
                                control={<Radio />}
                                label="No"
                              />
                            </RadioGroup>
                          </FormControl>
                        </div>
                      </div>
                    ))

                  }

                </>

              )}
            </div>
          )}

        </div >


      ))
      }

    </>
  );
}

import React, { useState, useEffect } from "react";
import services from "./apiServices";
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Grid,
} from "@mui/material";
import moment from 'moment';

export default function Adm_Rpt_All_Emp_and_Lve_Dtls() {

  const [leavecnt, Setleavecnt] = useState({
    login_lve_bal_cnt: [], lve_apl_dt: [], login_team_membr_inf_res: [],
    shw_upcom_hlod_res: [], empwise_lve_bal_res: [], all_usr_cnt_data: [], aflt_annocement_news_result: [], all_aflt_emp_and_lve_dtls_result: []
  });

  useEffect(() => {
    const lev_bal_cnt = async () => {
      try {
        const lve_cnt = await services.get_lev_bal_cnt();

        if (lve_cnt) {
          Setleavecnt(lve_cnt);
        } else {
          console.error("Unexpected data format:", lve_cnt);
        }
      } catch (error) {
        console.error("Error fetching data:", error);
      }
    };
    lev_bal_cnt();
  }, []);


  console.log("leavecnt", leavecnt);

  return (
    <div className='wrapper_form'>
      <Grid item xs={6} style={{ paddingLeft: "1%", paddingRight: "1%" }}>
        <div className="title" style={{ marginTop: "2%" }}>All Employees & Leave Details</div>
        <TableContainer
          component={Paper}
          style={{
            margin: "5px auto 25px auto",
            overflow: "hidden",
            boxShadow: "0px 6px 16px 3px #bababa", // Shadow only on the top and bottom
            borderRadius: "20px",
            border: "2px solid gray",
            maxHeight: "500px", // Set a fixed height for the table container
            overflowY: "auto", // Enable vertical scrolling
            scrollbarWidth: "thin",
            scrollbarColor: "red"
          }}
        >
          <Table style={{ width: "100%" }}>
            <TableHead>
              <TableRow style={{ width: "100%" }}>
                <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Sr. No.</TableCell>
                <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Employee ID</TableCell>
                <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Name</TableCell>
                <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Leave Type</TableCell>
                <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Yearly Consume</TableCell>
                <TableCell style={{ fontSize: "0.8rem", padding: "1px 18px", backgroundColor: "#4a6d8c", border: "1px solid gray", color: "white", textAlign: "center" }}>Leave Balance</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {/* Your existing TableBody logic */}
              {Object.values(
                leavecnt.all_aflt_emp_and_lve_dtls_result.reduce((acc, current) => {
                  const employeeId = current.USR_EMP_ID;
                  const employeeName = current.USR_DISPLAY_NAME;
                  const leaveType = current.disp_nm;
                  const conjumbal = current.LVE_TYP_CNMSN_CNT;
                  const remainingBalance = current.LVE_TYP_RN_BAL_CNT;

                  if (!acc[employeeId]) {
                    acc[employeeId] = {
                      employeeId,
                      employeeName,
                      leaveTypes: [],
                    };
                  }

                  acc[employeeId].leaveTypes.push({
                    leaveType,
                    conjumbal,
                    remainingBalance,
                  });

                  return acc;
                }, {})
              ).map((employee, index) => (
                <React.Fragment key={index}>
                  <TableRow style={{ height: "10px" }}>
                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>{index + 1}</TableCell>
                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>{employee.employeeId}</TableCell >
                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>{employee.employeeName}</TableCell>
                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                  </TableRow>
                  {employee.leaveTypes.map((leave, index) => (
                    <TableRow style={{ height: "10px" }} key={index}>
                      <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                      <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                      <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                      <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>{leave.leaveType}</TableCell>
                      <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>{leave.conjumbal}</TableCell>
                      <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>{leave.remainingBalance}</TableCell>
                    </TableRow>
                  ))}
                </React.Fragment>
              ))}
              {/* Add empty rows if there are less than 5 rows */}
              {leavecnt.empwise_lve_bal_res.length < 5 &&
                Array.from({ length: 5 - leavecnt.empwise_lve_bal_res.length }).map((_, index) => (
                  <TableRow style={{ height: "10px" }} key={`empty-row-${index}`}>
                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                    <TableCell align="center" style={{ fontSize: "0.8rem", padding: "1px 18px", border: "1px solid gray" }}>&nbsp;</TableCell>
                  </TableRow>
                ))}
            </TableBody>
          </Table>
        </TableContainer>
      </Grid>
    </div>
  )
}

import React from 'react'
import { Link } from 'react-router-dom';
export default function NavbarLogin() {
  return (
    <>
      <header class="fixedheader">
        <div class="container_12">
          <div class="grid_12">
            <div class="wrapper"> <Link to="index.html" class="logo">design.maniacs</Link>
              <nav>
                {/* <ul class="menu">
            <li class="active"><Link to="/">home</Link></li>
            <li><Link to="works.html">works</Link></li>
            <li><Link to="clients.html">clients</Link></li>
            <li><Link to="blog.html">blog</Link></li>
            <li><Link to="contacts.html">contacts</Link></li>
            
          </ul> */}
              </nav>
            </div>
          </div>
        </div>
      </header>
    </>
  )
}

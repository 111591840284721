import React, { useEffect, useState } from "react";
import fb from '../images/facebook-icon.png'
import twitter from '../images/twitter-icon.png'

export default function Footer() {
    const [curryear,setcurryear]=useState();
    useEffect(()=>{
        const date=new Date().getFullYear();
        setcurryear(date)
    },[curryear])
  return (
    <>
      <footer>
        <div class="container_12">
          <div class="wrapper">
            <div class="grid_8">
              {" "}
              Ardi-Bi Systems (RDBS) &copy;{curryear} | 
              {/* <a href="#">Privacy Policy</a> | */}
               Design Partner:{" "}
              <a  href="http://www.templatemonster.com/" class="link">
                TemplateMonster.com
              </a>{" "}
            </div>
            <div class="grid_4">
              <div class="social">
                {" "}
                My Social:
                <a href="#">
                  <img src={fb} alt="" />
                </a>
                <a href="#">
                  <img src={twitter} alt="" />
                </a>{" "}
              </div>
            </div>
          </div>
        </div>
      </footer>
    </>
  );
}

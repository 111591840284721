import React, { useState, useEffect } from "react";
import services from "./apiServices";
import { Modal } from './Modal';
import { useNavigate } from 'react-router-dom';

export default function Forgot_Pass() {
    const [errors, setErrors] = useState({});
    const [formData, setFormData] = useState({
        usr_name: "",
        new_pass: "",
        conf_pass: "",
        otp: "",
    });
    const navigate = useNavigate();
    const [isOtpSent, setIsOtpSent] = useState(false);
    const [sentOtp, setSentOtp] = useState("");
    const [modalOpen, setModalOpen] = useState(false);
    const [modalTitle, setModalTitle] = useState("");
    const [timer, setTimer] = useState(120);
    const [isTimerActive, setIsTimerActive] = useState(false);

    const openModal = (title) => {
        setModalTitle(title);
        setModalOpen(true);
    };

    const backtologinform = () => {
        navigate('/');
    }
    const handleSendOtp = async (e) => {
        e.preventDefault();
        try {
            const res = await services.fechdata_forgot_pass(formData);
            if (res) {
                setIsOtpSent(true);
                setSentOtp(res.otp);
                openModal("Verify OTP");
                startTimer();
            }
        } catch (error) {
            console.error("Error fetching data:", error);
        }
    };

    const startTimer = () => {
        setIsTimerActive(true);
        setTimer(120);
    };

    useEffect(() => {
        let interval = null;
        if (isTimerActive && timer > 0) {
            interval = setInterval(() => {
                setTimer((prevTimer) => prevTimer - 1);
            }, 1000);
        } else if (timer === 0) {
            clearInterval(interval);
            setIsTimerActive(false);
        }
        return () => clearInterval(interval);
    }, [isTimerActive, timer]);

    const handleVerifyOtp = async (e) => {
        e.preventDefault();
        if (formData.otp === sentOtp) {
            setModalTitle("Reset Password");
            setFormData({ ...formData, otp: "", new_pass: "", conf_pass: "" }); // Clear the form
            setModalOpen(true);
        } else {
            alert("Invalid OTP. Please try again.");
        }
    };

    const closeModal = () => {
        setModalOpen(false);
    };

    return (
        <>
            <div className="wrapper_form wd_log" style={{ marginTop: "8%" }}>
                <div className="title">Forget Password</div>
                <form onSubmit={handleSendOtp}>
                    <div className="field">
                        <input
                            style={{ marginTop: "6%" }}
                            type="text"
                            name="usr_name"
                            value={formData.usr_name || ""}
                            placeholder="Enter Login ID"
                            onChange={(e) =>
                                setFormData({ ...formData, usr_name: e.target.value })
                            }
                        />
                        <label style={{ marginTop: "4%" }}>
                            Login ID{" "}
                            {errors.usr_name && (
                                <span className="error-message">{errors.usr_name}</span>
                            )}
                        </label>
                    </div>

                    <div className="field">
                        <button style={{ width: "20%", float: "left" }} type="submit" onClick={backtologinform} className="btn-action action-button">
                            Back
                        </button>
                        <button style={{ width: "30%", float: "right" }} type="submit" className="btn-action action-button">
                            Send OTP
                        </button>
                    </div>
                </form>
            </div>
            <Modal
                isOpen={modalOpen}
                onClose={closeModal}
                onSubmit={modalTitle === "Verify OTP" ? handleVerifyOtp : () => { }}
                formData={formData}
                setFormData={setFormData}
                title={modalTitle}
                timer={timer}
            />
        </>
    );
}
// In your logout frontend
import axios from 'axios';

export const logout = async () => {
    try {
        await axios.post('/rdbs/logout'); // Adjust the URL if needed
        localStorage.removeItem('isLoggedIn'); // Clear any local storage if used
        // Optionally, navigate to the login page or show a logout message
    } catch (error) {
        console.error('Logout failed:', error);
        throw error; // Optional: rethrow error to handle it in the component
    }
};